import React from "react";
import styles from "./MapStat.module.css";
import sharedStyles from "../../styles/shared.module.css";
import classNames from "classnames";
import CompleteTick from "../completetick/CompleteTick";
type PropsType = {
  label: string;
  value: string;
  complete?: boolean;
};
export default function (props: PropsType) {
  return (
    <div className={styles.container}>
      <span
        className={classNames(sharedStyles.text, styles.label)}
      >{`${props.label}: `}</span>
      <span
        className={classNames(
          sharedStyles.text,
          props.complete ? styles.complete : styles.value
        )}
      >
        {props.value}
        {props.complete ? (
          <CompleteTick size={24} style={{ marginLeft: 10 }} />
        ) : null}
      </span>
    </div>
  );
}
