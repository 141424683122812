import React from "react";
import { UserCityType } from "cp-server";
import Achievement from "./Achievement";
import LangManager from "../../../../data/languages/LangManager";
import LangKeys from "../../../../data/languages/LangKeys";
import styles from "./DashboardAchievements.module.css";
import { getAchievementsSummary } from "../../../../data/utils/formating/AchievementUtils";
import SmallHeading from "../../../../components/headings/SmallHeading";
import { useGetAchievementsForUserByAreaQuery } from "../../../../state/api/ApiSlice";

type Props = {
  userCity: UserCityType;
};

function DashboardAchievements(props: Props) {
  const { data: achievements } = useGetAchievementsForUserByAreaQuery({
    area_id: props.userCity.area_id,
  });
  function renderAchievements() {
    if (achievements?.success) {
      const sortedAchievements = getAchievementsSummary(
        achievements.achievements
      );
      return (
        <div className={styles.achievementsList}>
          {sortedAchievements.map((a) => (
            <Achievement data={a} key={a.achievement_id} />
          ))}
        </div>
      );
    }
  }
  function getSeeAllText() {
    if (achievements?.success) {
      return `See all ${achievements.achievements.length}`;
    }
    return "See all";
  }

  return (
    <div>
      <SmallHeading
        left={LangManager.getLang(LangKeys.DASHBOARD_ACHIEVEMENTS)}
        right={getSeeAllText()}
        link={`/tabs/home/achievements/${props.userCity.area_id}`}
      />
      {renderAchievements()}
    </div>
  );
}

export default React.memo(DashboardAchievements);
