import { ApiResponses, ApiRoutes } from "cp-server";
import { EndpointBuilder } from "@reduxjs/toolkit/dist/query/endpointDefinitions";
import { getPlatform } from "../../../../platform/PlatformManager";

const Endpoint = (builder: EndpointBuilder<any, any, any>) =>
  builder.mutation<ApiResponses[ApiRoutes.AddEvent], string>({
    query: (type) => ({
      url: ApiRoutes.AddEvent,
      method: "post",
      body: {
        type,
        platform: getPlatform(),
      },
    }),
  });

export default Endpoint;
