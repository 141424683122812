import { ApiRequestBodies, ApiResponses, ApiRoutes } from 'cp-server';
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';

const Endpoint = (builder: EndpointBuilder<any, any, any>) =>
  builder.query<
    ApiResponses[ApiRoutes.GetActivitiesWithinArea],
    ApiRequestBodies[ApiRoutes.GetActivitiesWithinArea]
  >({
    query: (data) => ({
      url: ApiRoutes.GetActivitiesWithinArea,
      method: 'post',
      body: data,
    }),
    async onQueryStarted(arg, { dispatch, queryFulfilled }) {
      try {
        const { data } = await queryFulfilled;
      } catch (err) {
        console.log(err);
      }
    },
  });

export default Endpoint;
