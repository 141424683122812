import { ApiRequestBodies, ApiResponses, ApiRoutes } from "cp-server";
import { EndpointBuilder } from "@reduxjs/toolkit/dist/query/endpointDefinitions";
import { TagTypes } from "../../ApiSlice";

const Endpoint = (builder: EndpointBuilder<any, any, any>) =>
  builder.query<
    ApiResponses[ApiRoutes.GetActivities],
    ApiRequestBodies[ApiRoutes.GetActivities]
  >({
    providesTags: [TagTypes.activities],
    query: (data) => ({
      url: ApiRoutes.GetActivities,
      method: "post",
      body: {
        //@ts-ignore
        page_size: "6",
        ...data,
      },
    }),
    async onQueryStarted(arg, { dispatch, queryFulfilled }) {
      try {
        const { data } = await queryFulfilled;
      } catch (err) {
        console.log(err);
      }
    },
  });

export default Endpoint;
