import styles from "./CompleteTick.module.css";
import ImageKeys from "../../data/images/ImageKeys";
import React, { CSSProperties } from "react";
import ImageManager from "../../data/images/ImageManager";

type PropsType = {
  size: number;
  style?: CSSProperties;
};

export default function CompleteTick(props: PropsType) {
  return (
    <div
      className={styles.completeTick}
      style={{
        ...props.style,
        width: props.size,
        height: props.size,
        backgroundImage: `url(${ImageManager.getImage(ImageKeys.TICK_ICON)})`,
      }}
    />
  );
}
