"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * Created by matthewjamieson on 09/02/2017.
 */
// @flow
exports.default = {
    EN: "en",
    ES: "es",
    CA: "ca"
};
