import { AchievementType, LandmarkType, VisitedLandmarkType } from "cp-server";
import moment from "moment";
export function sortAchievements(achievements: Array<AchievementType>) {
  return [...achievements]
    .sort((a, b) =>
      moment(a.date_completed).isAfter(b.date_completed) ? -1 : 1
    ) // Sort by complete date (if applicable)
    .map((a) => {
      const totalLandmarks = a.landmarks_needed || a.landmarks.length;
      const visitedLandmarks = Math.min(
        totalLandmarks,
        getuniqueVisitedLandmarksCount(a.visited_landmarks)
      );
      return {
        data: a,
        percentComplete: visitedLandmarks / totalLandmarks,
      };
    })
    .sort((a, b) => (a.percentComplete > b.percentComplete ? -1 : 1))
    .map((a) => a.data);
}

/**
 * Get a short list of achievements to display on the dashboard
 * A few recently completed achievements and a few nearly complete achievements
 * @param achievements
 */
export function getAchievementsSummary(
  achievements: Array<AchievementType>
): Array<AchievementType> {
  const sortedAchievements = sortAchievements(achievements);
  const first3CompletedAchievements = sortedAchievements
    .filter((a) => a.date_completed)
    .slice(0, 3);
  const spacesLeft = 6 - first3CompletedAchievements.length;
  const incompleteAchievements = sortedAchievements
    .filter((a) => !a.date_completed)
    .slice(0, spacesLeft);
  return [...first3CompletedAchievements, ...incompleteAchievements];
}

export function getuniqueVisitedLandmarksCount(
  visitedLandmarks: Array<VisitedLandmarkType>
) {
  const visitCount = new Set(
    visitedLandmarks.filter((vl) => vl).map((vl) => vl.landmark_id)
  ).size;
  return visitCount;
}

export function getLandmarksSortedByVisited(
  landmarks: Array<LandmarkType>,
  visitedLandmarks: Array<VisitedLandmarkType>
) {
  return landmarks
    .map((landmark) => {
      const visited: boolean =
        visitedLandmarks.filter((vl) => vl.landmark_id === landmark.landmark_id)
          .length > 0;
      return {
        landmark,
        visited,
      };
    })
    .sort((a, b) => (a.visited && !b.visited ? -1 : 1));
}
