// @flow
import { setLocalStoreValue } from "./LocalStoreActions";
import { LocalStoreStateType } from "./LocalStoreTypes";

const initialState: LocalStoreStateType = {
  cookiesAccepted: false,
};

export default function (state: LocalStoreStateType = initialState, action) {
  switch (action.type) {
    case setLocalStoreValue:
      return Object.assign({}, state, action.data);
    default:
      return state;
  }
}
