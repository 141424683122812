import { ApiReducerHelper, defaultInitialState } from "framework";
import {
  SubmitFeedbackResponseType,
  SubmitFeedbackStateType,
} from "./SubmitFeedbackTypes";
import { SUBMIT_FEEDBACK_LOAD_ACTION } from "./SubmitFeedbackActions";

const initialState: SubmitFeedbackStateType = {
  ...defaultInitialState,
  data: {
    success: false,
  },
};

const submitFeedbackApiReducer: ApiReducerHelper<SubmitFeedbackResponseType> = new ApiReducerHelper(
  {
    loadAction: SUBMIT_FEEDBACK_LOAD_ACTION,
    initialState,
  }
);

export default submitFeedbackApiReducer.getReducer();
