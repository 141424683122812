import { ReduxStateType } from "./ReduxStateType";
import { ApiActionsHelpers } from "framework";
import type { ApiActionsOptionsType } from "framework";
import { ResponseType } from "framework";

export function createApiActionsHelper<TResponseType extends ResponseType>(
  options: ApiActionsOptionsType
) {
  return new ApiActionsHelpers<TResponseType, ReduxStateType>(options);
}
