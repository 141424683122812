import React from "react";
import styles from "../../cities/components/City.module.css";
import styleOverrides from "./DemoCity.module.css";
import sharedStyles from "../../../styles/shared.module.css";
import { CityWithBB } from "cp-server";
import classNames from "classnames";
import Stat from "../../../components/stat/Stat";
import LangManager from "../../../data/languages/LangManager";
import LangKeys from "../../../data/languages/LangKeys";
import { getDistance } from "../../../data/utils/formating/DistanceFormating";
import { Config } from "framework";

type OtherProps = { city: CityWithBB };
type PropsType = OtherProps;

function DemoCity(props: PropsType) {
  const city = props.city;
  return (
    <div className={styleOverrides.container}>
      <div className={styles.inner}>
        <div
          className={styles.thumbnail}
          style={{
            backgroundImage: `url(${Config.getConfigItem(
              "domain"
            )}/map_thumbs/${city.area_id}.png)`,
          }}
        />
        <div className={classNames(sharedStyles.heading2, styles.cityName)}>
          {city.name}
        </div>
        <div className={styles.statsContainer}>
          <Stat
            label={LangManager.getLang(LangKeys.CITIES_TOTAL_ROADS)}
            value={`${getDistance(city.total_roads_distance, 0)}`}
          />
          <Stat
            label={LangManager.getLang(LangKeys.CITIES_TOTAL_OFF_ROADS)}
            value={`${getDistance(city.total_off_roads_distance, 0)}`}
          />
          <Stat
            label={LangManager.getLang(LangKeys.CITIES_LANDMARKS)}
            value={`${city.total_landmarks || 0}`}
          />
        </div>
      </div>
    </div>
  );
}

export default DemoCity;
