import React, { Component, CSSProperties } from "react";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/lab/ToggleButtonGroup";
import styles from "./Toggle.module.css";
import classNames from "classnames";

type DispatchProps = {};
type StateProps = {};

type OtherProps = {
  onChange: (id: string) => void;
  buttons: Array<{ id: string; label: string }>;
  initialIndex?: number;
  style?: CSSProperties;
};
type PropsType = DispatchProps & StateProps & OtherProps;

type StateType = { currentButton: { id: string; label: string } };

export default class Toggle extends Component<PropsType, StateType> {
  constructor(props) {
    super(props);
    this.state = {
      currentButton: this.props.buttons[this.props.initialIndex || 0],
    };
  }

  renderButton(button: { id: string; label: string }): JSX.Element {
    const selected = button.id === this.state.currentButton.id;
    return (
      <ToggleButton
        key={button.id}
        classes={{
          root: classNames(styles.button, styles.buttonLabel),
          selected: classNames(
            styles.buttonSelected,
            styles.buttonLabelSelected
          ),
        }}
        selected={selected}
        value={button}
      >
        {button.label}
      </ToggleButton>
    );
  }

  render() {
    return (
      <div className={styles.container} style={this.props.style}>
        <ToggleButtonGroup
          onChange={(e, button) => {
            const b = button[0];
            if (b) {
              this.setState({ currentButton: b }, () => {
                this.props.onChange(b.id);
              });
            }
          }}
        >
          {this.props.buttons.map((button) => this.renderButton(button))}
        </ToggleButtonGroup>
      </div>
    );
  }
}
