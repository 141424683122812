import React from "react";
import styles from "./ProductSelector.module.css";
import sharedStyles from "../../../../styles/shared.module.css";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import classNames from "classnames";
import Stripelogo from "../../../../assets/powered_by_stripe.svg";
import DisplayIf from "../../../../components/display-if/DisplayIf";

export default function PriceCard(props: {
  name: string;
  formattedPrice: string | number;
  interval: string;
  cpmEquivString?: string;
  onClick: () => void;
  showStripeLogo: boolean;
  freeTrialAvailable?: boolean;
}) {
  return (
    <div className={styles.priceCard}>
      <div className={classNames(sharedStyles.heading2, styles.heading)}>
        {props.name}
      </div>

      <div className={classNames(sharedStyles.heading4, styles.price)}>
        <DisplayIf condition={Boolean(props.freeTrialAvailable)}>
          1 Month free* <br />
          <span className={classNames(sharedStyles.heading4, styles.then)}>
            then
          </span>
        </DisplayIf>
        <br />
        {props.formattedPrice} / {props.interval}
      </div>
      {props.cpmEquivString ? (
        <div className={classNames(sharedStyles.heading4, styles.priceText2)}>
          That's just £{props.cpmEquivString} per month!
        </div>
      ) : null}

      <Button
        variant={"contained"}
        color={"primary"}
        fullWidth
        onClick={props.onClick}
      >
        <span style={{ color: "white" }}>Subscribe</span>
      </Button>
      {props.showStripeLogo ? (
        <a href={"https://stripe.com"}>
          <img src={Stripelogo} width={100} className={styles.stripeLogo} />
        </a>
      ) : null}
      <div className={sharedStyles.heading5} style={{ marginTop: 25 }}>
        <Link to={"/t_and_c"}>Terms and conditions</Link> apply.
      </div>
      <DisplayIf condition={Boolean(props.freeTrialAvailable)}>
        <div className={sharedStyles.heading5}>
          *Free trial for new customers only
        </div>
      </DisplayIf>
    </div>
  );
}
