import React, { useEffect } from "react";
import Header from "../components/Header";
import Content from "../../components/content/Content";
import CityList from "./components/citylist/CityList";
import LangManager from "../../data/languages/LangManager";
import LangKeys from "../../data/languages/LangKeys";
import styles from "./Cities.module.css";
import CitySuggest from "../components/citysuggest/CitySuggest";
import { Helmet } from "react-helmet";
import SmallHeading from "../../components/headings/SmallHeading";
import { IonContent } from "@ionic/react";
import { useGetCitiesQuery } from "../../state/api/ApiSlice";
import { getResultsFromResponse } from "../../data/ApiData";
import { AreaType } from "cp-server";

export default React.memo(function Cities() {
  const { data: citiesResponse } = useGetCitiesQuery({});
  const cities = getResultsFromResponse(citiesResponse);
  function renderCitiesList() {
    if (citiesResponse?.success) {
      const citiesType = cities.filter((item) => item.type === "city");
      const areaType = cities.filter((item) => item.type === "area");
      const nationalParks = cities.filter(
        (item) => item.type === AreaType.national_park
      );
      return (
        <div>
          <div className={styles.citiesHeader}>
            <SmallHeading left={"Cities"} />
            <CitySuggest />
          </div>

          <CityList cities={citiesType} />
          <div style={{ marginTop: 16 }}>
            <SmallHeading left={"Areas"} />
          </div>
          <CityList cities={areaType} />

          <div style={{ marginTop: 16 }}>
            <SmallHeading left={"National Parks"} />
          </div>
          <CityList cities={nationalParks} />
        </div>
      );
    }
  }

  return (
    <>
      <Helmet>
        <title>City Painter - Explore</title>
      </Helmet>
      <Header title={LangManager.getLang(LangKeys.CITIES_BROWSE)} />
      <IonContent>
        <Content>{renderCitiesList()}</Content>
      </IonContent>
    </>
  );
});
