import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import {
  IonContent,
  IonLabel,
  IonSegment,
  IonSegmentButton,
} from "@ionic/react";
import PremiumComparisonGrid from "./components/comparisongrid/PremiumComparisonGrid";
import Content from "../../components/content/Content";
import PriceCard from "./components/productdisplay/PriceCard";
import { apiSlice, TagTypes, useUserQuery } from "../../state/api/ApiSlice";
import { AccountTypes } from "cp-server";
import SuccessCard from "./components/success/SuccessCard";
import classNames from "classnames";
import sharedStyles from "../../styles/shared.module.css";
import styles from "./components/productdisplay/ProductSelector.module.css";
import "cordova-plugin-purchase";
import {
  MakePurchaseResult,
  Purchases,
  PurchasesStoreProduct,
} from "@revenuecat/purchases-capacitor";
import { getStore } from "../../state/Store";
import AccountTierFunctions from "../../data/account/AccountTierFunctions";
import { User } from "framework";

export default function PremiumIAPRevCat() {
  const [products, setProducts] = useState<PurchasesStoreProduct[]>([]);
  const [currentProductId, setCurrentProductId] = useState<string | number>(0);

  const [purchasedProduct, setPurchasedProduct] = useState<
    MakePurchaseResult | undefined
  >(undefined);
  useEffect(() => {
    Purchases.getProducts({
      productIdentifiers: [
        "premium_monthly_android",
        "premium_3monthly_android",
        "premium_yearly_android",
      ],
    }).then((e) => {
      setProducts(e.products);
    });
  }, []);

  //Get the user data - This is refreshed after a successful subscription (See InAppPayments module)
  const { data: userResponse } = useUserQuery({});

  // Local data gets updated fastest. Probably before the database. So check both for an active subscription
  const isPremium =
    purchasedProduct ||
    !AccountTierFunctions.accountIsFree() ||
    (userResponse?.success &&
      userResponse.user.account_type === AccountTypes.premium);

  const headings = {
    premium_3monthly_android: "Every 3 Months",
    premium_monthly_android: "Monthly",
    premium_yearly_android: "Yearly",
  };
  const subheadings = {
    premium_3monthly_android: "Most Popular",
    premium_monthly_android: "",
    premium_yearly_android: "Best Value",
  };
  const period = {
    premium_3monthly_android: "3 months",
    premium_monthly_android: "month",
    premium_yearly_android: "Year",
  };

  const product: PurchasesStoreProduct | undefined =
    products.find((p) => p.defaultOption?.productId === currentProductId) ||
    products[0];
  const productId = product?.defaultOption?.productId || "";
  console.log(products);
  function renderSegments() {
    return (
      <IonSegment
        value={product?.identifier}
        onIonChange={(e) => setCurrentProductId(e.target.value || "")}
        color={"primary"}
      >
        {products.map((p, index) => {
          const key = p?.defaultOption?.productId || "";
          return (
            <IonSegmentButton value={key}>
              <IonLabel style={{ margin: 0 }}>{headings[key]}</IonLabel>
              <div
                className={classNames(
                  sharedStyles.heading5,
                  styles.buttonSubheading
                )}
              >
                {subheadings[key]}
              </div>
            </IonSegmentButton>
          );
        })}
      </IonSegment>
    );
  }

  async function orderProduct() {
    if (product) {
      try {
        const result = await Purchases.purchaseStoreProduct({ product });
        setPurchasedProduct(result);
        const subscriptions = result?.customerInfo?.activeSubscriptions;
        if (subscriptions && subscriptions?.length > 0) {
          User.setAccountType(AccountTypes.premium);
        }
        getStore().dispatch(
          apiSlice.util.invalidateTags([TagTypes.user_details])
        );

        console.log("purchased", result);
      } catch (e) {
        console.warn("purchase error", e);
      }
    }
  }

  return (
    <>
      <Header title={"Upgrade"} />
      <IonContent>
        <Content>
          {isPremium ? (
            <SuccessCard />
          ) : (
            <>
              <PremiumComparisonGrid />
              {renderSegments()}
              <PriceCard
                name={product?.title || ""}
                formattedPrice={product?.priceString || ""}
                interval={period[productId] || ""}
                onClick={orderProduct}
                showStripeLogo={false}
                freeTrialAvailable
              />
            </>
          )}
        </Content>
      </IonContent>
    </>
  );
}
