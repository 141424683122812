import ApiReducerHelper, {
  defaultInitialState,
} from "framework/build/src/reduxhelpers/api/ApiReducerHelper";
import { RegisterResponseType, RegisterStateType } from "./RegisterTypes";
import { REGISTER_LOAD_ACTION } from "./RegisterActions";

const initialState: RegisterStateType = {
  ...defaultInitialState,
  data: {},
};

const registerApiReducer: ApiReducerHelper<RegisterResponseType> = new ApiReducerHelper(
  {
    loadAction: REGISTER_LOAD_ACTION,
    initialState,
  }
);

export default registerApiReducer.getReducer();
