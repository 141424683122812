import { UserCityType } from "cp-server";
import React from "react";
import styles from "./activitieslist/ActivityList.module.css";
import Toggle from "../../../components/toggle/Toggle";
import LangManager from "../../../../data/languages/LangManager";
import LangKeys from "../../../../data/languages/LangKeys";
type AreaButton = {
  id: string;
  label: string;
};
export default function ActivityAreaButtons({
  userCity,
  onChange,
}: {
  userCity: UserCityType;
  onChange: (area_id: string) => void;
}) {
  const buttons: Array<AreaButton> = [
    { id: "", label: "All" },
    { id: userCity.area_id, label: userCity.name },
  ];
  const total = userCity.activities;
  return (
    <div className={styles.toggleAndCountContainer}>
      <Toggle
        buttons={buttons}
        onChange={onChange}
        key="buttons"
        initialIndex={1}
      />
      <div className={styles.divider}></div>
      <div className={styles.activitiesCount}>
        {(total &&
          `${total} ${LangManager.getLang(LangKeys.ACTIVITIES_COUNT)}`) ||
          ""}
      </div>
    </div>
  );
}
