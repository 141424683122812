import React, { Component } from "react";
import styles from "../../components/landmark/Landmark.module.css";
import { LandmarkType } from "cp-server";
import ImageManager from "../../../data/images/ImageManager";
import ImageKeys from "../../../data/images/ImageKeys";

type PropsType = { landmark: LandmarkType };

type StateType = {};
const getLandmarkIcon = (landmark: LandmarkType) => {
  switch (landmark.type) {
    case "peak":
      return ImageManager.getImage(ImageKeys.LANDMARKS_PEAK);
    case "viewpoint":
      return ImageManager.getImage(ImageKeys.LANDMARKS_VIEWPOINT);
    case "artwork":
      return ImageManager.getImage(ImageKeys.LANDMARKS_ARTWORK);
    case "archaeological_site":
    case "memorial":
    case "monument":
      return ImageManager.getImage(ImageKeys.LANDMARKS_MONUMENT);
    case "castle":
      return ImageManager.getImage(ImageKeys.LANDMARKS_CASTLE);
    case "lighthouse":
      return ImageManager.getImage(ImageKeys.LANDMARKS_LIGHTHOUSE);
    case "ruins":
      return ImageManager.getImage(ImageKeys.LANDMARKS_RUINS);
    case "dovecote":
      return ImageManager.getImage(ImageKeys.LANDMARKS_DOVECOTE);
    default:
      console.log(landmark.type);
  }
};
export default class DemoLandmark extends Component<PropsType, StateType> {
  render() {
    const landmark = this.props.landmark;
    const icon = getLandmarkIcon(landmark);
    return (
      <div className={styles.landmark}>
        <div
          className={styles.landmark_icon}
          style={{ backgroundImage: `url(${icon})` }}
        />
        <div className={styles.landmarkName}>{landmark.name}</div>
      </div>
    );
  }
}
