import { RegisterResponseType } from "./RegisterTypes";
import { createApiActionsHelper } from "../Helpers";
import { OptionsType } from "framework/build/src/types/Api";
import { RegisterResource } from "./RegisterResource";

export const REGISTER_LOAD_ACTION = "REGISTER_LOAD";

export const registerApiActions = createApiActionsHelper<RegisterResponseType>({
  resource: RegisterResource,
  loadAction: REGISTER_LOAD_ACTION,
});

export const loadRegister = ({ username, password }) => {
  const options: OptionsType = {
    body: {
      username,
      password,
    },
  };
  return registerApiActions.loadResource(options);
};
