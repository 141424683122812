import React, { Component } from "react";
import styles from "./SignInBox.module.css";
import shared from "../../../styles/shared.module.css";
import StravaButton from "../StravaButton";
import classNames from "classnames";

export default class SignInBox extends Component {
  render() {
    return (
      <>
        <div className={classNames(shared.heading2, styles.authHeader)}>
          Welcome Back!
        </div>
        <div className={classNames(styles.authMessage, shared.heading4)}>
          Choose your login method
          <br />
          (currently strava only)
        </div>
        <div style={{ flex: 1 }} />

        <StravaButton />
        {/*<GarminButton style={{ marginTop: 10 }} />*/}
        <div className={classNames(styles.platformMessage2, shared.heading5)}>
          Support for other platforms coming soon!
        </div>
      </>
    );
  }
}
