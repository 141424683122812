import { Geolocation } from "@capacitor/geolocation";
import { Point } from "geojson";

async function getLocation() {
  await Geolocation.requestPermissions();
  const position = await Geolocation.getCurrentPosition();
  const point: Point = {
    type: "Point",
    coordinates: [position.coords.longitude, position.coords.latitude],
  };
  return point;
}

export default { getLocation };
