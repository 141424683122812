import React, { ReactElement } from "react";
import sharedStyles from "../../styles/shared.module.css";
import { useHistory } from "react-router-dom";
import styles from "./Header.module.css";
import Content from "../../components/content/Content";
import { Logo } from "../../components/logo/Logo";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import ImageManager from "../../data/images/ImageManager";
import ImageKeys from "../../data/images/ImageKeys";
import { PopupTypes, showPopup } from "../../state/popups/PopupsActions";

import { Button } from "@mui/material";
import { isMobile } from "../../platform/PlatformManager";
import MainHeading from "../../components/headings/MainHeading";
import { useUserQuery } from "../../state/api/ApiSlice";
import { isLoggedInSelector } from "../../state/userslice/UserSlice";
import { useTranslation } from "react-i18next";

type OtherProps = {
  logoOnly?: boolean;
  showAuth?: boolean;
  title?: string; // For use with mobile app only
  rightContent?: ReactElement;
};

export default function Header(props: OtherProps) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const goto = (url: string) => {
    history.push(url);
  };

  const isLoggedIn = useSelector(isLoggedInSelector);
  const { data: userResponse, isLoading: isUserLoading } = useUserQuery(
    {},
    { skip: !isLoggedIn }
  );

  const items = (
    <>
      <div className={styles.link} onClick={() => goto("/tabs/home")}>
        {t("header.dashboard")}
      </div>
      <div className={styles.link} onClick={() => goto("/tabs/my-areas")}>
        {t("header.myCities")}
      </div>
      <div className={styles.link} onClick={() => goto("/tabs/explore")}>
        {t("header.explore")}
      </div>
    </>
  );

  function openPopup() {
    dispatch(showPopup({ popupType: PopupTypes.ACCOUNT }));
  }

  function renderUser() {
    if (userResponse?.success) {
      const user = userResponse.user;
      const name = `${user?.first_name} ${user?.last_name}`;
      return (
        <div className={styles.nameAndSettings} onClick={openPopup}>
          <div className={classNames(sharedStyles.heading4, styles.nameText)}>
            {name}
          </div>
          <div
            style={{
              backgroundImage: `url(${ImageManager.getImage(
                ImageKeys.SETTINGS_ICON
              )})`,
            }}
            className={styles.settings}
          />
        </div>
      );
    }
    return null;
  }
  function renderContent() {
    return (
      <div className={styles.contentContainer}>
        <div className={styles.menuItemsContainer}>{items}</div>
        {renderUser()}
      </div>
    );
  }

  function renderAuthButton() {
    return (
      <div className={styles.signInButtonContainer}>
        <Button color="primary" onClick={() => goto("/signin")}>
          Sign in
        </Button>
      </div>
    );
  }

  const logoOnly = props.logoOnly;
  const showAuth = props.showAuth;

  if (isMobile()) {
    if (props.title) {
      return (
        <MainHeading title={props.title} rightContent={props.rightContent} />
      );
    }
    return <div style={{ marginTop: "env(safe-area-inset-top, 20px)" }} />;
  }
  return (
    <>
      <div className={styles.container}>
        <Content>
          <div className={styles.innerContent}>
            <div
              className={classNames(
                styles.logo,
                logoOnly ? "" : styles.logoMobile
              )}
              onClick={() => goto(isLoggedIn ? "/tabs/home" : "/")}
            >
              <Logo />
            </div>
            {!logoOnly ? renderContent() : null}
            {showAuth ? renderAuthButton() : null}
          </div>
        </Content>
      </div>
      {props.title ? (
        <MainHeading title={props.title} rightContent={props.rightContent} />
      ) : null}
    </>
  );
}
