import React from "react";
import "../../App.css";
import styles from "./Dashboard.module.css";
import Header from "../components/Header";
import WaitForData from "src/components/WaitForData";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import DashboardContentAreaSelector from "./DashboardContentAreaSelector";
import { getUserCityWithHighestPercentComplete } from "../../data/utils/formating/UserCityUtils";
import {
  useGetMostRecentActivityDetailsQuery,
  useGetSettingsQuery,
  useGetUserCitiesQuery,
} from "../../state/api/ApiSlice";
import { IonContent } from "@ionic/react";
import { isAtBottom } from "../../App";
import AccountTierFunctions from "../../data/account/AccountTierFunctions";
import AppAvailableNotification from "../../components/appavailablenotification/AppAvailableNotification";
import NoActivities from "./components/no-activities/NoActivities";
import Content from "../../components/content/Content";
import { Skeleton } from "@mui/material";

function Dashboard() {
  let { id: currentAreaId } = useParams<{ id: string }>();
  const {
    data: userCities,
    isLoading: userCitiesLoading,
  } = useGetUserCitiesQuery({});
  const {
    data: mostRecentActivityDetails,
    isLoading: mostRecentActivityDetailsLoading,
  } = useGetMostRecentActivityDetailsQuery({});
  const { data: settingsResponse } = useGetSettingsQuery();
  function renderContent() {
    if (userCities?.success && settingsResponse?.success) {
      const homeArea = settingsResponse?.settings?.home_area || "";

      if (currentAreaId || mostRecentActivityDetails?.success) {
        const { availableAreas } = AccountTierFunctions.processUserAreasList(
          userCities.cities,
          homeArea
        );
        const availableAreaIds = availableAreas.map((area) => area.area_id);
        /**
         * Logic to decide which user city to display.. Priority order:
         * 1. User city specified in the url (could be undefined)
         * 2. The user city defined as the HOME area
         * 3. User city specified in the most recent activity (could be null if the activity is not in an existing city)
         * 4. User city with the highest percentage complete (should always return something)
         * TODO - Ideally 3 and 4 would be replace with whichever city the user last did an activity in but this is complex to calculate - revisit later
         */

        if (!currentAreaId) {
          const mostRecentActivityAreaId =
            mostRecentActivityDetails?.success &&
            availableAreaIds.includes(
              mostRecentActivityDetails.activityCities[0]?.area_id
            ) && // Check that the areaid is in one of the available areas (On the free tier a user might have an activity in an existing area that isn't available to them)
            mostRecentActivityDetails.activityCities[0]?.user_city_id;

          const homeUserCityId = userCities.cities.find(
            (uc) => uc.area_id === homeArea
          )?.user_city_id;

          currentAreaId =
            homeUserCityId ||
            mostRecentActivityAreaId ||
            getUserCityWithHighestPercentComplete(userCities.cities)
              ?.user_city_id;
        }
        return (
          <DashboardContentAreaSelector
            userAreas={userCities.cities}
            initialCityId={currentAreaId}
          />
        );
      }
    }
    return (
      <Content>
        <NoActivities />
      </Content>
    );
  }

  return (
    <IonContent scrollEvents={true} onIonScroll={isAtBottom}>
      {/* Scroll needed for activity list infinite feed*/}
      <div className={styles.container}>
        <Helmet>
          <title>City Painter - Dashboard</title>
        </Helmet>
        <Header />
        <AppAvailableNotification />

        <WaitForData
          isLoading={userCitiesLoading || mostRecentActivityDetailsLoading}
          loadingElement={<LoadingSkeleton />}
        >
          {renderContent()}
        </WaitForData>
      </div>
    </IonContent>
  );
}

function LoadingSkeleton() {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div style={{ display: "flex" }}>
        <Skeleton
          variant="rectangular"
          style={{ margin: 16, flex: 3 }}
          height={30}
          animation={"wave"}
        />
        <Skeleton
          variant="rectangular"
          style={{ margin: 16, flex: 1 }}
          height={30}
          animation={"wave"}
        />
      </div>
      <Skeleton variant="rectangular" height={"50vh"} />
    </div>
  );
}

export default React.memo(Dashboard);
