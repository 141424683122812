import React, { useEffect } from "react";
import Header from "../components/Header";
import { IonContent } from "@ionic/react";
import {
  useGetLeaderboardForUserQuery,
  useGetUserCitiesQuery,
  useGetUserNeighbourhoodQuery,
  useGroupQuery,
} from "../../state/api/ApiSlice";
import { useLocation, useParams } from "react-router-dom";
import { LeaderboardRowType, TimePeriods, UserCityType } from "cp-server";
import { getResultsFromResponse } from "../../data/ApiData";
import styles from "./Leaderboard.module.css";
import sharedStyles from "../../styles/shared.module.css";
import classNames from "classnames";
import Content from "../../components/content/Content";
import LangManager from "../../data/languages/LangManager";
import LangKeys from "../../data/languages/LangKeys";
import Helmet from "react-helmet";
import { getPercent } from "../../data/utils/PercentageUtils";
import { useDispatch } from "react-redux";
import { PopupTypes, showPopup } from "../../state/popups/PopupsActions";

const titles = {
  [TimePeriods.all]: "All time",
  [TimePeriods.year]: "This year",
  [TimePeriods.monthly]: "This month",
};

function Leaderboard() {
  const dispatch = useDispatch();
  const { id, period, type } = useParams<{
    id: string;
    period: TimePeriods;
    type: "neighbourhood" | "area";
  }>();
  let { search } = useLocation();
  const group_id = new URLSearchParams(search).get("groupId") || undefined;

  const {
    data: leaderboardResponse,
    isLoading: isLeaderboardLoading,
  } = useGetLeaderboardForUserQuery({ id, period, type, group_id });
  const { data: groupsResponse } = useGroupQuery(
    { group_id: group_id || "" },
    { skip: !group_id }
  );
  const group = groupsResponse?.success && groupsResponse.group;

  const results = getResultsFromResponse(leaderboardResponse);
  // const userCity = useGetUserCity(id);
  //console.log(userCity.user_city_id)
  //const {data} = useHistoricUserCityPercentagesQuery({user_city_id: userCity.user_city_id}, {skip: !userCity});
  //console.log(data);

  /**
   * All this is just to get the neighbourhood/area name
   */
  const { data: userNeighbourhoodResponce } = useGetUserNeighbourhoodQuery(
    {
      neighbourhood_id: id,
    },
    { skip: type !== "neighbourhood" }
  );

  const { data: userCities } = useGetUserCitiesQuery(
    {},
    { skip: type !== "area" }
  );

  let name;
  if (type === "area") {
    const city: UserCityType | undefined = userCities?.success
      ? (userCities.cities.find((uc) => uc.area_id === id) as UserCityType)
      : undefined;
    name = city?.name || "";
  }
  if (type === "neighbourhood") {
    name =
      userNeighbourhoodResponce?.success &&
      userNeighbourhoodResponce.user_neighbourhood.name;
  }
  if (group_id && group) {
    name = group.name;
  }
  /**
   * End of code to get the name
   */

  // Find the 'gap' in the otherwise sequantial row numbers. Needed for the appearance of the truncated leaderboard
  const gapIndex = results.findIndex((r, index, array) => {
    return parseInt(r.row_no) + 1 !== parseInt(array[index + 1]?.row_no);
  });

  function renderUserStats() {
    if (!userRow) {
      return (
        <div
          className={styles.keyStatsContainer}
          style={{ gridTemplateColumns: "1fr" }}
        >
          <div className={styles.notInLeaderboard}>
            {LangManager.getLang(LangKeys.LEADERBOARD_NOT_IN_LEADERBOARD)}
          </div>
        </div>
      );
    }
    return (
      <div className={styles.keyStatsContainer}>
        <div className={styles.keyStatBox}>
          <div className={classNames(sharedStyles.text, styles.keyStatLabel)}>
            Rank
          </div>
          <div
            className={classNames(sharedStyles.heading1, styles.keyStatValue)}
          >
            {userRow?.ranking || "-"}
          </div>
        </div>
        <div
          style={{
            backgroundColor: "#dddddd",
            width: 1,
            height: 60,
            marginTop: 5,
          }}
        />

        <div className={styles.keyStatBox}>
          <div className={classNames(sharedStyles.text, styles.keyStatLabel)}>
            Explored
          </div>
          <div
            className={classNames(sharedStyles.heading1, styles.keyStatValue)}
          >
            {getPercent(userRow?.percent_covered) || "--"}%
          </div>
        </div>
      </div>
    );
  }
  const userRow = results.find((lr) => lr.current_user);

  // Show the name update popup if the user does not currently have a name
  useEffect(() => {
    console.log("username", userRow);

    if (userRow && userRow.user_name.trim() === "") {
      console.log("show popup");
      dispatch(showPopup({ popupType: PopupTypes.NAME }));
    }
  }, [userRow]);
  return (
    <>
      <Helmet>
        <title>City Painter - Leaderboard - {titles[period]}</title>
      </Helmet>
      <Header title={`${name} Leaderboard`} />
      <IonContent>
        <div className={styles.topSection}>
          <Content>
            <div className={styles.headingContainer}>
              <div
                className={classNames(sharedStyles.heading1, styles.heading)}
              >
                {period === "all" ? "All Time" : null}
                {period === "year" ? "This year" : null}
                {period === "monthly" ? "This month" : null}
              </div>
            </div>
            {renderUserStats()}
          </Content>
        </div>
        <div className={styles.leaderboardHeader}>
          <LeaderboardRow
            row={{
              row_no: "0",
              ranking: "Rank",
              user_name: "Name",
              percent_covered: "Explored",
              current_user: false,
            }}
          />
        </div>
        {!results.length ? (
          <div className={styles.noEntries}>
            {LangManager.getLang(LangKeys.LEADERBOARD_NO_ENTRIES)}
          </div>
        ) : null}
        {results.map((lr, index) => {
          return (
            <>
              <LeaderboardRow
                row={{
                  ...lr,
                  percent_covered: getPercent(lr.percent_covered),
                }}
                key={index}
              />
              {index === gapIndex ? <div className={styles.gap} /> : null}
            </>
          );
        })}
        {/* Padding for the fixed total box*/}
        <div style={{ height: 50 }} />
        <div className={styles.totalContainer}>
          <div className={classNames(sharedStyles.text, styles.totalText)}>
            Total: {leaderboardResponse?.success && leaderboardResponse.total}
          </div>
        </div>
      </IonContent>
    </>
  );
}

function LeaderboardRow({ row }: { row: LeaderboardRowType }) {
  return (
    <Content
      style={{ backgroundColor: row.current_user ? "var(--orange-2)" : "" }}
    >
      <div className={styles.row}>
        <div className={classNames(styles.rank, sharedStyles.text)}>
          {row.ranking}
        </div>
        <div className={styles.divider} />
        <div className={classNames(styles.name, sharedStyles.text)}>
          {row.user_name}
        </div>
        <div
          className={classNames(
            row.current_user ? styles.percentDark : styles.percent,
            sharedStyles.text
          )}
        >
          {row.percent_covered} %
        </div>
      </div>
    </Content>
  );
}

export default React.memo(Leaderboard);
