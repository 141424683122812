import React, { useCallback } from "react";
import Dialog from "@mui/material/Dialog";
import CloseButton from "../components/CloseButton";
import styles from "./DeleteAccountConfirm.module.css";
import sharedStyles from "../../styles/shared.module.css";
import Button from "@mui/material/Button";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { deleteAccountConfirmPopupSelector } from "../../state/popups/PopupsSelectors";
import { hidePopup, PopupTypes } from "../../state/popups/PopupsActions";
import LangManager from "../../data/languages/LangManager";
import LangKeys from "../../data/languages/LangKeys";
import { useDeleteAccountMutation } from "../../state/api/ApiSlice";
import WaitForData from "../../components/WaitForData";

export default function DeleteAccountConfirm() {
  const dispatch = useDispatch();
  const popup = useSelector(deleteAccountConfirmPopupSelector);

  const hide = useCallback(() => {
    dispatch(hidePopup({ popupType: PopupTypes.DELETE_ACCOUNT_CONFIRM }));
  }, []);

  const [deleteAccount, { isLoading }] = useDeleteAccountMutation();
  return (
    <Dialog open={popup.visible} onClose={hide}>
      <CloseButton hidePopup={hide} />
      <div className={styles.container}>
        <div
          className={classNames(sharedStyles.heading6, styles.message)}
        >{`${LangManager.getLang(
          LangKeys.DELETE_ACCOUNT_CONFIRM_MESSAGE
        )}`}</div>
        <div className={styles.buttonContainer}>
          <Button
            variant="contained"
            color="primary"
            className={styles.okButton}
            onClick={hide}
          >
            {LangManager.getLang(LangKeys.DELETE_ACCOUNT_CANCEL)}
          </Button>
          <WaitForData isLoading={isLoading} size={"small"}>
            <Button
              className={styles.deleteButton}
              onClick={() => deleteAccount()}
            >
              {LangManager.getLang(LangKeys.DELETE_ACCOUNT_CONFIRM)}
            </Button>
          </WaitForData>
        </div>
      </div>
    </Dialog>
  );
}
