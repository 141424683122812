import React from "react";
import { PopupTypes, showPopup } from "../../../../state/popups/PopupsActions";
import { useDispatch } from "react-redux";
import classNames from "classnames";
import sharedStyles from "../../../../styles/shared.module.css";
import styles from "./NoActivities.module.css";
import Content from "../../../../components/content/Content";
import { useUserQuery } from "../../../../state/api/ApiSlice";

// TODO move copy to the lang file
export default function NoActivities() {
  const dispatch = useDispatch();
  const showFeedback = () => {
    dispatch(showPopup({ popupType: PopupTypes.FEEDBACK }));
  };
  const { data: userData } = useUserQuery({});
  const vendor = userData?.success && userData?.vendor;
  return (
    <div className={classNames(sharedStyles.heading3, styles.noActivities)}>
      {`We could not find any activities to import from your ${vendor} account`}
      <br />
      <br />
      {`Please go to the ${vendor} app record a run, cycle or walk. The city you recorded it in should then automatically appear here`}
      <br />
      <br />
      {`If you think this is a mistake please contact us `}
      <span className={styles.link} onClick={showFeedback}>
        here
      </span>
    </div>
  );
}
