import React from "react";
import { useGroupsListQuery } from "../../state/api/ApiSlice";
import { getResultsFromResponse } from "../../data/ApiData";
import Header from "../components/Header";
import { IonContent } from "@ionic/react";
import Helmet from "react-helmet";
import Content from "../../components/content/Content";
import SmallHeading from "../../components/headings/SmallHeading";
import GroupCard from "./components/GroupCard";
import styles from "./GroupsList.module.css";
import DisplayIf from "../../components/display-if/DisplayIf";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import sharedStyles from "../../styles/shared.module.css";
export default function GroupsList() {
  const { data: groupsResponse } = useGroupsListQuery({});
  const groups = getResultsFromResponse(groupsResponse);
  const owned = groups.filter((g) => g.role === "owner");
  const member = groups.filter((g) => g.role === "member");
  const { t } = useTranslation();
  const [edit, setEdit] = React.useState(false);
  return (
    <>
      <Helmet>
        <title>City Painter - Groups</title>
      </Helmet>
      <Header title={`Your groups`} />
      <IonContent>
        <Content>
          {/** Groups that you created */}
          <SmallHeading
            left={"Groups that you created"}
            right={"Create group"}
            link={"/tabs/home/groups/create"}
          ></SmallHeading>
          <section className={styles.groupList}>
            {owned.map((g) => {
              return <GroupCard group={g} />;
            })}
          </section>
          <DisplayIf condition={owned.length === 0}>
            <div className={classNames(sharedStyles.text, styles.noGroups)}>
              {t("groups.noCreatedGroups")}
            </div>
          </DisplayIf>

          {/** Groups that you are a member of */}
          <SmallHeading
            left={"Groups"}
            rightElement={
              <DisplayIf condition={member.length > 0}>
                <div onClick={() => setEdit(!edit)}>
                  {edit ? "Done" : "Edit"}
                </div>
              </DisplayIf>
            }
          ></SmallHeading>
          <div className={classNames(sharedStyles.text, styles.joinText)}>
            {t("groups.howToJoin")}
          </div>
          <DisplayIf condition={member.length === 0}>
            <div className={classNames(sharedStyles.text, styles.noGroups)}>
              {t("groups.noJoinedGroups")}
            </div>
          </DisplayIf>
          <section className={styles.groupList}>
            {member.map((g) => {
              return <GroupCard group={g} edit={edit} />;
            })}
          </section>
        </Content>
      </IonContent>
    </>
  );
}
