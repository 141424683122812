import React from "react";
import { useGroupsListQuery } from "../../../../state/api/ApiSlice";
import { getResultsFromResponse } from "../../../../data/ApiData";
import LangManager from "../../../../data/languages/LangManager";
import LangKeys from "../../../../data/languages/LangKeys";
import styles from "./DashboardGroups.module.css";
import classNames from "classnames";
import sharedStyles from "../../../../styles/shared.module.css";
import DashboardLeaderboardLinks from "../leaderboard/DashboardLeaderboardLinks";
import SmallHeading from "../../../../components/headings/SmallHeading";
import DisplayIf from "../../../../components/display-if/DisplayIf";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function DashboardGroups({
  area_id,
  area_name,
  id,
  type,
}: {
  area_id: string; // the area id that the group is in
  area_name: string; // the area name that the group is in
  id: string; // this could be the neighbourhood id or area id. Yes the area_id and id might be the same... TODO maybe refactor but its barely important
  type: "area" | "neighbourhood";
}) {
  const { data: groupsResponse } = useGroupsListQuery({});
  const groups = getResultsFromResponse(groupsResponse);
  const groupsForArea = groups.filter((g) => g.area_id === area_id);
  const { t } = useTranslation();
  return (
    <div>
      <SmallHeading
        left={LangManager.getLang(LangKeys.GROUPS_DASHBOARD_HEADER)}
        right={"Edit / Create"}
        link={"/tabs/home/groups/list"}
      />
      {groupsForArea.map((g) => {
        return (
          <div className={styles.container}>
            <Link
              to={`/tabs/home/group/${g.group_id}`}
              className={classNames(sharedStyles.text, styles.groupHeader)}
            >
              {g.name}
            </Link>
            <DashboardLeaderboardLinks
              id={id}
              type={type}
              groupId={g.group_id}
            />
          </div>
        );
      })}
      <DisplayIf condition={groupsForArea.length === 0}>
        <div className={classNames(sharedStyles.text, styles.noGroupsText)}>
          {`${t("dashboard.groups.noGroups")} ${area_name}`}{" "}
        </div>
      </DisplayIf>
    </div>
  );
}
