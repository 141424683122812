import { ApiRequestBodies, ApiResponses, ApiRoutes } from "cp-server";
import { EndpointBuilder } from "@reduxjs/toolkit/dist/query/endpointDefinitions";

const Endpoint = (builder: EndpointBuilder<any, any, any>) =>
  builder.query<
    ApiResponses[ApiRoutes.PublicGetCities],
    ApiRequestBodies[ApiRoutes.PublicGetCities]
  >({
    query: (data) => ({
      url: ApiRoutes.PublicGetCities,
      method: "post",
      body: data,
    }),
    async onQueryStarted(arg, { dispatch, queryFulfilled }) {
      try {
        const { data } = await queryFulfilled;
      } catch (err) {
        console.log(err);
      }
    },
  });

export default Endpoint;
