import React, { Component } from "react";
import { connect } from "react-redux";
import Dialog from "@mui/material/Dialog";

import styles from "./ErrorPopup.module.css";
import sharedStyles from "../../styles/shared.module.css";
import { GenericErrorPopupType } from "../../state/popups/PopupsTypes";
import { hidePopup, PopupTypes } from "../../state/popups/PopupsActions";
import { errorPopupSelector } from "../../state/popups/PopupsSelectors";
import classNames from "classnames";
import Button from "@mui/material/Button";
import CloseButton from "../components/CloseButton";

type DispatchProps = { hidePopup: () => void };
type StateProps = {
  errorPopupState: GenericErrorPopupType;
};
type OtherProps = {};
type PropsType = DispatchProps & StateProps & OtherProps;

type StateType = {};
class GenericErrorPopup extends Component<PropsType, StateType> {
  render() {
    return (
      <Dialog
        open={this.props.errorPopupState.visible}
        onClose={this.props.hidePopup}
      >
        <CloseButton hidePopup={this.props.hidePopup} />
        <div className={styles.container}>
          <div
            className={classNames(sharedStyles.heading6, styles.errorMessage)}
            dangerouslySetInnerHTML={{
              __html: this.props.errorPopupState.message || "",
            }}
          ></div>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={styles.okButton}
            fullWidth
            onClick={this.props.hidePopup}
          >
            {"OK"}
          </Button>
        </div>
      </Dialog>
    );
  }
}

function mapStateToProps(state): StateProps {
  return {
    errorPopupState: errorPopupSelector(state),
  };
}

function mapDispatchToProps(dispatch): DispatchProps {
  return {
    hidePopup: () => dispatch(hidePopup({ popupType: PopupTypes.ERROR })),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(GenericErrorPopup);
