import React from "react";
import styles from "./AchievementDetails.module.css";
import Header from "../components/Header";
import WaitForData from "../../components/WaitForData";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import CPMap from "../../components/map/CPMap";
import Content from "../../components/content/Content";
import sharedStyles from "../../styles/shared.module.css";
import classNames from "classnames";
import { AchievementType, LandmarkType, VisitedLandmarkType } from "cp-server";
import {
  getLandmarksSortedByVisited,
  getuniqueVisitedLandmarksCount,
} from "../../data/utils/formating/AchievementUtils";
import Stat from "../../components/stat/Stat";
import LangManager from "../../data/languages/LangManager";
import LangKeys from "../../data/languages/LangKeys";
import AchievementLandmark from "./components/AchievementLandmark";
import ImageKeys from "../../data/images/ImageKeys";
import ImageManager from "../../data/images/ImageManager";
import { useGetAchievementByIdQuery } from "../../state/api/ApiSlice";
import { IonContent } from "@ionic/react";

function AchievementDetails() {
  const { id } = useParams<{ id: string }>();
  const {
    data: achievement,
    isLoading: achievementLoading,
  } = useGetAchievementByIdQuery({ achievement_id: id });

  function renderLandmarks(
    landmarks: Array<LandmarkType>,
    visitedLandmarks: Array<VisitedLandmarkType>
  ) {
    return (
      <div>
        <div
          className={classNames(sharedStyles.heading3, styles.landmarksHeader)}
        >
          {LangManager.getLang(LangKeys.ACHIEVEMENT_LANDMARKS_HEADER)}
        </div>
        <div className={styles.landmarksGrid}>
          {getLandmarksSortedByVisited(landmarks, visitedLandmarks).map(
            (item) => {
              return (
                <AchievementLandmark
                  landmark={item.landmark}
                  visited={item.visited}
                  key={item.landmark.landmark_id}
                />
              );
            }
          )}
        </div>
      </div>
    );
  }

  function renderAchievementSummaryMessage(remaining: number) {
    if (remaining) {
      return (
        <>
          <img
            src={require("../../assets/landing/exclaimation.png")}
            width={23}
            height={23}
          />
          <div
            className={classNames(
              sharedStyles.heading3,
              styles.summaryMessageText
            )}
          >
            {LangManager.getLang(LangKeys.ACHIEVEMENT_VISIT_1)}
            <span style={{ color: "var(--orange-1)" }}>
              {" "}
              {` ${remaining} `}{" "}
            </span>
            {LangManager.getLang(LangKeys.ACHIEVEMENT_VISIT_2)}
          </div>
        </>
      );
    }
    return (
      <div className={styles.completeMessage}>
        <div
          className={styles.completeTick}
          style={{
            backgroundImage: `url(${ImageManager.getImage(
              ImageKeys.TICK_ICON
            )})`,
          }}
        />
        <div className={sharedStyles.heading2}>
          {LangManager.getLang(LangKeys.ACHIEVEMENT_COMPLETE)}
        </div>
      </div>
    );
  }
  function renderAchievementSummary(achievement: AchievementType) {
    const totalLandmarks =
      achievement.landmarks_needed || achievement.landmarks.length;
    const visitedLandmarks = Math.min(
      totalLandmarks,
      getuniqueVisitedLandmarksCount(achievement.visited_landmarks)
    );
    const remaining = totalLandmarks - visitedLandmarks;
    return (
      <div className={styles.summaryContainer}>
        <div className={styles.summaryBar}>
          <img
            className={styles.icon}
            src={
              !remaining
                ? require("../../assets/achievement-badge.png")
                : require("../../assets/achievement-badge-grey.png")
            }
          />
          <div className={styles.progressBarContainer}>
            <div className={styles.progressBarBackground}>
              <div
                className={styles.progressBarFill}
                style={{ flex: visitedLandmarks }}
              >
                <div className={styles.progressFillHighlight} />
              </div>
              <div style={{ flex: remaining }} />
            </div>
          </div>
          <Stat
            label={LangManager.getLang(LangKeys.ACHIEVEMENT_PROGRESS)}
            value={`${visitedLandmarks} / ${totalLandmarks}`}
          />
        </div>
        <div className={styles.summaryMessage}>
          {renderAchievementSummaryMessage(remaining)}
        </div>
      </div>
    );
  }
  function renderContent() {
    const achievementResponse = achievement;
    if (achievementResponse?.success) {
      const achievement = achievementResponse.achievement;
      const landmarks = achievement.landmarks;
      const visits = achievement.visited_landmarks;
      const landmarksWithVisits = landmarks.map((l) => {
        const visit_count = visits.filter(
          (visit) => visit.landmark_id === l.landmark_id
        ).length;
        return {
          ...l,
          visits: `${visit_count}`,
        };
      });
      const area = achievementResponse.area;
      return (
        <div>
          <CPMap
            unfilled_map
            landmarks={landmarksWithVisits || []}
            className={styles.map}
            boundingBox={area.bounding_box}
            area_id={achievementResponse.achievement.area_id}
            bigLandmarks
          />
          <Content>
            <div
              className={classNames(sharedStyles.heading4, styles.subHeader)}
            >
              {achievement.description}
            </div>
            {renderAchievementSummary(achievement)}
            {renderLandmarks(
              achievement.landmarks,
              achievement.visited_landmarks
            )}
          </Content>
        </div>
      );
    }
    return null;
  }
  function getPageTitle() {
    if (achievement?.success) {
      return `${achievement.achievement.name} achievement`;
    }
    return "Achievement";
  }

  return (
    <>
      <Helmet>
        <title>{`City Painter - Achievement details`}</title>
      </Helmet>
      <Header title={getPageTitle()} />
      <IonContent>
        <WaitForData isLoading={achievementLoading}>
          {renderContent()}
        </WaitForData>
      </IonContent>
    </>
  );
}

export default React.memo(AchievementDetails);
