import React, { CSSProperties } from "react";
import styles from "./Stat.module.css";
import shared from "../../styles/shared.module.css";
import classNames from "classnames";
import CompleteTick from "../completetick/CompleteTick";
import LockIcon from "../../assets/lock.svg";
import ArrowIcon from "../../assets/arrow.svg";
import DisplayIf from "../display-if/DisplayIf";
import { Link } from "react-router-dom";

function Stat({
  label,
  value,
  style = {},
  valueColor,
  loading,
  complete,
  editable,
  locked,
}: {
  label: string | number | undefined;
  value: string | number | undefined;
  style?: CSSProperties;
  valueColor?: string;
  loading?: boolean;
  complete?: boolean;
  editable?: boolean; // for easy editing production of marketing marterial
  locked?: boolean; // Lock stat for free users
}) {
  return (
    <div className={styles.statContainer} style={style}>
      <div className={styles.statLabel}>{label}</div>
      {loading ? (
        <div className={classNames(shared.shine, styles.statValueLoading)} />
      ) : (
        <>
          <div
            className={classNames(
              shared.heading2,
              complete ? styles.statValueComplete : styles.statValue
            )}
            style={{
              color: complete ? "var(--green)" : valueColor,
            }}
            contentEditable={editable}
          >
            <DisplayIf condition={Boolean(locked)}>
              <Link
                to={"/tabs/home/premium/upgrade"}
                className={styles.leaderboardRow}
              >
                <img src={LockIcon} height={20} alt={"arrow"} />
              </Link>
            </DisplayIf>
            <DisplayIf condition={!locked}>
              {value}
              {complete ? (
                <CompleteTick size={20} style={{ marginLeft: 8 }} />
              ) : null}
            </DisplayIf>
          </div>
        </>
      )}
    </div>
  );
}

export default React.memo(Stat);
