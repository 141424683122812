import React, { useCallback } from "react";
import { UserCityType } from "cp-server";
import styles from "./CustomAreaEdit.module.css";
import { Config } from "framework";
import sharedStyles from "../../../styles/shared.module.css";
import classNames from "classnames";
import { useDispatch } from "react-redux";
import { PopupTypes, showPopup } from "../../../state/popups/PopupsActions";

type PropsType = { area: UserCityType };
export default function CustomAreaEdit(props: PropsType) {
  const dispatch = useDispatch();
  const area = props.area;
  const showConfirmPopup = useCallback(() => {
    dispatch(
      showPopup({
        popupType: PopupTypes.DELETE_CONFIRM,
        data: { type: "AREA", area_id: area.area_id, name: area.name },
      })
    );
  }, [area.area_id, area.name]);

  return (
    <div className={styles.container}>
      <div className={styles.inner}>
        <div
          className={styles.thumbnail}
          style={{
            backgroundImage: `url(${Config.getConfigItem(
              "domain"
            )}/heatmap_thumbs/${area.user_city_id}.png)`,
          }}
        />
        <div className={classNames(sharedStyles.heading2, styles.cityName)}>
          {area.name}
        </div>
        <div className={styles.buttons}>
          {renderDeleteButton({ deleteArea: showConfirmPopup })}
        </div>
      </div>
    </div>
  );
}

type DeleteButtonProps = {
  deleteArea: () => void;
};
function renderDeleteButton(props: DeleteButtonProps) {
  return (
    <div className={styles.deleteButton} onClick={props.deleteArea}>
      Delete
    </div>
  );
}
