import React from "react";
import Header from "../components/Header";
import styles from "./Neighbourhoods.module.css";
import Content from "../../components/content/Content";
import { useParams } from "react-router-dom";
import { getResultsFromResponse } from "../../data/ApiData";
import CPMap from "../../components/map/CPMap";
import NeighbourhoodsList from "./components/NeighbourhoodsList";
import { MapStyles } from "../../components/map/MapStyles";
import LangManager from "../../data/languages/LangManager";
import LangKeys from "../../data/languages/LangKeys";
import { UserCityType, UserNeighbourhoodType } from "cp-server";
import MapStat from "../../components/mapstat/MapStat";
import SmallHeading from "../../components/headings/SmallHeading";
import {
  useGetUserCitiesQuery,
  useGetUserNeighbourhoodsByUserAreaQuery,
} from "../../state/api/ApiSlice";
import { IonContent } from "@ionic/react";
import { isAtBottom } from "../../App";
import { Helmet } from "react-helmet";
import useAttemptReviewPopupOnUnmount from "../../data/hooks/useAttemptReviewPopupOnUnmount";

function renderMapStats(stats: { neighbourhoodsVisited: string }) {
  return (
    <div
      style={{ height: 0, position: "relative", transform: "translateZ(0)" }}
    >
      <div className={styles.exploredBox}>
        <MapStat
          value={stats.neighbourhoodsVisited}
          label={`${LangManager.getLang(LangKeys.NEIGHBOURHOODS_VISITED)}`}
        />
      </div>
    </div>
  );
}

function getVisitedNeighbourhoodCount(
  neighbourhoods: Array<UserNeighbourhoodType>
) {
  let count = 0;
  neighbourhoods.forEach((n) => {
    if (n.percent_complete !== "0") {
      count++;
    }
  });
  return count;
}

function Neighbourhoods() {
  const { uc_id: userCityId } = useParams<{ uc_id: string }>();
  const { data: userCities } = useGetUserCitiesQuery({});
  const {
    data: userNeighbourhoodsResponse,
  } = useGetUserNeighbourhoodsByUserAreaQuery({ user_city_id: userCityId });

  useAttemptReviewPopupOnUnmount();

  function renderContent() {
    if (userCities?.success && userNeighbourhoodsResponse?.success) {
      const userNeighbourhoods = getResultsFromResponse(
        userNeighbourhoodsResponse
      );
      const userCity: UserCityType = userCities.cities.find(
        (uc) => uc.user_city_id === userCityId
      ) as UserCityType;
      const neighbourhoodGeo = userNeighbourhoods.map((un) => {
        return {
          type: "Feature",
          geometry: un.polygon,
          properties: {
            id: un.neighbourhood_id,
            name: un.name,
            percentComplete: parseFloat(un.percent_complete) / 100,
          },
        };
      });

      //Order by percent complete
      const sortedNeighbourhoods = [...userNeighbourhoods].sort((a, b) =>
        parseFloat(a.percent_complete) > parseFloat(b.percent_complete) ? -1 : 1
      );
      return (
        <>
          <div className={styles.mapContainer}>
            <CPMap
              mapStyle={MapStyles.Neighbourhoods}
              unfilled_map
              area_id={userCity?.area_id}
              boundingBox={userCity?.bounding_box}
              landmarks={[]}
              className={styles.map}
              sources={{
                neighbourhoods: {
                  type: "geojson",
                  data: {
                    type: "FeatureCollection",
                    features: neighbourhoodGeo,
                  },
                },
              }}
            />
            {renderMapStats({
              neighbourhoodsVisited: `${getVisitedNeighbourhoodCount(
                userNeighbourhoods
              )}/${userNeighbourhoods.length}`,
            })}
          </div>
          <Content>
            <SmallHeading
              left={LangManager.getLang(LangKeys.NEIGHBOURHOODS_HEADER_TOP)}
            />
            <NeighbourhoodsList userNeighbourhoods={sortedNeighbourhoods} />
          </Content>
        </>
      );
    }
  }
  function getHeaderTitle() {
    if (userCities?.success) {
      const userCity = userCities.cities.find(
        (uc) => uc.user_city_id === userCityId
      );
      return `${userCity?.name} Neighbourhoods`;
    }
    return "Neighbourhoods";
  }
  return (
    <>
      <Helmet>
        <title>City Painter - Neighbourhoods</title>
      </Helmet>
      <Header title={getHeaderTitle()} />
      <IonContent scrollEvents={true} onIonScroll={isAtBottom}>
        {renderContent()}
      </IonContent>
    </>
  );
}

export default React.memo(Neighbourhoods);
