import React, { Component } from "react";
import styles from "./CityList.module.css";
import City from "../City";
import { CityWithBBSimple } from "cp-server";
type DispatchProps = {};
type StateProps = {};
type OtherProps = {
  cities: Array<CityWithBBSimple>;
};
type PropsType = DispatchProps & StateProps & OtherProps;

type StateType = {};

export default class CityList extends Component<PropsType, StateType> {
  render() {
    const cities = this.props.cities;
    return (
      <div className={styles.cityList}>
        {cities.map((city) => (
          <City city={city} key={city.area_id} />
        ))}
      </div>
    );
  }
}
