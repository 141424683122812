import React, { Component } from "react";
import { LandmarkType, LandmarkTypeEnum } from "cp-server";
import LangManager from "../../../../data/languages/LangManager";
import LangKeys from "../../../../data/languages/LangKeys";
import styles from "../../CityDetails.module.css";
import sharedStyles from "../../../../styles/shared.module.css";
import Toggle from "../../../components/toggle/Toggle";
import Landmark from "../../../components/landmark/Landmark";
import classNames from "classnames";
import CustomSelect from "../../../components/select/Select";
type DispatchProps = {};
type StateProps = {};
type OtherProps = { landmarks: Array<LandmarkType> };
type PropsType = StateProps & DispatchProps & OtherProps;

type StateType = { filter: string };
const tabs = [
  { id: "all", label: LangManager.getLang(LangKeys.LANDMARK_HEADER_ALL) },
  {
    id: LandmarkTypeEnum.artwork,
    label: LangManager.getLang(LangKeys.LANDMARK_HEADER_ARTWORKS),
  },
  {
    id: LandmarkTypeEnum.peak,
    label: LangManager.getLang(LangKeys.LANDMARK_HEADER_PEAKS),
  },
  {
    id: LandmarkTypeEnum.monument,
    label: LangManager.getLang(LangKeys.LANDMARK_HEADER_MONUMENTS),
  },
  {
    id: LandmarkTypeEnum.memorial,
    label: LangManager.getLang(LangKeys.LANDMARK_HEADER_MEMORIALS),
  },
  {
    id: LandmarkTypeEnum.viewpoint,
    label: LangManager.getLang(LangKeys.LANDMARK_HEADER_VIEWPORTS),
  },
  { id: "other", label: LangManager.getLang(LangKeys.LANDMARK_HEADER_OTHERS) },
];
export default class CityLandmarks extends Component<PropsType, StateType> {
  constructor(props) {
    super(props);
    this.state = {
      filter: "all",
    };
  }

  onChangeType = (id: string) => {
    this.setState({ filter: id });
  };
  render() {
    const others = [LandmarkTypeEnum.archaeological_site];
    const landmarks = this.props.landmarks;
    const filter = this.state.filter;
    const filteredLandmarks =
      filter === "all"
        ? landmarks
        : landmarks.filter((l) => {
            if (filter === "other") {
              return others.includes(l.type);
            }
            return l.type === filter;
          });
    const sortedLandmarks = [...filteredLandmarks].sort((a, b) => {
      return a.name > b.name ? 1 : -1;
    });

    return (
      <div className={styles.landmarksContainer}>
        <div
          className={classNames(sharedStyles.heading3, styles.landmarksHeader)}
        >
          {LangManager.getLang(LangKeys.CITY_DETAILS_LANDMARKS_HEADER)}
          <div className={styles.mobileDropDown}>
            <CustomSelect
              value={this.state.filter}
              onChange={this.onChangeType}
              buttons={tabs}
            />
          </div>
        </div>
        <div className={styles.toggleContainer}>
          <Toggle
            onChange={this.onChangeType}
            buttons={tabs}
            initialIndex={0}
          />
        </div>

        {sortedLandmarks.map((l) => {
          return (
            <div className={styles.landmark} key={l.landmark_id}>
              <Landmark landmark={l} />
            </div>
          );
        })}
      </div>
    );
  }
}
