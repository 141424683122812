// Remove duplicates based on osm_id
// duplicates occur because a landmark can exist in multiple areas and therefore multiple entries can be genrated
import { LandmarkType, Neighbourhood } from "cp-server";
import { getUnique } from "../Unique";

function removeDuplicates(
  landmarks: Array<Neighbourhood> | undefined
): Neighbourhood[] {
  if (!landmarks) return [];
  const ids = landmarks.map((l) => l.mapdb_id);
  const unique_ids = getUnique(ids);
  return unique_ids.map(
    (id) => landmarks.find((l) => l.mapdb_id === id) as Neighbourhood
  );
}

export default { removeDuplicates };
