import UploadActivityEndpoint from "./endpoints/uploadactivity/UploadActivity";
import GroupDeleteEndpoint from "./endpoints/groupdelete/GroupDelete";
import AreasForPointEndpoint from "./endpoints/areasforpoint/AreasForPoint";
import ReviewPopupLogAttemptEndpoint from "./endpoints/reviewpopuplogattempt/ReviewPopupLogAttempt";
import ReviewPopupCanShowEndpoint from "./endpoints/reviewpopupcanshow/ReviewPopupCanShow";
import GetActivitiesWithinAreaEndpoint from "./endpoints/getactivitieswithinarea/GetActivitiesWithinArea";
import DeleteActivityEndpoint from "./endpoints/deleteactivity/DeleteActivity";
import GroupRemoveMemberEndpoint from "./endpoints/groupremovemember/GroupRemoveMember";
import GroupEndpoint from "./endpoints/group/Group";
import GroupAddMemberEndpoint from "./endpoints/groupaddmember/GroupAddMember";
import GroupCreateEndpoint from "./endpoints/groupcreate/GroupCreate";
import GroupGetMembersEndpoint from "./endpoints/groupgetmembers/GroupGetMembers";
import GroupsListEndpoint from "./endpoints/groupslist/GroupsList";
import HistoricUserCityPercentagesEndpoint from "./endpoints/historicusercitypercentages/HistoricUserCityPercentages";
import AddUserEndpoint from "./endpoints/adduser/AddUser";
import EmailActionEndpoint from "./endpoints/emailaction/EmailAction";
import SetSettingsEndpoint from "./endpoints/setsettings/SetSettings";
import GetSettingsEndpoint from "./endpoints/getsettings/GetSettings";
import AddEventEndpoint from "./endpoints/addevent/AddEvent";
import PublicGetCitiesEndpoint from "./endpoints/publicgetcities/PublicGetCities";
import ReportInaccessibleAreaEndpoint from "./endpoints/reportinaccessiblearea/ReportInaccessibleArea";
import DeleteAccountEndpoint from "./endpoints/deleteaccount/DeleteAccount";
import SubscriptionGetEndpoint from "./endpoints/subscriptionget/SubscriptionGet";
import UpdateUserEndpoint from "./endpoints/updateuser/UpdateUser";
import UserEndpoint from "./endpoints/user/User";
import SubscriptionGetAvailableProductsEndpoint from "./endpoints/subscriptiongetavailableproducts/SubscriptionGetAvailableProducts";
import SubscriptionGetPortalUrlEndpoint from "./endpoints/subscriptiongetportalurl/SubscriptionGetPortalUrl";
import SubscriptionStoreEndpoint from "./endpoints/subscriptionstore/SubscriptionStore";
import SubscriptionCreateCheckoutEndpoint from "./endpoints/subscriptioncreatecheckout/SubscriptionCreateCheckout";
import GetLeaderboardForUserEndpoint from "./endpoints/getleaderboardforuser/GetLeaderboardForUser";
import GetMostRecentActivityDetailsEndpoint from "./endpoints/getmostrecentactivitydetails/GetMostRecentActivityDetails";
import GetCityByIdEndpoint from "./endpoints/getcitybyid/GetCityById";
import GetLandmarksByAreaIdEndpoint from "./endpoints/getlandmarksbyareaid/GetLandmarksByAreaId";
import GetActivityPercentCoveredEndpoint from "./endpoints/getactivitypercentcovered/GetActivityPercentCovered";
import GetActivitiesEndpoint from "./endpoints/getactivities/GetActivities";
import GetCitiesEndpoint from "./endpoints/getcities/GetCities";
import GetAchievementByIdEndpoint from "./endpoints/getachievementbyid/GetAchievementById";
import GetLandmarkDetailsEndpoint from "./endpoints/getlandmarkdetails/GetLandmarkDetails";
import GetLandmarksWithVisitsByNeighbourhoodEndpoint from "./endpoints/getlandmarkswithvisitsbyneighbourhood/GetLandmarksWithVisitsByNeighbourhood";
import GetUserNeighbourhoodEndpoint from "./endpoints/getuserneighbourhood/GetUserNeighbourhood";
import TopNeighbourhoodsEndpoint from "./endpoints/topneighbourhoods/TopNeighbourhoods";
import GetUserNeighbourhoodsByUserAreaEndpoint from "./endpoints/getuserneighbourhoodsbyuserarea/GetUserNeighbourhoodsByUserArea";
import GetLandmarksWithVisitsInAreaEndpoint from "./endpoints/getlandmarkswithvisitsinarea/GetLandmarksWithVisitsInArea";
import GetAchievementsForUserByAreaEndpoint from "./endpoints/getachievementsforuserbyarea/GetAchievementsForUserByArea";
import ActivityDetailsEndpoint from "./endpoints/activitydetails/ActivityDetails";
import DeleteCustomAreaEndpoint from "./endpoints/deletecustomarea/DeleteCustomArea";
import AddCustomAreaEndpoint from "./endpoints/addcustomarea/AddCustomArea";
import GetUserCitiesEndpoint from "./endpoints/getusercities/GetUserCities";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { ReduxStateType } from "../ReduxStateType";
import { accessTokenSelector } from "../userslice/UserSlice";

export const DOMAIN =
  process.env.REACT_APP_SERVER === "local"
    ? "http://localhost:8080"
    : "https://citypainter.io";

export enum TagTypes {
  groups = "groups",
  user_areas = "user_areas",
  user_details = "user_details",
  settings = "settings",
  activities = "activities",
  review_popup = "review_popup",
  leaderboard = "leaderboard",
}

export const apiSlice = createApi({
  reducerPath: "api",
  tagTypes: Object.values(TagTypes),
  baseQuery: fetchBaseQuery({
    baseUrl: `${DOMAIN}/api`, //"https://citypainter.io/api",
    prepareHeaders: (headers, { getState }) => {
      const token = accessTokenSelector(getState());
      // If we have a token set in state, let's assume that we should be passing it.
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    /*@endpoint-template-hook*/
    uploadActivity: UploadActivityEndpoint(builder),
    groupDelete: GroupDeleteEndpoint(builder),
    areasForPoint: AreasForPointEndpoint(builder),
    reviewPopupLogAttempt: ReviewPopupLogAttemptEndpoint(builder),
    reviewPopupCanShow: ReviewPopupCanShowEndpoint(builder),
    getActivitiesWithinArea: GetActivitiesWithinAreaEndpoint(builder),
    deleteActivity: DeleteActivityEndpoint(builder),
    groupRemoveMember: GroupRemoveMemberEndpoint(builder),
    group: GroupEndpoint(builder),
    groupAddMember: GroupAddMemberEndpoint(builder),
    groupCreate: GroupCreateEndpoint(builder),
    groupGetMembers: GroupGetMembersEndpoint(builder),
    groupsList: GroupsListEndpoint(builder),
    historicUserCityPercentages: HistoricUserCityPercentagesEndpoint(builder),
    addUser: AddUserEndpoint(builder),
    emailAction: EmailActionEndpoint(builder),
    setSettings: SetSettingsEndpoint(builder),
    getSettings: GetSettingsEndpoint(builder),
    addEvent: AddEventEndpoint(builder),
    publicGetCities: PublicGetCitiesEndpoint(builder),
    reportInaccessibleArea: ReportInaccessibleAreaEndpoint(builder),
    deleteAccount: DeleteAccountEndpoint(builder),
    subscriptionGet: SubscriptionGetEndpoint(builder),
    updateUser: UpdateUserEndpoint(builder),
    user: UserEndpoint(builder),
    subscriptionGetAvailableProducts: SubscriptionGetAvailableProductsEndpoint(
      builder
    ),
    subscriptionGetPortalUrl: SubscriptionGetPortalUrlEndpoint(builder),
    subscriptionStore: SubscriptionStoreEndpoint(builder),
    subscriptionCreateCheckout: SubscriptionCreateCheckoutEndpoint(builder),
    getLeaderboardForUser: GetLeaderboardForUserEndpoint(builder),
    getMostRecentActivityDetails: GetMostRecentActivityDetailsEndpoint(builder),
    getCityById: GetCityByIdEndpoint(builder),
    getLandmarksByAreaId: GetLandmarksByAreaIdEndpoint(builder),
    getActivityPercentCovered: GetActivityPercentCoveredEndpoint(builder),
    getActivities: GetActivitiesEndpoint(builder),
    getCities: GetCitiesEndpoint(builder),
    getAchievementById: GetAchievementByIdEndpoint(builder),
    getLandmarkDetails: GetLandmarkDetailsEndpoint(builder),
    getLandmarksWithVisitsByNeighbourhood: GetLandmarksWithVisitsByNeighbourhoodEndpoint(
      builder
    ),
    getUserNeighbourhood: GetUserNeighbourhoodEndpoint(builder),
    topNeighbourhoods: TopNeighbourhoodsEndpoint(builder),
    getUserNeighbourhoodsByUserArea: GetUserNeighbourhoodsByUserAreaEndpoint(
      builder
    ),
    getLandmarksWithVisitsInArea: GetLandmarksWithVisitsInAreaEndpoint(builder),
    getAchievementsForUserByArea: GetAchievementsForUserByAreaEndpoint(builder),
    activityDetails: ActivityDetailsEndpoint(builder),
    deleteCustomArea: DeleteCustomAreaEndpoint(builder),
    addCustomArea: AddCustomAreaEndpoint(builder),
    getUserCities: GetUserCitiesEndpoint(builder),
  }),
});

export const {
  usePrefetch,
  useGetUserCitiesQuery,
  useAddCustomAreaMutation,
  useDeleteCustomAreaMutation,
  useActivityDetailsQuery,
  useGetAchievementsForUserByAreaQuery,
  useGetLandmarksWithVisitsInAreaQuery,
  useGetUserNeighbourhoodsByUserAreaQuery,
  useTopNeighbourhoodsQuery,
  useGetUserNeighbourhoodQuery,
  useGetLandmarksWithVisitsByNeighbourhoodQuery,
  useGetLandmarkDetailsQuery,
  useGetAchievementByIdQuery,
  useGetCitiesQuery,
  useLazyGetCitiesQuery,
  useGetActivitiesQuery,
  useLazyGetActivityPercentCoveredQuery,
  useGetLandmarksByAreaIdQuery,
  useGetCityByIdQuery,
  useGetMostRecentActivityDetailsQuery,
  useGetLeaderboardForUserQuery,
  useSubscriptionCreateCheckoutMutation,
  useSubscriptionStoreMutation,
  useSubscriptionGetPortalUrlMutation,
  useSubscriptionGetAvailableProductsQuery,
  useUserQuery,
  useUpdateUserMutation,
  useSubscriptionGetQuery,
  useDeleteAccountMutation,
  useReportInaccessibleAreaMutation,
  usePublicGetCitiesQuery,
  useGetSettingsQuery,
  useSetSettingsMutation,
  useAddUserMutation,
  useHistoricUserCityPercentagesQuery,
  useGroupsListQuery,
  useGroupCreateMutation,
  useGroupQuery,
  useGroupGetMembersQuery,
  useGroupRemoveMemberMutation,
  useGroupAddMemberMutation,
  useDeleteActivityMutation,
  useGetActivitiesWithinAreaQuery,
  useReviewPopupCanShowQuery,
  useReviewPopupLogAttemptMutation,
  useAddEventMutation,
  useAreasForPointQuery,
  useLazyAreasForPointQuery,
  useGroupDeleteMutation,
  useLazyUserQuery,
  useUploadActivityMutation,
} = apiSlice;
