import React from "react";
import { MenuItem, Select } from "@mui/material";
import sharedStyles from "../../styles/shared.module.css";
import { TimePeriods } from "cp-server";
import { IonSelect, IonSelectOption } from "@ionic/react";

export default function TimePeriodSelect({
  period,
  setPeriod,
}: {
  period: TimePeriods;
  setPeriod: (value) => void;
}) {
  // TODO
  // return (
  //   <IonSelect
  //     onIonChange={(e) => console.log(`ionChange fired with value: ${e.detail.value}`)}
  //
  //   >
  //     <IonSelectOption value={TimePeriods.all} style={{ color: "var(--orange-1)" }}>
  //       ALL TIME
  //     </IonSelectOption>
  //     <IonSelectOption value={TimePeriods.year} style={{ color: "var(--orange-1)" }}>
  //       THIS YEAR
  //     </IonSelectOption>
  //     <IonSelectOption value={TimePeriods.monthly} style={{ color: "var(--orange-1)" }}>
  //       THIS MONTH
  //     </IonSelectOption>
  //   </IonSelect>
  // )

  return (
    <Select
      style={{ color: "var(--orange-1)" }}
      classes={{ root: sharedStyles.text }}
      disableUnderline
      inputProps={{ "aria-label": "Without label" }}
      value={period}
      variant={"standard"}
      onChange={(e) => {
        setPeriod(e.target.value as string);
      }}
    >
      <MenuItem
        value={TimePeriods.all}
        classes={{ root: sharedStyles.text }}
        style={{ color: "var(--orange-1)", textTransform: "uppercase" }}
      >
        ALL TIME
      </MenuItem>
      <MenuItem
        value={TimePeriods.year}
        classes={{ root: sharedStyles.text }}
        style={{ color: "var(--orange-1)" }}
      >
        THIS YEAR
      </MenuItem>
      <MenuItem
        value={TimePeriods.monthly}
        classes={{ root: sharedStyles.text }}
        style={{ color: "var(--orange-1)" }}
      >
        THIS MONTH
      </MenuItem>
    </Select>
  );
}
