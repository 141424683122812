import React from "react";
import styles from "./LandmarkDetails.module.css";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import Header from "../components/Header";
import CPMap from "../../components/map/CPMap";
import Content from "../../components/content/Content";
import sharedStyles from "../../styles/shared.module.css";
import classNames from "classnames";
import { getLandmarkType } from "../../data/utils/formating/LandmarkFormatting";
import LangManager from "../../data/languages/LangManager";
import LangKeys from "../../data/languages/LangKeys";
import Stat from "../../components/stat/Stat";

import { Helmet } from "react-helmet";
import ActivitiesList from "../dashboard/components/activity/activitieslist/ActivitiesList";
import SmallHeading from "../../components/headings/SmallHeading";
import {
  useGetActivitiesQuery,
  useGetLandmarkDetailsQuery,
} from "../../state/api/ApiSlice";
import { IonContent } from "@ionic/react";
import { isAtBottom } from "../../App";
import { getResultsFromResponse } from "../../data/ApiData";

function LandmarkDetails() {
  const { id: landmark_id } = useParams<{ id: string }>();
  const { data: landmarkDetails } = useGetLandmarkDetailsQuery({ landmark_id });
  const { data: activitiesResponse } = useGetActivitiesQuery({
    page: `1`,
    landmark_id,
    page_size: "6",
  });
  const activities = getResultsFromResponse(activitiesResponse);
  function renderActivities(landmarkId: string) {
    return (
      <div className={styles.activitiesContainer}>
        <SmallHeading
          left={LangManager.getLang(LangKeys.LANDMARK_DETAILS_VISITS)}
        />
        {activities.length === 0 && (
          <div className={styles.statsContainer}>
            <div
              className={classNames(sharedStyles.heading4, styles.noVisitsText)}
            >
              {LangManager.getLang(LangKeys.LANDMARK_DETAILS_NO_VISITS)}
            </div>
          </div>
        )}
        <ActivitiesList key={landmarkId} filter={{ landmark_id: landmarkId }} />
      </div>
    );
  }
  function renderStats(
    stats: Array<{ type: string; count: string }>,
    firstLabel: string
  ) {
    const statsObject = stats.reduce((acc, value) => {
      return { ...acc, [value.type]: value.count };
    }, {});
    const total = stats.reduce((acc, value) => {
      return acc + parseInt(value.count);
    }, 0);

    const runs = parseInt(statsObject["Run"] || "0");
    const cycles = parseInt(statsObject["Ride"] || "0");
    const walks =
      parseInt(statsObject["Walk"] || "0") +
      parseInt(statsObject["Hike"] || "0");
    return (
      <div className={styles.statsContainer}>
        <Stat
          label={LangManager.getLang(LangKeys.LANDMARK_DETAILS_TOTAL_VISITS)}
          value={total}
          valueColor="var(--orange-2)"
          style={{ width: 80 }}
        />
        <div className={styles.divider} />
        <Stat
          label={LangManager.getLang(LangKeys.LANDMARK_DETAILS_RUNS)}
          value={runs}
          style={{ marginRight: 35 }}
        />
        <Stat
          label={LangManager.getLang(LangKeys.LANDMARK_DETAILS_CYCLE)}
          value={cycles}
          style={{ marginRight: 35 }}
        />
        <Stat
          label={LangManager.getLang(LangKeys.LANDMARK_DETAILS_WALK)}
          value={walks}
        />
      </div>
    );
  }

  function renderContent() {
    if (
      landmarkDetails?.success &&
      landmarkDetails.landmark.landmark_id === landmark_id
    ) {
      const landmark = landmarkDetails.landmark;
      const area = landmarkDetails.area;
      return (
        <div className={styles.container}>
          <Content
            style={{ paddingRight: 0, paddingLeft: 0 }}
            key={landmark_id}
          >
            <CPMap
              area_id={landmark.area_id}
              boundingBox={area.bounding_box}
              center={landmark?.way_point?.coordinates}
              zoom={14}
              landmarks={[landmark]}
              className={styles.map}
              bigLandmarks
              key={landmark_id}
            />
          </Content>
          <Content>
            <div
              className={classNames(sharedStyles.heading3, styles.subheader)}
            >{`${getLandmarkType(landmark.type)} in ${area.name}`}</div>
            <SmallHeading
              left={LangManager.getLang(LangKeys.LANDMARK_DETAILS_EVERYONE)}
            />
            {renderStats(
              landmarkDetails.publicStats,
              LangManager.getLang(LangKeys.LANDMARK_DETAILS_TOTAL_VISITS)
            )}
            <SmallHeading
              left={LangManager.getLang(LangKeys.LANDMARK_DETAILS_YOU)}
            />

            {renderStats(
              landmarkDetails.stats,
              LangManager.getLang(LangKeys.LANDMARK_DETAILS_YOU)
            )}
            {renderActivities(landmark.landmark_id)}
          </Content>
        </div>
      );
    }
  }
  function getHeading() {
    if (landmarkDetails?.success) {
      return landmarkDetails.landmark.name;
    }
    return "Landmark";
  }
  return (
    <>
      <Helmet>
        <title>City Painter - Landmark Details</title>
      </Helmet>
      <Header title={getHeading()} />
      <IonContent scrollEvents={true} onIonScroll={isAtBottom}>
        {/* Scroll needed for activity list infinite feed*/}
        {renderContent()}
      </IonContent>
    </>
  );
}
export default React.memo(LandmarkDetails);
