import React, { Component } from "react";
import AccountPopup from "./account/AccountPopup";
import FeedbackPopup from "./feedback/FeedbackPopup";
import ErrorPopup from "./error/ErrorPopup";
import DeleteConfirmPopup from "./deleteconfirmpopup/DeleteConfirmPopup";
import VendorPermissionsErrorPopup from "./vendorpermissionserror/VendorPermissionsErrorPopup";
import DeleteAccountConfirm from "./delete-account-confirm/DeleteAccountConfirm";
import NamePopup from "./name/NamePopup";
import ActivityDescriptionPermissionsPopup from "./activity-description-permissions/ActivityDescriptionPermissionsPopup";

type DispatchProps = {};
type StateProps = {};
type OtherProps = {};
type PropsType = StateProps & DispatchProps & OtherProps;

type StateType = {};

export default class PopupRenderer extends Component<PropsType, StateType> {
  render() {
    return (
      <div>
        <NamePopup />
        <FeedbackPopup />
        <AccountPopup />
        <ErrorPopup />
        <DeleteConfirmPopup />
        <VendorPermissionsErrorPopup />
        <DeleteAccountConfirm />
        <ActivityDescriptionPermissionsPopup />
      </div>
    );
  }
}
