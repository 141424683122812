import React, { useEffect, useRef, useState } from "react";
import Header from "../components/Header";
import { Helmet } from "react-helmet";
import { IonButton, IonContent } from "@ionic/react";
import CPMap from "../../components/map/CPMap";
import {
  useGetActivitiesWithinAreaQuery,
  useGetLandmarksWithVisitsInAreaQuery,
  useLazyAreasForPointQuery,
  useUploadActivityMutation,
} from "../../state/api/ApiSlice";
import DisplayIf from "../../components/display-if/DisplayIf";
import { TimePeriods, UserCityType } from "cp-types";
import { MapStyles } from "../../components/map/MapStyles";
import styles from "./LiveMap.module.css";
import { useGetUserCity } from "../../data/hooks/useGetUserCity";
import bbox from "@turf/bbox";
import maplibregl, { LayerSpecification } from "maplibre-gl";
import CanvasLayer from "./canvas-layer/CanvasLayer";
import { heatmapSource } from "../../components/map/Sources";
import { isAndroid, isApp, isWeb } from "../../platform/PlatformManager";
import Location from "../../data/utils/Location";
import Content from "../../components/content/Content";
import sharedStyles from "../../styles/shared.module.css";
import classNames from "classnames";
import { useHistory } from "react-router-dom";

export default function LiveMap() {
  const [loadAreas, { data: areasResponse }] = useLazyAreasForPointQuery();
  const [trys, setTrys] = useState(0);
  const [startPoint, setStartPoint] = useState<[number, number]>([0, 0]);
  async function getLocation() {
    Location.getLocation().then((point) => {
      setStartPoint([point.coordinates[0], point.coordinates[1]]);
      setTrys(trys + 1);
      loadAreas({ point });
    });
  }

  function retry() {
    setTrys(0);
    getLocation();
  }
  useEffect(() => {
    if (isApp()) {
      // Get initial location and load areas for it
      getLocation();
    }
  }, []);
  //TODO reduce tries?
  useEffect(() => {
    if (
      areasResponse?.success &&
      areasResponse.results.length === 0 &&
      trys < 5
    ) {
      setTimeout(async () => {
        getLocation();
      }, 2000);
    }
  }, [areasResponse, trys, setTrys]);

  const userCity = useGetUserCity(
    areasResponse?.success ? areasResponse.results[0]?.area_id : ""
  );

  const loading = trys < 5 && !userCity;

  return (
    <>
      <Helmet>
        <title>City Painter - Activity Details</title>
      </Helmet>
      <Header title={"Live Map"} />
      <IonContent>
        {/* If theres no map or the user is not in a supported area*/}
        <DisplayIf condition={!userCity}>
          <Content>
            <div className={styles.noAreaContent}>
              <DisplayIf condition={loading}>Loading...</DisplayIf>
              <DisplayIf condition={!loading && !userCity}>
                <div
                  className={classNames(
                    sharedStyles.text,
                    styles.unsupportedAreaText
                  )}
                >
                  You do not appear to be in a supported area
                </div>
                <IonButton onClick={retry} className={styles.retryButton}>
                  Retry
                </IonButton>
              </DisplayIf>
            </div>
          </Content>
        </DisplayIf>

        {/*Display the map if all is well*/}
        <DisplayIf condition={Boolean(userCity)}>
          <Map area={userCity as UserCityType} startPoint={startPoint} />
        </DisplayIf>
      </IonContent>
    </>
  );
}

function Map({
  area,
  startPoint,
}: {
  area: UserCityType;
  startPoint: [number, number];
}) {
  const history = useHistory();
  const [centered, setCentered] = useState(true);
  const [recording, setRecording] = useState(false);
  const [canvas, setCanvas] = useState<CanvasLayer>(
    new CanvasLayer("live-canvas")
  );
  const { data: landmarks } = useGetLandmarksWithVisitsInAreaQuery({
    area_id: area.area_id,
  });

  const { data: activitiesResponse } = useGetActivitiesWithinAreaQuery({
    area_id: area.area_id,
  });

  const [
    uploadActivity,
    { data: uploadActivityResponse },
  ] = useUploadActivityMutation();

  useEffect(() => {
    if (activitiesResponse?.success) {
      canvas.setActivities(activitiesResponse.geojson);
    }
  }, [activitiesResponse]);

  useEffect(() => {
    canvas
      .setup({
        area,
        bounds: { bounds: bbox(area.bounding_box), zoom: 12 },
        startPoint,
      })
      .then(() => {
        //canvas.draw(coords);
      });
  }, []);
  useEffect(() => {
    canvas.setCentered(centered);
  }, [centered]);
  useEffect(() => {
    if (recording) {
      canvas.startRecording();
    } else {
      canvas.stopRecording();
    }
  }, [recording]);

  useEffect(() => {
    if (uploadActivityResponse?.success) {
      const activityId = uploadActivityResponse.activity_id;
      history.replace(`/tabs/home/activity/${activityId}`);
    }
  }, [uploadActivityResponse]);

  function saveRecording() {
    const data = canvas.getCoords();
    uploadActivity({ line: data });
  }

  if (canvas && landmarks?.success) {
    const bBoxCoords = area.bounding_box.coordinates[0].slice(1, 5);
    const source = {
      type: "canvas",
      canvas: canvas.getCanvasId(),
      // @ts-ignore
      coordinates: bBoxCoords,
      animate: true,
    };
    const canvasLayer: LayerSpecification = {
      id: "canvas-layer",
      type: "raster",
      source: "canvas-source",
      paint: {
        "raster-fade-duration": 0,
      },
    };

    return (
      <>
        <CPMap
          className={styles.map}
          mapStyle={MapStyles.LiveMap}
          area_id={area.area_id}
          boundingBox={area.bounding_box}
          landmarks={[]}
          zoom={14}
          center={startPoint}
          onBoundsChange={(bounds, zoom) => {
            //setCentered(false);
            //canvas.setBB(bounds, zoom);
            //canvas.draw();
          }}
          sources={{
            ...heatmapSource(area.user_city_id, TimePeriods.all),
            // activity: { type: "geojson", data: testActivityGeoJson },
           // "canvas-source": source,
          }}
          layers={[canvasLayer]}
          unfilled_map
          onSetup={(map: maplibregl.Map) => canvas.setMap(map)}
        />

        <canvas
          height={canvas?.getHeight()}
          width={canvas?.getWidth()}
          id={canvas?.getCanvasId()}
          key={canvas?.getCanvasId()}
          style={{ display: "none" }}
        />
        <IonButton
          disabled={centered}
          onClick={() => {
            setCentered(true);
          }}
        >
          Center
        </IonButton>
        <IonButton
          onClick={() => {
            setRecording(!recording);
          }}
        >
          {recording ? "Stop" : "Start"}
        </IonButton>
        <IonButton
          disabled={recording || canvas.getCoords()?.length === 0}
          onClick={() => canvas.clearRecording()}
        >
          Clear
        </IonButton>
        <IonButton onClick={saveRecording}>Save</IonButton>
      </>
    );
  } else {
    return null;
  }
}
