import React from "react";
import { UserCityType, UserNeighbourhoodSimple } from "cp-server";
import { getResultsFromResponse } from "../../../../data/ApiData";
import styles from "./DashboardNeighbourhoods.module.css";
import LangManager from "../../../../data/languages/LangManager";
import LangKeys from "../../../../data/languages/LangKeys";

import SmallHeading from "../../../../components/headings/SmallHeading";
import { Config } from "framework";
import classNames from "classnames";
import sharedStyles from "../../../../styles/shared.module.css";
import { Link } from "react-router-dom";
import {
  useTopNeighbourhoodsQuery,
  useUserQuery,
} from "../../../../state/api/ApiSlice";
import moment from "moment";
import { getPercent } from "../../../../data/utils/PercentageUtils";

type PropsType = {
  userCity: UserCityType;
};

function DashboardNeighbourhoods({ userCity }: PropsType) {
  const { data: userNeighbourhoodsResponse } = useTopNeighbourhoodsQuery({
    user_city_id: userCity.user_city_id,
  });
  const userNeighbourhoods = getResultsFromResponse(userNeighbourhoodsResponse);
  const topNeighbourhoods = [...userNeighbourhoods]
    .sort((a, b) =>
      parseFloat(a.percent_complete) > parseFloat(b.percent_complete) ? -1 : 1
    )
    .slice(0, 6);

  return (
    <div>
      <SmallHeading
        left={LangManager.getLang(LangKeys.DASHBOARD_NEIGHBOURHOODS)}
        right={LangManager.getLang(LangKeys.DASHBOARD_NEIGHBOURHOODS_SEE_ALL)}
        link={`/tabs/home/neighbourhoods/${userCity.user_city_id}`}
      />
      <div className={styles.container}>
        <div
          className={styles.thumbnail}
          style={{
            backgroundImage: `url(${Config.getConfigItem(
              "domain"
            )}/area_neighbourhoods_thumbs/${userCity.user_city_id}.png)`,
          }}
        />
        <div>
          <div
            className={classNames(
              sharedStyles.heading3,
              styles.topNeighbourhoodsHeader
            )}
          >
            {LangManager.getLang(LangKeys.DASHBOARD_NEIGHBOURHOODS_TOP)}
          </div>
          <div className={styles.topNeighbourhoods}>
            {topNeighbourhoods.map((un) => {
              return (
                <TopNeighbourhood
                  userNeighbourhood={un}
                  key={un.neighbourhood_id}
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

function TopNeighbourhood(props: {
  userNeighbourhood: UserNeighbourhoodSimple;
}) {
  const un = props.userNeighbourhood;
  return (
    <Link
      className={styles.neighbourhood}
      key={un.user_neighbourhood_id}
      to={`/tabs/home/neighbourhood-details/${un.neighbourhood_id}`}
    >
      <div className={classNames(sharedStyles.heading4, styles.percentValue)}>
        {getPercent(un.percent_complete)} %
      </div>
      <div className={styles.hyphen}>-</div>
      <div className={classNames(sharedStyles.heading4, styles.name)}>
        {un.name}
      </div>
    </Link>
  );
}

export default React.memo(DashboardNeighbourhoods);
