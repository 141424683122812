import React from "react";
import AccountTierFunctions from "../../data/account/AccountTierFunctions";
import styles from "./PremiumMessage.module.css";
import sharedStyles from "../../styles/shared.module.css";
import { Button } from "@mui/material";
import classNames from "classnames";
import { useHistory, Link } from "react-router-dom";

export default function PremiumMessage({ text }: { text: string }) {
  const history = useHistory();
  return AccountTierFunctions.accountIsFree() &&
    AccountTierFunctions.showPremiumOptions() ? (
    <div className={styles.premiumNotification}>
      <div className={classNames(sharedStyles.heading3)}>{text}</div>
      <Button
        variant={"contained"}
        color={"primary"}
        style={{ color: "white" }}
        onClick={() => {
          history.push("/tabs/home/premium/upgrade");
        }}
      >
        Check out the benefits
        <br /> of a premium account
      </Button>
    </div>
  ) : null;
}
