import React, { Component } from "react";
import { connect } from "react-redux";
import styles from "./Auth.module.css";
import { Logo } from "../../components/logo/Logo";
import IntroLayout from "../../components/introlayout/IntroLayout";
import AuthBoxContent from "../../components/auth/AuthBoxContent";
import { AppBar } from "@mui/material";
import AppAvailableNotification from "../../components/appavailablenotification/AppAvailableNotification";
type DispatchProps = {};
type StateProps = {};
type OtherProps = {};
type PropsType = DispatchProps & StateProps & OtherProps;

type StateType = {};

class Auth extends Component<PropsType, StateType> {
  render() {
    return (
      <>
        <AppAvailableNotification />
        <IntroLayout>
          <div className={styles.container}>
            <AuthBoxContent />
          </div>
        </IntroLayout>
      </>
    );
  }
}

function mapStateToProps(state): StateProps {
  return {};
}
function mapDispatchToProps(dispatch): DispatchProps {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(Auth);
