import React from "react";
import { TimePeriods, UserCityType } from "cp-server";
import CPMap from "../../../../components/map/CPMap";
import styles from "../../Dashboard.module.css";
import { MapStyles } from "../../../../components/map/MapStyles";
import { heatmapSource } from "../../../../components/map/Sources";
import {
  useGetLandmarksWithVisitsInAreaQuery,
  useGetSettingsQuery,
} from "../../../../state/api/ApiSlice";

type PropsType = { userCity: UserCityType; period: string };
export default function Map({ userCity, period }: PropsType) {
  const { data: landmarks } = useGetLandmarksWithVisitsInAreaQuery({
    area_id: userCity.area_id,
  });
  const { data: settings } = useGetSettingsQuery();
  const showLandmarks =
    settings?.success && settings.settings.map_show_landmarks;
  {
    const city = userCity;
    if (
      landmarks?.success &&
      settings?.success &&
      (landmarks.landmarks.length === 0 ||
        landmarks.landmarks[0].area_id === city.area_id)
    ) {
      return (
        <CPMap
          mapStyle={MapStyles.Heatmap}
          unfilled_map
          /* We only want to show landmarks for the all time map. There aren't landmark stats for the monthly map*/
          landmarks={
            (period === TimePeriods.all && showLandmarks
              ? landmarks.landmarks
              : []) || []
          }
          area_id={city.area_id}
          boundingBox={city.bounding_box}
          className={styles.map}
          key={`${city.user_city_id}-${period}-landmarks-${showLandmarks}`}
          sources={heatmapSource(city.user_city_id, period)}
        />
      );
    } else {
      return <div className={styles.map} />;
    }
  }
}
