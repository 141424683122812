"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// @flow
/**
 * Created by matthewjamieson on 15/03/2017.
 */
const server = process.env.REACT_APP_SERVER === "local"
    ? "http://localhost:8080"
    : "https://citypainter.io";
const devConfig = {
    endPoint: `${server}/api`,
    domain: `${server}`,
};
const prodConfig = {
    endPoint: `${server}/api`,
    domain: `${server}`,
};
const adminConfig = {
    endPoint: `${server}/admin`,
    domain: `${server}`,
};
class Config {
    constructor() {
        this.env = "dev";
        this.setEnvironment("dev");
    }
    getConfigItem(key) {
        return this.config[key];
    }
    getEnvironment() {
        return this.env;
    }
    setEnvironment(env = "prod") {
        this.env = env;
        switch (env) {
            case "prod":
                this.config = prodConfig;
                break;
            case "dev":
                this.config = devConfig;
                break;
            case "admin":
                this.config = adminConfig;
                break;
            default:
                this.config = prodConfig;
                break;
        }
    }
}
exports.default = new Config();
