import unkinkPolygon from "@turf/unkink-polygon";
import area from "@turf/area";
import cleanCoords from "@turf/clean-coords";
import { showErrorPopup } from "../../../state/popups/PopupDispatcher";
import MapboxDraw from "@mapbox/mapbox-gl-draw";

/**
 * Fix any kinked polygons and return the largest resulting polygon
 * @param geojson
 */
function fixPolygonKinks(geojson) {
  const unkinked = unkinkPolygon(geojson);
  const polys = unkinked.features;
  polys.sort((a, b) => (area(a) < area(b) ? 1 : -1));
  const newData = {
    type: "FeatureCollection",
    features: [polys[0]],
  };
  return newData;
}

/**
 * Get first polygon, clean it, and return
 * @param data
 */
function getFirstCleanPolygon(data) {
  const firstPolygon = data.features[0];
  const cleanPoly = fixPolygonKinks({
    type: "FeatureCollection",
    features: [cleanCoords(firstPolygon)],
  });
  return cleanPoly;
}

/**
 * Cleans polygon
 * Remove excess polygons and unkink.
 * update map with the clean polygon
 *  return clean polygon data
 */
function cleanPolygonData(data: any, draw: MapboxDraw) {
  console.log(data);
  if (data.features.length > 0) {
    try {
      const cleanPoly = getFirstCleanPolygon(data);
      // Prevent more than one shape being drawn
      if (data.features.length > 1) {
        showErrorPopup("Sorry, an area can only have one shape");
      } else {
        return cleanPoly;
      }
      //@ts-ignore
      draw.set(cleanPoly);
    } catch (e) {
      console.log(e);
      return null;
    }
  }
  return null;
}

export default {
  fixPolygonKinks,
  getFirstCleanPolygon,
  cleanPolygonData,
};
