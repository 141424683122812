import React from "react";
import { Link } from "react-router-dom";
import { TimePeriods } from "cp-server";
import styles from "./DashboardLeaderboards.module.css";
import sharedStyles from "../../../../styles/shared.module.css";
import AccountTierFunctions from "../../../../data/account/AccountTierFunctions";
import ArrowIcon from "../../../../assets/arrow.svg";
import LockIcon from "../../../../assets/lock.svg";
import { useGetLeaderboardForUserQuery } from "../../../../state/api/ApiSlice";
import { getResultsFromResponse } from "../../../../data/ApiData";
import { withOrdinal } from "../../../../data/utils/NumberUtils";
import classNames from "classnames";

export default function DashboardLeaderboardLinks({
  type,
  id,
  groupId,
}: {
  id: string;
  type: "area" | "neighbourhood";
  groupId?: string;
}) {
  return (
    <>
      <Row
        id={id}
        type={type}
        groupId={groupId}
        period={TimePeriods.all}
        locked={false}
        label={"All Time"}
      />
      <div className={styles.divider} />
      <Row
        id={id}
        type={type}
        groupId={groupId}
        period={TimePeriods.year}
        locked={AccountTierFunctions.accountIsFree()}
        label={"This Year"}
      />
      <div className={styles.divider} />
      <Row
        id={id}
        type={type}
        groupId={groupId}
        period={TimePeriods.monthly}
        locked={AccountTierFunctions.accountIsFree()}
        label={"This Month"}
      />
    </>
  );
}

function Row({
  groupId,
  id,
  type,
  period,
  locked,
  label,
}: {
  label: string;
  groupId?: string;
  id: string;
  period: TimePeriods;
  type: "area" | "neighbourhood";
  locked: boolean;
}) {
  const {
    data: leaderboardResponse,
    isLoading: isLeaderboardLoading,
  } = useGetLeaderboardForUserQuery({ id, period, type, group_id: groupId });
  const total =
    (leaderboardResponse?.success && leaderboardResponse?.total) || 0;
  const results = getResultsFromResponse(leaderboardResponse);
  const userRow = results.find((lr) => lr.current_user);

  const url = `/tabs/home/leaderboard/${type}/${period}/${id}${
    groupId ? `?groupId=${groupId}` : ""
  }`;

  return (
    <Link
      to={locked ? "/tabs/home/premium/upgrade" : url}
      className={styles.leaderboardRow}
    >
      <div className={classNames(sharedStyles.text, styles.label)}>{label}</div>
      <div className={classNames(sharedStyles.text, styles.rankingAndArrow)}>
        <div>{userRow?.ranking ? withOrdinal(userRow?.ranking) : ""}</div>
        <img src={locked ? LockIcon : ArrowIcon} height={20} alt={"arrow"} />
      </div>
    </Link>
  );
}
