import React, { useCallback, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import CloseButton from "../components/CloseButton";
import styles from "./DeleteConfirmPopup.module.css";
import sharedStyles from "../../styles/shared.module.css";
import Button from "@mui/material/Button";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { deleteConfirmPopupSelector } from "../../state/popups/PopupsSelectors";
import { hidePopup, PopupTypes } from "../../state/popups/PopupsActions";
import LangManager from "../../data/languages/LangManager";
import LangKeys from "../../data/languages/LangKeys";
import {
  useDeleteActivityMutation,
  useDeleteCustomAreaMutation,
  useGroupRemoveMemberMutation,
} from "../../state/api/ApiSlice";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import activity from "../../routes/dashboard/components/activity/Activity";

export default function DeleteConfirmPopup() {
  const dispatch = useDispatch();
  const popup = useSelector(deleteConfirmPopupSelector);
  const area_id = popup.area_id || "";
  const group_id = popup.group_id || "";
  const activity_id = popup.activity_id || "";
  const type = popup.type;
  const history = useHistory();
  const { t } = useTranslation();

  const hide = useCallback(() => {
    dispatch(hidePopup({ popupType: PopupTypes.DELETE_CONFIRM }));
  }, []);

  /**
   *  Delete and  Remove endpoints
   */
  // Delete a group
  const [
    removeGroup,
    { data: removeGroupMemberResponse },
  ] = useGroupRemoveMemberMutation();

  // Delete a custom area
  const [loadDeleteCustomArea, {}] = useDeleteCustomAreaMutation();

  //Delete an activity
  const [
    loadDeleteActivity,
    activityDeleteResponse,
  ] = useDeleteActivityMutation();

  useEffect(() => {
    // When the activity is deleted, redirect to home
    activityDeleteResponse.data?.success && history.push("/tabs/home");
  }, [activityDeleteResponse.data]);

  // Delete call
  const deleteFunction = useCallback(() => {
    if (type === "AREA") {
      loadDeleteCustomArea({ area_id });
    }
    if (type === "GROUP") {
      removeGroup({ group_id });
    }
    if (type === "ACTIVITY") {
      loadDeleteActivity({ activity_id });
    }

    hide();
  }, [area_id, type]);
  const message = {
    GROUP: t("popups.deleteConfirm.group"),
    AREA: t("popups.deleteConfirm.area"),
    ACTIVITY: t("popups.deleteConfirm.activity"),
  };
  return (
    <Dialog open={popup.visible} onClose={hide}>
      <CloseButton hidePopup={hide} />
      <div className={styles.container}>
        <div
          className={classNames(sharedStyles.heading6, styles.message)}
        >{`${message[type]} ${popup.name}`}</div>
        <div className={styles.buttonContainer}>
          <Button
            variant="contained"
            color="primary"
            className={styles.okButton}
            onClick={hide}
          >
            {LangManager.getLang(LangKeys.CUSTOM_AREA_DELETE_CANCEL)}
          </Button>
          <Button className={styles.deleteButton} onClick={deleteFunction}>
            {LangManager.getLang(LangKeys.CUSTOM_AREA_DELETE_CONFIRM)}
          </Button>
        </div>
      </div>
    </Dialog>
  );
}
