import ImageKeys from "../../data/images/ImageKeys";
import ImageManager from "../../data/images/ImageManager";

export const mapImages = {
  peak: ImageManager.getImage(ImageKeys.LANDMARK_MAP_PEAK),
  monument: ImageManager.getImage(ImageKeys.LANDMARK_MAP_MONUMENT),
  viewpoint: ImageManager.getImage(ImageKeys.LANDMARK_MAP_VIEWPOINT),
  archaeological_site: ImageManager.getImage(ImageKeys.LANDMARK_MAP_MONUMENT),
  memorial: ImageManager.getImage(ImageKeys.LANDMARK_MAP_MONUMENT),
  artwork: ImageManager.getImage(ImageKeys.LANDMARK_MAP_ARTWORK),
  lighthouse: ImageManager.getImage(ImageKeys.LANDMARK_MAP_LIGHTHOUSE),
  castle: ImageManager.getImage(ImageKeys.LANDMARK_MAP_CASTLE),
  ruins: ImageManager.getImage(ImageKeys.LANDMARK_MAP_RUINS),
  dovecote: ImageManager.getImage(ImageKeys.LANDMARK_MAP_DOVECOTE),
  // Visited
  peak_visited: ImageManager.getImage(ImageKeys.LANDMARK_MAP_PEAK_VISITED),
  monument_visited: ImageManager.getImage(
    ImageKeys.LANDMARK_MAP_MONUMENT_VISITED
  ),
  viewpoint_visited: ImageManager.getImage(
    ImageKeys.LANDMARK_MAP_VIEWPOINT_VISITED
  ),
  archaeological_site_visited: ImageManager.getImage(
    ImageKeys.LANDMARK_MAP_MONUMENT_VISITED
  ),
  memorial_visited: ImageManager.getImage(
    ImageKeys.LANDMARK_MAP_MONUMENT_VISITED
  ),
  artwork_visited: ImageManager.getImage(
    ImageKeys.LANDMARK_MAP_ARTWORK_VISITED
  ),
  castle_visited: ImageManager.getImage(ImageKeys.LANDMARK_MAP_CASTLE_VISITED),
  lighthouse_visited: ImageManager.getImage(
    ImageKeys.LANDMARK_MAP_LIGHTHOUSE_VISITED
  ),
  ruins_visited: ImageManager.getImage(ImageKeys.LANDMARK_MAP_RUINS_VISITED),
  dovecote_visited: ImageManager.getImage(
    ImageKeys.LANDMARK_MAP_DOVECOTE_VISITED
  ),
};
