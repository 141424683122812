import { Config } from "framework";
import geobuf from "geobuf";
import mask from "@turf/mask";
import {
  areaBackground,
  areaOutline,
  backgroundHeatmap,
  heatmap,
  neighbourhoodMask,
  neighbourhoods,
  osmMaps,
  roadAndTrailLabels,
  roadsAndTrails,
} from "./Layers";
import { LayerSpecification } from "maplibre-gl";
var Pbf = require("pbf");

export type MapStyleParams = {
  unfilled?: boolean;
  simple?: boolean;
};

export async function getMainMapSources(area_id, customSources) {
  async function parseGeobuf(responce) {
    return geobuf.decode(new Pbf(await responce.arrayBuffer()));
  }
  const roadsUrl = `${Config.getConfigItem(
    "domain"
  )}/maps/${area_id}/roads.json`;
  const trailsUrl = `${Config.getConfigItem(
    "domain"
  )}/maps/${area_id}/trails.json`;
  const areaUrl = `${Config.getConfigItem("domain")}/maps/${area_id}/area.json`;
  const responses = await Promise.all([
    fetch(roadsUrl).then(parseGeobuf),
    fetch(trailsUrl).then(parseGeobuf),
    fetch(areaUrl).then(parseGeobuf),
  ]);
  return {
    osm: {
      type: "raster",
      tiles: ["https://tile.openstreetmap.org/{z}/{x}/{y}.png"],
      tileSize: 256,
    },
    ...(customSources || {}),
    roads: {
      type: "geojson",
      data: responses[0],
    },
    trails: {
      type: "geojson",
      data: responses[1],
    },
    area: {
      type: "geojson",
      data: responses[2],
    },
    area_mask: {
      type: "geojson",
      data: mask(responses[2]),
    },
  };
}

export function getNeighbourhoodsMapStyles(
  customLayers: LayerSpecification[],
  params: MapStyleParams
) {
  return [
    roadsAndTrails(params),
    roadAndTrailLabels(),
    neighbourhoods(),
  ].flat();
}

export function getNeighbourhoodDetailsStyles(
  customLayers: LayerSpecification[],
  params: MapStyleParams
) {
  return [getMainMapLayers(heatmap(), params), neighbourhoodMask()].flat();
}
export function getMainMapLayers(
  customLayers: LayerSpecification[] | LayerSpecification,
  params: MapStyleParams
): LayerSpecification[] {
  return [
    areaBackground(),
    osmMaps(params),
    roadsAndTrails(params),
    areaOutline(),
    customLayers || [],
    !params.simple ? roadAndTrailLabels() : [],
  ]
    .flat(2)
    .filter((i) => i);
}

export function getHeatmapLayers(customLayers, params: MapStyleParams) {
  return [getMainMapLayers([heatmap(), customLayers], params)].flat();
}

export function getLiveMapLayers(customLayers, params: MapStyleParams) {
  return [getMainMapLayers([backgroundHeatmap(), customLayers], params)].flat();
}

export enum MapStyles {
  NeighbourhoodDetails = "neighbourhood_details",
  Neighbourhoods = "neighbourhoods",
  Heatmap = "heatmap",
  LiveMap = "live_map",
  default = "default",
}
export const mapStyles: {
  [key in MapStyles]: (customLayers, params: MapStyleParams) => Array<Object>;
} = {
  default: getMainMapLayers,
  neighbourhood_details: getNeighbourhoodDetailsStyles,
  neighbourhoods: getNeighbourhoodsMapStyles,
  heatmap: getHeatmapLayers,
  live_map: getLiveMapLayers,
};
