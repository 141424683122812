import React from "react";
import styles from "./AccountPopup.module.css";
import { useDispatch, useSelector } from "react-redux";
import {
  hidePopup,
  PopupTypes,
  showPopup,
} from "../../state/popups/PopupsActions";
import { accountPopupSelector } from "../../state/popups/PopupsSelectors";
import { useHistory } from "react-router-dom";
import { PopupType } from "../../state/popups/PopupsTypes";
import { Dialog } from "@mui/material";
import LangManager from "../../data/languages/LangManager";
import LangKeys from "../../data/languages/LangKeys";
import classNames from "classnames";
import sharedStyles from "../../styles/shared.module.css";
import UserUtils from "../../data/utils/UserUtils";
type DispatchProps = {
  closePopup: () => void;
  logout: () => void;
  showFeedbackPopup: () => void;
};
type StateProps = {
  accountPopupState: PopupType;
};
type OtherProps = {};
type PropsType = StateProps & DispatchProps & OtherProps;

type StateType = {};

export default function AccountPopup() {
  const dispatch = useDispatch();
  const history = useHistory();
  const closePopup = () =>
    dispatch(hidePopup({ popupType: PopupTypes.ACCOUNT }));
  const logout = () => {
    closePopup();
    UserUtils.logout();
  };

  const accountPopupState = useSelector(accountPopupSelector);

  const showFeedback = () => {
    closePopup();
    dispatch(showPopup({ popupType: PopupTypes.FEEDBACK }));
  };
  const gotoSettings = () => {
    history.push("/tabs/settings");
    closePopup();
  };

  return (
    <Dialog open={accountPopupState.visible} onClose={closePopup} fullScreen>
      <div className={styles.position} onClick={() => closePopup()}>
        <div
          className={classNames(styles.container)}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div
            className={classNames(sharedStyles.heading4, styles.menuItem)}
            style={{ marginBottom: 24 }}
            onClick={gotoSettings}
          >
            {LangManager.getLang(LangKeys.ACCOUNT_SETTINGS)}
          </div>
          <div
            className={classNames(sharedStyles.heading4, styles.menuItem)}
            style={{ marginBottom: 24 }}
            onClick={showFeedback}
          >
            {LangManager.getLang(LangKeys.ACCOUNT_CONTACT)}
          </div>
          <div
            className={classNames(sharedStyles.heading4, styles.menuItem)}
            onClick={logout}
          >
            {LangManager.getLang(LangKeys.ACCOUNT_LOGOUT)}
          </div>
        </div>
      </div>
    </Dialog>
  );
}
