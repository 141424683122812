import React from "react";
import Stat from "../../../../components/stat/Stat";
import styles from "./DashboardPercentages.module.css";
import { UserCityType } from "cp-types";
import LangKeys from "../../../../data/languages/LangKeys";
import SmallHeading from "../../../../components/headings/SmallHeading";
import { getPercent } from "../../../../data/utils/PercentageUtils";
import AccountTierFunctions from "../../../../data/account/AccountTierFunctions";

export default function DashboardPercentages({
  userArea,
}: {
  userArea: UserCityType;
}) {
  return (
    <div>
      <SmallHeading
        left={`You've explored`}
        rightElement={
          <div className={styles.newForSubs}>New for Subscribers</div>
        }
      />
      <div className={styles.container}>
        <Stat
          value={`${getPercent(userArea.percent_covered)}%`}
          label={"Total"}
          valueColor={"#d9814a"}
        />
        <Stat
          value={`${getPercent(userArea.percent_covered_roads)}%`}
          label={"Roads"}
          valueColor={"#d9814a"}
          locked={AccountTierFunctions.accountIsFree()}
        />
        <Stat
          value={`${getPercent(userArea.percent_covered_trails)}%`}
          label={"Trails"}
          valueColor={"#d9814a"}
          locked={AccountTierFunctions.accountIsFree()}
        />
      </div>
    </div>
  );
}
