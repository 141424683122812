/**
 * Created by matthewjamieson on 14/01/2021.
 */

export default {
  LOGO: "logo",
  STRAVA: "strava_logo",
  ONBOARDING_BG: "onboarding_bg",
  LANDMARKS_VIEWPOINT: "viewpoint",
  LANDMARKS_STATUE: "status",
  LANDMARKS_MONUMENT: "monument",
  LANDMARKS_PEAK: "peak",
  LANDMARKS_ARTWORK: "artwork",
  LANDMARKS_CASTLE: "castle",
  LANDMARKS_RUINS: "ruins",
  LANDMARKS_DOVECOTE: "dovecote",
  LANDMARKS_LIGHTHOUSE: "lighthouse",
  LANDMARK_MAP_MONUMENT: "monument_map",
  LANDMARK_MAP_VIEWPOINT: "viewpoint_map",
  LANDMARK_MAP_PEAK: "peak_map",
  LANDMARK_MAP_ARTWORK: "artwork_map",
  LANDMARK_MAP_CASTLE: "castle_map",
  LANDMARK_MAP_LIGHTHOUSE: "lighthouse_map",
  LANDMARK_MAP_RUINS: "ruins_map",
  LANDMARK_MAP_DOVECOTE: "dovecote_map",
  LANDMARK_MAP_MONUMENT_VISITED: "monument_map_visited",
  LANDMARK_MAP_VIEWPOINT_VISITED: "viewpoint_map_visited",
  LANDMARK_MAP_PEAK_VISITED: "peak_map_visited",
  LANDMARK_MAP_ARTWORK_VISITED: "artwork_map_visited",
  LANDMARK_MAP_CASTLE_VISITED: "castle_map_visited",
  LANDMARK_MAP_LIGHTHOUSE_VISITED: "lighthouse_map_visited",
  LANDMARK_MAP_RUINS_VISITED: "ruins_map_visited",
  LANDMARK_MAP_DOVECOTE_VISITED: "dovecote_map_visited",
  SETTINGS_ICON: "settings",
  TICK_ICON: "tick_icon",
  FULL_SCREEN_ICON: "full_screen",
  FULL_SCREEN_EXIT_ICON: "full_screen_exit",
  PLUS_ICON: "plus_icon",
  NEIGHBOURHOOD_ICON: "neighbourhood",
};
