import React from "react";
import styles from "./UserCity.module.css";
import sharedStyles from "../../../styles/shared.module.css";
import { UserCityType } from "cp-server";
import classNames from "classnames";
import Stat from "../../../components/stat/Stat";
import LangManager from "../../../data/languages/LangManager";
import LangKeys from "../../../data/languages/LangKeys";
import { getDistance } from "../../../data/utils/formating/DistanceFormating";
import { Config } from "framework";
import { useHistory } from "react-router-dom";
import { FacebookIcon, FacebookShareButton } from "react-share";
import LockIcon from "../../../assets/lock.svg";
import { getPercent } from "../../../data/utils/PercentageUtils";
import DisplayIf from "../../../components/display-if/DisplayIf";
type PropsType = { city: UserCityType; locked: boolean };

function UserCity(props: PropsType) {
  const history = useHistory();
  const gotoCity = () => {
    if (!props.locked) {
      history.push(`/tabs/home/${props.city.user_city_id}`);
    } else {
      history.push(`/tabs/home/premium/upgrade`);
    }
  };

  const city = props.city;
  const custom = city.type === "custom";
  const imageUrl = `${Config.getConfigItem("domain")}/heatmap_thumbs/${
    city.user_city_id
  }.png`;
  return (
    <div className={styles.container} onClick={gotoCity}>
      <div className={styles.inner}>
        {/*<FacebookShareButton url={imageUrl} >*/}
        {/*  <FacebookIcon size={32} round={false} crossOrigin={undefined} path={undefined}/>*/}
        {/*</FacebookShareButton>*/}
        <div
          className={styles.thumbnail}
          style={{
            backgroundImage: `url(${imageUrl})`,
          }}
        >
          {custom ? (
            <div className={classNames(styles.custom, sharedStyles.heading5)}>
              {LangManager.getLang(LangKeys.CITIES_CUSTOM)}
            </div>
          ) : null}
          <DisplayIf condition={props.locked}>
            <div className={styles.lock}>
              <img src={LockIcon} width={32} height={32} />
            </div>
          </DisplayIf>
        </div>
        <div className={classNames(sharedStyles.heading2, styles.cityName)}>
          {city.name}
        </div>
        <div className={styles.statsContainer}>
          <Stat
            label={LangManager.getLang(LangKeys.CITIES_LANDMARKS_VISITED)}
            valueColor="var(--orange-2)"
            value={`${city.landmarks_visited || 0}/${city.total_landmarks}`}
            complete={
              city.landmarks_visited === city.total_landmarks &&
              city.total_landmarks !== "0"
            }
          />
          <Stat
            label={LangManager.getLang(LangKeys.CITIES_PERCENT)}
            valueColor="var(--orange-2)"
            value={`${getPercent(city.percent_covered)}%`}
            complete={parseInt(getPercent(city.percent_covered)) === 100}
          />{" "}
          <Stat
            label={LangManager.getLang(LangKeys.CITIES_NEIGHBOURHOODS)}
            valueColor="var(--orange-2)"
            value={
              parseInt(city.total_neighbourhoods)
                ? `${city.neighbourhoods_visited}/${city.total_neighbourhoods}`
                : " -- "
            }
            complete={
              city.neighbourhoods_visited === city.total_neighbourhoods &&
              city.total_neighbourhoods !== "0"
            }
          />
          <Stat
            label={LangManager.getLang(LangKeys.CITIES_ACHIEVEMENTS)}
            value={`${city.achievements_complete}`}
          />
          <Stat
            label={LangManager.getLang(LangKeys.CITIES_ACTIVITIES)}
            value={`${city.activities}`}
          />
          <Stat
            label={LangManager.getLang(LangKeys.CITIES_DISTANCE)}
            value={`${getDistance(city.distance_covered, 1)}`}
          />
        </div>
      </div>
    </div>
  );
}

export default React.memo(UserCity);
