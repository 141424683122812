import React, { PureComponent } from "react";
import styles from "./CitySuggest.module.css";
import { connect } from "react-redux";
import LangManager from "../../../data/languages/LangManager";
import LangKeys from "../../../data/languages/LangKeys";
import { ReduxStateType } from "../../../state/ReduxStateType";
import classNames from "classnames";
import sharedStyles from "../../../styles/shared.module.css";
import { PopupTypes, showPopup } from "../../../state/popups/PopupsActions";
import { FeedbackCategory } from "../../../popups/feedback/FeedbackPopup";

type DispatchProps = {
  showFeedbackPopup: () => void;
};
type StateProps = {};
type OtherProps = {};
type PropsType = StateProps & DispatchProps & OtherProps;

type StateType = {};

class CitySuggest extends PureComponent<PropsType, StateType> {
  render() {
    return (
      <div className={classNames(sharedStyles.heading5, styles.suggestMessage)}>
        {LangManager.getLang(LangKeys.CITY_SUGGESTION_MESSAGE)}{" "}
        <span
          className={classNames(sharedStyles.heading5, styles.suggest)}
          onClick={() => this.props.showFeedbackPopup()}
        >
          {LangManager.getLang(LangKeys.CITY_SUGGESTION)}
        </span>
      </div>
    );
  }
}

function mapStateToProps(state: ReduxStateType): StateProps {
  return {};
}

function mapDispatchToProps(dispatch): DispatchProps {
  return {
    showFeedbackPopup: () =>
      dispatch(
        showPopup({
          popupType: PopupTypes.FEEDBACK,
          data: { category: FeedbackCategory.city_request },
        })
      ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CitySuggest);
