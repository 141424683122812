import { ReduxStateType } from "../../state/ReduxStateType";
import { getState, getStore } from "../../state/Store";
import { isAndroid, isApp, isIos } from "../../platform/PlatformManager";
import { apiSlice, DOMAIN, TagTypes } from "../../state/api/ApiSlice";
import { accessTokenSelector } from "../../state/userslice/UserSlice";
import "cordova-plugin-purchase";
import { LOG_LEVEL, Purchases } from "@revenuecat/purchases-capacitor";
import { User } from "framework";
import { AccountTypes } from "cp-server";

/**
 * Registers in app products and listens for events
 */
let _store: CdvPurchase.Store; // a bit hacky..

async function setupInAppPayments(userId?: string) {
  if (isAndroid() && isApp()) {
    // await Purchases.setLogLevel({ level: LOG_LEVEL.DEBUG });
    // This is the Rev Cat setup
    await Purchases.configure({
      apiKey: "goog_xCHLJIDUoahQRPpXBVehIwCJCYR",
      appUserID: userId,
    });
    await Purchases.addCustomerInfoUpdateListener((customerInfo) => {
      const activeSubscriptions = customerInfo.activeSubscriptions;
      activeSubscriptions.length > 0 &&
        User.setAccountType(AccountTypes.premium);
    });
  } else if (isApp() && !_store) {
    console.log("SETUP IN APP PAYMENTS");
    const { store, ProductType, Platform } = CdvPurchase;
    console.log({ store, ProductType, Platform });
    _store = store;
    if (isIos()) {
      store.register({
        type: ProductType.PAID_SUBSCRIPTION,
        id: "premium_monthly",
        platform: Platform.APPLE_APPSTORE,
      });
      store.register({
        type: ProductType.PAID_SUBSCRIPTION,
        id: "premium_3monthly",
        platform: Platform.APPLE_APPSTORE,
      });
      store.register({
        type: ProductType.PAID_SUBSCRIPTION,
        id: "premium_yearly",
        platform: Platform.APPLE_APPSTORE,
      });
    }

    if (isAndroid()) {
      // This is for if we go back to using native google billing
      // store.register({
      //   type: ProductType.PAID_SUBSCRIPTION,
      //   id: "premium_monthly_android",
      //   platform: Platform.GOOGLE_PLAY,
      // });
      // store.register({
      //   type: ProductType.PAID_SUBSCRIPTION,
      //   id: "premium_3monthly_android",
      //   platform: Platform.GOOGLE_PLAY,
      // });
      // store.register({
      //   type: ProductType.PAID_SUBSCRIPTION,
      //   id: "premium_yearly_android",
      //   platform: Platform.GOOGLE_PLAY,
      // });
    }

    store
      .when()
      .productUpdated((product) => {})
      .approved((value) => {
        console.log("approved", value);
        return value.verify();
      })
      .verified((value) => {
        console.log("verified", value);
        return value.finish();
      })
      .finished((value) => {
        console.log("finished", value);
      });

    store.validator = async function (product, callback) {
      const token = accessTokenSelector(getState() as ReduxStateType);
      const response = await fetch(`${DOMAIN}/api/ios_validate_receipt`, {
        method: "post",
        body: JSON.stringify(product),
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`,
        },
      });
      const json = await response.json();
      if (json.ok) {
        // refresh (invalidate the current details) user details so that the details reflect the new upgraded subscription
        getStore().dispatch(
          apiSlice.util.invalidateTags([TagTypes.user_details])
        );
      }
      callback(json.ok);
    };
    isIos() && isApp() && store.initialize([Platform.APPLE_APPSTORE]);
    isAndroid() && isApp() && store.initialize([Platform.GOOGLE_PLAY]);
  } else {
    console.log("Skipping in app payments setup.. Not IOS and/or APP");
  }
}
function getCVDStore() {
  return _store;
}

export default { setupInAppPayments, getCVDStore };
