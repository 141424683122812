import React, { Component, Props } from "react";
import styles from "./Landmark.module.css";
import ImageKeys from "../../../data/images/ImageKeys";
import { LandmarkType } from "cp-server";
import ImageManager from "../../../data/images/ImageManager";
import { RouteComponentProps, useHistory, withRouter } from "react-router-dom";
import { Link } from "react-router-dom";

type PropsType = { landmark: LandmarkType };
const getLandmarkIcon = (landmark: LandmarkType) => {
  switch (landmark.type) {
    case "peak":
      return ImageManager.getImage(ImageKeys.LANDMARKS_PEAK);
    case "viewpoint":
      return ImageManager.getImage(ImageKeys.LANDMARKS_VIEWPOINT);
    case "artwork":
      return ImageManager.getImage(ImageKeys.LANDMARKS_ARTWORK);
    case "archaeological_site":
    case "memorial":
    case "monument":
      return ImageManager.getImage(ImageKeys.LANDMARKS_MONUMENT);
    case "castle":
      return ImageManager.getImage(ImageKeys.LANDMARKS_CASTLE);
    case "lighthouse":
      return ImageManager.getImage(ImageKeys.LANDMARKS_LIGHTHOUSE);
    case "ruins":
      return ImageManager.getImage(ImageKeys.LANDMARKS_RUINS);
    case "dovecote":
      return ImageManager.getImage(ImageKeys.LANDMARKS_DOVECOTE);
    default:
      console.log(landmark.type);
  }
};
function Landmark(props: PropsType) {
  const history = useHistory();
  function goToLandmark(e: React.MouseEvent) {
    e.stopPropagation();
    history.push(`/tabs/home/landmark/${props.landmark.landmark_id}`);
  }

  const landmark = props.landmark;
  const icon = getLandmarkIcon(landmark);
  return (
    <div className={styles.landmark} onClick={goToLandmark}>
      <div
        className={styles.landmark_icon}
        style={{ backgroundImage: `url(${icon})` }}
      />
      <div className={styles.landmarkName}>{landmark.name}</div>
    </div>
  );
}

export default React.memo(Landmark);
