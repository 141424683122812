import React from "react";
import styles from "./FreeTrial.module.css";
import Logo from "../../../../assets/logo.svg";
import Content from "../../../../components/content/Content";
import sharedStyles from "../../../../styles/shared.module.css";
import classNames from "classnames";
import DisplayIf from "../../../../components/display-if/DisplayIf";
import AccountTierFunctions from "../../../../data/account/AccountTierFunctions";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useAddEventMutation } from "../../../../state/api/ApiSlice";
export default function FreeTrial() {
  const { t } = useTranslation();
  const [trackEvent] = useAddEventMutation();
  return (
    <DisplayIf condition={AccountTierFunctions.accountIsFree()}>
      <Content>
        <Link
          to={"/tabs/home/premium/upgrade"}
          onClick={() => {
            trackEvent("free_trial_click");
          }}
        >
          <div className={styles.container}>
            <img src={Logo} width={48} height={48} alt={"Logo"} />
            <div className={classNames(sharedStyles.text, styles.message)}>
              <span
                className={classNames(styles.highlightText, sharedStyles.text)}
              >
                {t("dashboard.freeTrial.message")}
              </span>
              <br />
              <span className={classNames(sharedStyles.text)}>
                {t("dashboard.freeTrial.message2")}
              </span>
            </div>
          </div>
        </Link>
      </Content>
    </DisplayIf>
  );
}
