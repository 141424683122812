import { ApiRequestBodies, ApiResponses, ApiRoutes } from "cp-server";
import { EndpointBuilder } from "@reduxjs/toolkit/dist/query/endpointDefinitions";
import UserUtils from "../../../../data/utils/UserUtils";
import { getStore } from "../../../Store";
import { hidePopup, PopupTypes } from "../../../popups/PopupsActions";

const Endpoint = (builder: EndpointBuilder<any, any, any>) =>
  builder.mutation<
    ApiResponses[ApiRoutes.DeleteAccount],
    ApiRequestBodies[ApiRoutes.DeleteAccount]
  >({
    query: (data) => ({
      url: ApiRoutes.DeleteAccount,
      method: "post",
      body: data,
    }),
    async onQueryStarted(arg, { dispatch, queryFulfilled }) {
      try {
        const { data } = await queryFulfilled;
        getStore().dispatch(
          hidePopup({ popupType: PopupTypes.DELETE_ACCOUNT_CONFIRM })
        );
        UserUtils.logout();
      } catch (err) {
        console.log(err);
      }
    },
  });

export default Endpoint;
