import React, { useEffect, useState } from "react";
import Activity from "../Activity";
import styles from "./ActivityList.module.css";
import CircularProgress from "@mui/material/CircularProgress";

import eventEmitter from "../../../../../data/EventEmitter";
import { getResultsFromResponse } from "../../../../../data/ApiData";
import {
  useGetActivitiesQuery,
  useLazyGetActivityPercentCoveredQuery,
} from "../../../../../state/api/ApiSlice";

type ActivityFilter = {
  area_id?: string;
  neighbourhood_id?: string;
  landmark_id?: string;
};
type OtherProps = {
  key?: string;
  filter: ActivityFilter;
};

function ActivitiesList(props: OtherProps) {
  const [pages, setPages] = useState(1);

  /**
   * This does not load data it just accesses the data and loading state of the most recently loaded page
   * The initial load call is in the ActivitiesPage component
   * The params need to be exactly the same (same order as well) to match the query cache key
   * This data is just used to determine whether another page is allowed to be added (the goal being to only allow 1 page to load at a time)
   * IT IS NOT USED TO ACTUALLY DISPLAY THE ACTIVITIES
   */
  const { data: activitiesResponse, isFetching } = useGetActivitiesQuery({
    page: `${pages}`,
    ...props.filter,
    page_size: "6",
  });

  const addPage = () => {
    if (activitiesResponse?.success) {
      const totalActivities = parseInt(activitiesResponse.total || "");
      const lastPage = Math.ceil(totalActivities / 6);

      if (!isFetching && pages !== lastPage) {
        setPages(pages + 1);
      }
    }
  };

  useEffect(() => {
    eventEmitter.on("scroll_bottom", addPage);
    return () => {
      eventEmitter.off("scroll_bottom", addPage);
    };
  }, [pages, isFetching]);

  return (
    <div className={styles.container}>
      <div className={styles.activityList}>
        {[...new Array(pages)].map((item, index) => {
          return (
            <ActivitiesPage
              page={`${index + 1}`}
              filter={props.filter}
              key={index}
            />
          );
        })}
      </div>
    </div>
  );
}

const ActivitiesPage = React.memo(function (props: {
  filter: ActivityFilter;
  page: string;
}) {
  /**
   * Component loads its own page of data
   */
  const { data: activitiesResponse, isLoading } = useGetActivitiesQuery({
    page: `${props.page}`,
    ...props.filter,
    page_size: "6",
  });
  const activities = getResultsFromResponse(activitiesResponse);

  /**
   * Some activities dont have their 'percent new' values calculated by default because it is resource intensive
   * Request percentages for any activity where the value is missing
   */
  const [
    loadPercentsCovered,
    { data: percentResponse },
  ] = useLazyGetActivityPercentCoveredQuery();
  useEffect(() => {
    const activitiesWithoutPercent = activities
      .filter((a) => !a.percent_new)
      .map((a) => a.id);
    if (activitiesWithoutPercent.length) {
      loadPercentsCovered({ activities: activitiesWithoutPercent });
    }
  }, [activities]);
  const percents = percentResponse?.success ? percentResponse : {};
  if (activities) {
    return (
      <>
        {activities.map((item) => (
          <Activity data={item} key={item.id} percent={percents[item.id]} />
        ))}
        {isLoading ? (
          <div className={styles.activityIndicatorContainer}>
            {isLoading ? <CircularProgress /> : null}
          </div>
        ) : undefined}
      </>
    );
  }
  return null;
});

export default React.memo(ActivitiesList);
