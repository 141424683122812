// Import needed to set up language manager. Dont remove!!
import Language from "./data/languages/Language";
import { createRoot } from "react-dom/client";
import React, { StrictMode } from "react";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { User } from "framework";
import { getState, getStore, initStore } from "./state/Store";
import InAppPayments from "./data/iap/InAppPayments";
import { apiSlice } from "./state/api/ApiSlice";
import { accessTokenSelector, setToken } from "./state/userslice/UserSlice";

/**
 * Dev token allows an admin/developer to specific a user auth token in the query string and view the
 * site as that user
 */
const params = new URLSearchParams(window.location.search);
const dev_token = params.get("dt");

// DO NOT REMOVE. The language manager does not get setup if the import is unused
// Could be the compiler trying to be clever and remove unused imports
const l = Language;

initStore(() => {
  // Normal user session persist
  const accessToken = dev_token || accessTokenSelector(getState());
  if (accessToken) {
    getStore().dispatch(setToken(accessToken));
    User.setToken(accessToken);
    if (!dev_token) {
      //@ts-ignore
      const { initiate } = apiSlice.endpoints.addEvent;
      getStore().dispatch(
        // @ts-ignore todo find correct typing
        initiate("visit")
      );
    }
  }

  // Email Tracking.. Track whether the user was sent to the site from an email and report it to the server
  if (params.get("eid")) {
    const email_id = params.get("eid");
    const { initiate } = apiSlice.endpoints.emailAction;

    // @ts-ignore
    getStore().dispatch(initiate(email_id));
  }
  const container = document.getElementById("root");
  // Just to pass typescript strict checks
  const root = container && createRoot(container);
  root?.render(
    // <StrictMode>
    <App />
    // </StrictMode>
  );
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
