import React from "react";
import styles from "./PremiumComparisonGrid.module.css";
import classNames from "classnames";
import sharedStyles from "../../../../styles/shared.module.css";
import SmallHeading from "../../../../components/headings/SmallHeading";

type ComparisonRowType = {
  label: string;
  premium: boolean | string;
  free: boolean | string;
};
const data: Array<ComparisonRowType> = [
  { label: "All activities", free: true, premium: true },
  { label: "Heatmaps", free: true, premium: true },
  { label: "All Time Leaderboards", free: true, premium: true },
  { label: "Achievements", free: true, premium: true },
  { label: "Cities and areas", free: "Top 3", premium: "All" },
  { label: "Neighbourhoods", free: "Top 6", premium: "All" },
  { label: "Landmarks", free: "Top 10", premium: "All" },
  { label: "Custom Areas", free: "1", premium: "10" },
  { label: "National Parks", free: false, premium: true },
  { label: "Monthly leaderboards", free: false, premium: true },
  { label: "Yearly leaderboards", free: false, premium: true },
  { label: "Neighbourhoods in your custom areas", free: false, premium: true },
];

export default function PremiumComparisonGrid() {
  return (
    <>
      <SmallHeading left={"Comparison"} />
      <div className={styles.grid}>
        <div></div>
        <div
          className={classNames(
            sharedStyles.heading4,
            styles.cell,
            styles.freeText
          )}
        >
          Free
        </div>
        <div
          className={classNames(
            sharedStyles.heading4,
            styles.cellPremium,
            styles.premiumText
          )}
        >
          Premium
        </div>
        {data.map((row) => {
          return (
            <>
              <div className={styles.cellLabel}>{row.label}</div>
              <div className={styles.cell}>
                {typeof row.free === "boolean" ? (
                  row.free ? (
                    <img
                      width={20}
                      src={require("../../../../assets/tick.png")}
                    />
                  ) : null
                ) : (
                  row.free
                )}
              </div>
              <div className={styles.cellPremium}>
                {typeof row.premium === "boolean" ? (
                  row.premium ? (
                    <img
                      width={20}
                      src={require("../../../../assets/tick.png")}
                    />
                  ) : null
                ) : (
                  row.premium
                )}
              </div>
            </>
          );
        })}
      </div>
    </>
  );
}
