import React from "react";
import styles from "./AboutUs.module.css";
import Header from "../components/Header";
import sharedStyles from "../../styles/shared.module.css";

import Content from "../../components/content/Content";
import classNames from "classnames";
import Footer from "../landing/components/Footer";
import { Helmet } from "react-helmet";
import { IonContent } from "@ionic/react";

function AboutUs() {
  return (
    <>
      <Helmet>
        <title>City Painter - About Us</title>
      </Helmet>
      <Header showAuth logoOnly title={"About us"} />
      <IonContent>
        <Content>
          <div className={classNames(sharedStyles.heading1, styles.header)}>
            About Us
          </div>
          <div
            className={classNames(sharedStyles.heading4, styles.contentHeader)}
          >
            Who are we?
          </div>
          <div
            className={classNames(sharedStyles.paragraph, styles.contentText)}
          >
            City Painter is currently a very small company based in Edinburgh,
            Scotland
          </div>
          <div
            className={classNames(sharedStyles.heading4, styles.contentHeader)}
          >
            How did it start?
          </div>
          <div
            className={classNames(sharedStyles.paragraph, styles.contentText)}
          >
            The initial idea came about during the first covid lockdown in March
            2020. I wanted to to know how much of Edinburgh I have seen while
            out running and cycling. The initial plan was simple. I just wanted
            to somehow generate a percent value of the number of streets and
            trails that I had run through in the city with an accompanying map.
          </div>

          <div
            className={classNames(sharedStyles.heading4, styles.contentHeader)}
          >
            And then?
          </div>
          <div
            className={classNames(sharedStyles.paragraph, styles.contentText)}
          >
            This simple concept grew into wanting to see how much new ground I
            covered in each activity. After a number of runs where I set about
            exploring all the streets in my local area it became clear that this
            wasn't a particularly exciting way of exploring the city. I wanted
            to see interesting things! To accomplish this I added the concept of
            landmarks to the app. Landmarks included things like public artwork,
            statues, ruins, interesting buildings and viewpoints. We developed
            the landmarks feature in such a way that users can see which
            landmarks they have and haven't visited, the number of visits and
            during which activities they visited the landmark. This feature also
            allowed users to see which landmarks they visited during each
            activity.
          </div>
          <div
            className={classNames(sharedStyles.heading4, styles.contentHeader)}
          >
            What next?
          </div>
          <div
            className={classNames(sharedStyles.paragraph, styles.contentText)}
          >
            For our initial launch we are only including a few cities and areas
            in scotland. Assuming this is somewhat successful we plan on
            expanding to other areas of the United Kingdom and hopefully Europe
          </div>
        </Content>
      </IonContent>

      <Footer />
    </>
  );
}

export default React.memo(AboutUs);
