import React from "react";
import Header from "../components/Header";
import { Helmet } from "react-helmet";
import { IonContent } from "@ionic/react";
import { useTranslation } from "react-i18next";
import styles from "./Support.module.css";
import Content from "../../components/content/Content";
import LangManager from "../../data/languages/LangManager";
import LangKeys from "../../data/languages/LangKeys";
import { Link, useHistory } from "react-router-dom";
import { PopupTypes, showPopup } from "../../state/popups/PopupsActions";
import { useDispatch } from "react-redux";

export default function Support() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();

  const showFeedbackPopup = () =>
    dispatch(showPopup({ popupType: PopupTypes.FEEDBACK }));

  return (
    <>
      <Helmet>
        <title>City Painter - Support</title>
      </Helmet>
      <Header title={t("support.header")} />
      <IonContent>
        <Content>
          <div className={styles.linkList}>
            <Link className={styles.link} to="/about_us">
              {LangManager.getLang(LangKeys.FOOTER_ABOUT_US)}
            </Link>

            <div className={styles.link} onClick={() => showFeedbackPopup()}>
              {LangManager.getLang(LangKeys.FOOTER_FEEDBACK)}
            </div>
            <a href={"mailto:matthew@citypainter.io"} className={styles.link}>
              {LangManager.getLang(LangKeys.FOOTER_CONTACT_US)}
            </a>

            <Link className={styles.link} to="/t_and_c">
              {LangManager.getLang(LangKeys.FOOTER_TERMS_AND_CONDITIONS)}
            </Link>
            <Link className={styles.link} to="/privacy_policy">
              {LangManager.getLang(LangKeys.FOOTER_PRIVACY_POLICY)}
            </Link>
          </div>
        </Content>
      </IonContent>
    </>
  );
}
