import React from "react";
import { UserCityType } from "cp-server";

import styles from "./DemoNeighbourhoods.module.css";

import { Config } from "framework";
import classNames from "classnames";
import sharedStyles from "../../../styles/shared.module.css";
import SmallHeading from "../../../components/headings/SmallHeading";
import LangManager from "../../../data/languages/LangManager";
import LangKeys from "../../../data/languages/LangKeys";

const neighbourhoods = [
  {
    name: "Leith",
    percent_complete: "98.4%",
  },
  {
    name: "New Town",
    percent_complete: "95.4%",
  },
  {
    name: "Leith Docks",
    percent_complete: "91.0%",
  },
  {
    name: "Stockbridge",
    percent_complete: "85%",
  },
  {
    name: "Old Town",
    percent_complete: "81%",
  },
  {
    name: "Western Harbour",
    percent_complete: "80.4%",
  },
];
export function DemoDashboardNeighbourhoods() {
  return (
    <div style={{ marginTop: 12 }}>
      <div className={styles.container}>
        <div
          className={styles.thumbnail}
          style={{
            backgroundImage: `url(${require("../../../assets/landing/neighbourhood_thumb.png")})`,
          }}
        />
        <div>
          <div
            className={classNames(
              sharedStyles.heading3,
              styles.topNeighbourhoodsHeader
            )}
          >
            {LangManager.getLang(LangKeys.DASHBOARD_NEIGHBOURHOODS_TOP)}
          </div>
          <div className={styles.topNeighbourhoods}>
            {neighbourhoods.map((un) => {
              return <TopNeighbourhood userNeighbourhood={un} />;
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

function TopNeighbourhood(props: {
  userNeighbourhood: { name: string; percent_complete: string };
}) {
  const un = props.userNeighbourhood;
  return (
    <div className={styles.neighbourhood}>
      <div className={classNames(sharedStyles.heading4, styles.percentValue)}>
        {un.percent_complete}
      </div>
      <div className={styles.hyphen}>-</div>
      <div className={classNames(sharedStyles.heading4, styles.name)}>
        {un.name}
      </div>
    </div>
  );
}
