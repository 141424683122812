import React, { useEffect } from "react";
import styles from "./Achievements.module.css";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import Header from "../components/Header";
import { sortAchievements } from "../../data/utils/formating/AchievementUtils";
import Achievement from "../dashboard/components/achievements/Achievement";
import LangManager from "../../data/languages/LangManager";
import LangKeys from "../../data/languages/LangKeys";
import Content from "../../components/content/Content";
import { useGetAchievementsForUserByAreaQuery } from "../../state/api/ApiSlice";
import { IonContent } from "@ionic/react";

function Achievements() {
  const { area_id } = useParams<{ area_id: string }>();
  const { data: achievements } = useGetAchievementsForUserByAreaQuery({
    area_id,
  });
  function renderContent() {
    if (achievements?.success) {
      const areaName = achievements.area.name;
      const sortedAchievements = sortAchievements(achievements.achievements);
      return (
        <div>
          <Content>
            <div className={styles.achievementsList}>
              {sortedAchievements.map((a) => (
                <Achievement data={a} key={a.achievement_id} />
              ))}
            </div>
          </Content>
        </div>
      );
    }
  }

  function getHeaderTitle() {
    if (achievements?.success) {
      return `${LangManager.getLang(LangKeys.ACHIEVEMENTS_HEADER)} ${
        achievements.area.name
      }`;
    }
    return "My Achievements";
  }
  return (
    <>
      <Header title={getHeaderTitle()} />
      <IonContent>{renderContent()}</IonContent>
    </>
  );
}

export default React.memo(Achievements);
