/**
 * Created by matthewjamieson on 24/02/2020.
 */

export enum PopupTypes {
  ACCOUNT = "account",
  ERROR = "error",
  FEEDBACK = "feedback",
  DELETE_CONFIRM = "deleteConfirm",
  VENDOR_PERMISSIONS_ERROR = "vendorPermissionsError",
  DELETE_ACCOUNT_CONFIRM = "deleteAccountConfirm",
  NAME = "namePopup",
  ACTIVITY_DESCRIPTION_PERMISSIONS = "activityDescriptionPermissions",
}

export const SHOW_POPUP = "SHOW_POPUP";
export const HIDE_POPUP = "HIDE_POPUP";
export const showPopup = ({
  popupType,
  data,
}: {
  popupType: PopupTypes;
  data?: object;
}) => {
  return {
    type: SHOW_POPUP,
    popupType,
    data,
  };
};

export const hidePopup = ({ popupType }: { popupType: PopupTypes }) => {
  return {
    type: HIDE_POPUP,
    popupType,
  };
};
