import React, { useCallback } from "react";
import CloseButton from "../components/CloseButton";
import { Dialog } from "@mui/material";
import LangManager from "../../data/languages/LangManager";
import LangKeys from "../../data/languages/LangKeys";
import { useDispatch, useSelector } from "react-redux";
import { vendorPermissionsErrorPopupSelector } from "../../state/popups/PopupsSelectors";
import { hidePopup, PopupTypes } from "../../state/popups/PopupsActions";
import { useHistory } from "react-router-dom";
import Button from "@mui/material/Button";
import styles from "./VendorPermissionsErrorPopup.module.css";

export default function VendorPermissionsErrorPopup() {
  const dispatch = useDispatch();
  const popupState = useSelector(vendorPermissionsErrorPopupSelector);
  const history = useHistory();
  const close = useCallback(() => {
    history.goBack();
    dispatch(hidePopup({ popupType: PopupTypes.VENDOR_PERMISSIONS_ERROR }));
  }, []);

  return (
    <Dialog open={popupState.visible} onClose={close}>
      <CloseButton hidePopup={close} />
      <div className={styles.container}>
        <div className={styles.message}>
          {LangManager.getLang(LangKeys.VENDOR_PERMISSIONS_ERROR_MESSAGE)}
        </div>
        <Button
          variant="contained"
          color="primary"
          className={styles.grantButton}
          onClick={close}
        >
          {LangManager.getLang(LangKeys.VENDOR_PERMISSION_GRANT_BUTTON)}
        </Button>
      </div>
    </Dialog>
  );
}
