//Library Imports
import React from "react";
import styles from "./Terms.module.css";
import sharedStyles from "../../styles/shared.module.css";
import classNames from "classnames";
import Content from "../../components/content/Content";
import Header from "../components/Header";
import { Helmet } from "react-helmet";
import { IonContent } from "@ionic/react";

const Terms: React.FunctionComponent = () => {
  return (
    <>
      <Helmet>
        <title>City Painter - Terms and conditions</title>
      </Helmet>
      <Header showAuth logoOnly title={"Terms and conditions"} />
      <IonContent>
        <div className={styles.container}>
          <Content>
            <div className={classNames(sharedStyles.heading1, styles.header)}>
              Terms and conditions
            </div>
            <div
              className={classNames(sharedStyles.paragraph, styles.contentText)}
            >
              Welcome to our website. If you continue to browse and use this
              website, you are agreeing to comply with and be bound by the
              following terms and conditions of use, which together with our
              privacy policy govern CityPainter's relationship with you in
              relation to this website. If you disagree with any part of these
              terms and conditions, please do not use our website. The term
              'CityPainter' or 'us' or 'we' refers to the owner of the website.
              The term 'you' refers to the user or viewer of our website.
            </div>
            <div
              className={classNames(
                sharedStyles.heading4,
                styles.contentHeader
              )}
            >
              Subscriptions
            </div>
            <div
              className={classNames(sharedStyles.paragraph, styles.contentText)}
            >
              When subscribing to CityPainter premium you consent to your
              preferred payment method being charged at the beginning of each
              subscription period. This is either monthly, every 3 months or
              yearly depending on the subscription you have chosen.
            </div>
            <br />
            <div
              className={classNames(sharedStyles.paragraph, styles.contentText)}
            >
              CityPainter DOES NOT have access to your payment details. All
              payment details are handled by stripe.com This makes us fully
              PCI-compliant
            </div>
            <br />
            <div
              className={classNames(sharedStyles.paragraph, styles.contentText)}
            >
              You may cancel your subscription at any time. After canceling
              there will be no further payments due. Your premium subscription
              will still be valid until the end of the current subscription
              period. e.g. If you cancel your monthly subscription a week before
              it is due to renew then you will still have access to CityPainter
              premium for another week.
            </div>
            <br />
            <div
              className={classNames(sharedStyles.paragraph, styles.contentText)}
            >
              If you are unhappy with CityPainter premium then you may request a
              refund. Please email matthew@citypainter.io
            </div>
            <div
              className={classNames(
                sharedStyles.heading4,
                styles.contentHeader
              )}
            >
              Usage of the platform
            </div>
            <div
              className={classNames(sharedStyles.paragraph, styles.contentText)}
            >
              The use of this website is subject to the following terms of use:
              <ul>
                <li>
                  You are responsible for exercising caution when attempting to
                  find landmarks or 'fill in' streets and trails on city painter
                  maps
                </li>
                <li>
                  While we do our best to make sure our maps are accurate we
                  cannot take responsibility for any inaccessible
                  streets/trails/landmarks/neighbourhoods. Please email us at
                  matthew@citypainter.io if you find any inaccuracies
                </li>
                <li>
                  We cannot take responsibility for any injuries that you
                  acquire while using CityPainter. You are responsible for your
                  own safety
                </li>
                <li>
                  The content of the pages of this website is for your general
                  information and use only. It is subject to change without
                  notice.
                </li>
                <li>
                  Neither we nor any third parties provide any warranty or
                  guarantee as to the accuracy, timeliness, performance,
                  completeness or suitability of the information and materials
                  found or offered on this website for any particular purpose.
                  You acknowledge that such information and materials may
                  contain inaccuracies or errors and we expressly exclude
                  liability for any such inaccuracies or errors to the fullest
                  extent permitted by law.
                </li>
                <li>
                  Your use of any information or materials on this website is
                  entirely at your own risk, for which we shall not be liable.
                  It shall be your own responsibility to ensure that any
                  products, services or information available through this
                  website meet your specific requirements.
                </li>
                <li>
                  This website contains material which is owned by or licensed
                  to us. This material includes, but is not limited to, the
                  design, layout, look, appearance and graphics. Reproduction is
                  prohibited other than in accordance with the copyright notice,
                  which forms part of these terms and conditions.
                </li>
                <li>
                  All trade marks reproduced in this website which are not the
                  property of, or licensed to, the operator are acknowledged on
                  the website.
                </li>
                <li>
                  Unauthorised use of this website may give rise to a claim for
                  damages and/or be a criminal offence.
                </li>
                <li>
                  From time to time this website may also include links to other
                  websites. These links are provided for your convenience to
                  provide further information. They do not signify that we
                  endorse the website(s). We have no responsibility for the
                  content of the linked website(s).
                </li>
                <li>
                  Your use of this website and any dispute arising out of such
                  use of the website is subject to the laws of England, Northern
                  Ireland, Scotland and Wales.
                </li>
              </ul>
            </div>
          </Content>
        </div>
      </IonContent>
    </>
  );
};

export default Terms;
