import React from "react";
import SmallHeading from "../../../../components/headings/SmallHeading";
import styles from "./DashboardLandmarks.module.css";
import sharedStyles from "../../../../styles/shared.module.css";
import LangManager from "../../../../data/languages/LangManager";
import LangKeys from "../../../../data/languages/LangKeys";
import classNames from "classnames";
import Landmark from "../../../components/landmark/Landmark";
import { ActivityType, LandmarkType } from "cp-server";
import { Link } from "react-router-dom";
import landmark from "../../../components/landmark/Landmark";

type PropsType = {
  area_id?: string;
  activities: Array<ActivityType>;
  landmarks: Array<LandmarkType>;
};
export function LandmarksSummary(props: PropsType) {
  const area_id = props.area_id;
  const landmarks = [...props.landmarks];

  function renderRecentVisits() {
    const landmarksFromRecentActivities = props.activities
      .map((a) =>
        // Remove nulls (Can happen due to the way the server processes landmarks) // TODO
        (a.landmarks || [])
          .filter((l) => l)
          .map((l) => ({
            landmark: l,
            activityName: a.name,
            activityId: a.id,
          }))
      )
      .flat();
    return landmarksFromRecentActivities.slice(0, 5).map((landmark) => {
      return (
        <div
          className={styles.landmarkContainer}
          key={landmark.landmark.landmark_id}
        >
          <Landmark landmark={landmark.landmark} />
          <Link
            className={styles.detailsText}
            style={{ cursor: "pointer" }}
            to={`/activity/${landmark.activityId}`}
          >{` - ${landmark.activityName}`}</Link>
        </div>
      );
    });
  }
  function renderMostVisited() {
    landmarks.sort((a, b) => {
      return parseInt(a.visits || "") > parseInt(b.visits || "") ? -1 : 1;
    });
    return landmarks.slice(0, 5).map((landmark) => {
      return (
        <div className={styles.landmarkContainer} key={landmark.landmark_id}>
          <Landmark landmark={landmark} />
          <span className={styles.detailsText}>{` - ${
            landmark.visits
          } ${LangManager.getLang(
            landmark.visits === "1"
              ? LangKeys.DASHBOARD_LANDMARKS_VISIT
              : LangKeys.DASHBOARD_LANDMARKS_VISITS
          )}`}</span>
        </div>
      );
    });
  }
  function getSeeAllText() {
    if (landmarks.length) {
      return `${LangManager.getLang(LangKeys.LANDMARKS_SEE_ALL)} ${
        landmarks.length
      }`;
    }
    return LangManager.getLang(LangKeys.LANDMARKS_SEE_ALL);
  }
  return (
    <div>
      <SmallHeading
        left={"Landmarks"}
        right={getSeeAllText()}
        link={area_id ? `/tabs/home/landmarks/${props.area_id}` : ""}
      />
      <div className={styles.container}>
        <div className={styles.section}>
          <div
            className={classNames(sharedStyles.heading3, styles.sectionHeader)}
          >
            {LangManager.getLang(LangKeys.DASHBOARD_LANDMARKS_RECENTLY_VISITED)}
          </div>
          {renderRecentVisits()}
        </div>
        <div className={styles.section}>
          <div
            className={classNames(sharedStyles.heading3, styles.sectionHeader)}
          >
            {LangManager.getLang(LangKeys.DASHBOARD_LANDMARKS_MOST_VISITED)}
          </div>
          {renderMostVisited()}
        </div>
      </div>
    </div>
  );
}
