import React from "react";
import { LandmarksSummary } from "./LandmarksSummary";
import { UserCityType } from "cp-server";
import {
  useGetActivitiesQuery,
  useGetLandmarksWithVisitsInAreaQuery,
} from "../../../../state/api/ApiSlice";
import { getResultsFromResponse } from "../../../../data/ApiData";

type Props = {
  userCity: UserCityType;
};
export default function DashboardLandmarks({ userCity }: Props) {
  const { data: activitiesResponse } = useGetActivitiesQuery({
    page: "1",
    area_id: userCity.area_id,
    page_size: "6",
  });
  const { data: landmarks } = useGetLandmarksWithVisitsInAreaQuery({
    area_id: userCity.area_id,
  });
  if (activitiesResponse?.success && landmarks?.success) {
    return (
      <LandmarksSummary
        area_id={userCity.area_id}
        activities={getResultsFromResponse(activitiesResponse)}
        landmarks={landmarks.landmarks}
      />
    );
  }
  return null;
}
