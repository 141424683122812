import React from "react";
import styles from "./CustomAreaAdd.module.css";
import ImageKeys from "../../../data/images/ImageKeys";
import ImageManager from "../../../data/images/ImageManager";
import sharedStyles from "../../../styles/shared.module.css";
import classNames from "classnames";
import LangManager from "../../../data/languages/LangManager";
import LangKeys from "../../../data/languages/LangKeys";
import { Link } from "react-router-dom";

export default function CustomAreaAdd() {
  return (
    <div className={styles.container}>
      <Link className={styles.inner} to={"/tabs/my-areas/draw-area"}>
        <div className={styles.addIconContainer}>
          <div
            className={styles.addIcon}
            style={{
              backgroundImage: `url(${ImageManager.getImage(
                ImageKeys.PLUS_ICON
              )})`,
            }}
          />
        </div>
        <div className={classNames(sharedStyles.heading2, styles.cityName)}>
          {LangManager.getLang(LangKeys.CUSTOM_AREA_CREATE)}
        </div>
      </Link>
    </div>
  );
}
