"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var AccountTypes;
(function (AccountTypes) {
    AccountTypes["premium"] = "premium";
    AccountTypes["free"] = "free";
})(AccountTypes || (AccountTypes = {}));
/**
 * Singleton for storing the user access token and user Id
 */
class User {
    setToken(token) {
        this.token = token;
    }
    getToken() {
        return this.token;
    }
    setUserId(id) {
        this.id = id;
    }
    getUserId() {
        return this.id;
    }
    clearData() {
        this.id = null;
        this.token = null;
        this.accountType = undefined;
    }
    setAccountType(type) {
        // We only want to allow upgrading the account type.
        // Sometimes different sources (device or server) have different account types for a short period of time. So only allow upgrades.
        // this variable is session only so the only risk here is that a user has access to premium features for longer than they should.
        if (type === AccountTypes.premium) {
            this.accountType = AccountTypes.premium;
        }
        if (this.accountType === undefined) {
            this.accountType = type;
        }
    }
    getAccountType() {
        return this.accountType;
    }
    isLoggedIn() {
        return !!this.token;
    }
}
exports.default = new User();
