import SubmitFeedbackReducer from "./submitfeedback/SubmitFeedbackReducer";
import ImportFromVendorReducer from "./importfromvendor/ImportFromVendorReducer";
import RegisterReducer from "./register/RegisterReducer";
import { composeWithDevTools } from "redux-devtools-extension";
import createSagaMiddleware from "redux-saga";
import { createBrowserHistory } from "history";
/**
 * Created by MattATI on 09/05/2020.
 */
import thunk from "redux-thunk";
import {
  applyMiddleware,
  combineReducers,
  createStore,
  Middleware,
  MiddlewareAPI,
  Store,
} from "redux";
import { ReduxStateType } from "./ReduxStateType";
import RootSagas from "./RootSagas";
import { StoreLocal, User } from "framework";
import PopupsReducer from "./popups/PopupsReducer";
import LocalStoreReducer from "./localstore/LocalStoreReducer";
import { apiSlice } from "./api/ApiSlice";
import { isRejectedWithValue, PayloadAction } from "@reduxjs/toolkit";
import UserSlice, { logout } from "./userslice/UserSlice";
import UserUtils from "../data/utils/UserUtils";
let store: Store<ReduxStateType>;

export const history = createBrowserHistory();

const saga = createSagaMiddleware();

const reducers = combineReducers({
  submitFeedback: SubmitFeedbackReducer,
  importFromVendor: ImportFromVendorReducer,
  register: RegisterReducer,
  popups: PopupsReducer,
  // router: connectRouter(history),
  localStore: LocalStoreReducer,
  userSlice: UserSlice,
  [apiSlice.reducerPath]: apiSlice.reducer,
});

export const apiErrorHandler: Middleware = (api: MiddlewareAPI) => (next) => (
  action: PayloadAction<any>
) => {
  if (isRejectedWithValue(action)) {
    //@ts-ignore TODO Correct type - No idea what it is
    if (action.payload?.status === 401) {
      // Handle invalid token for RTK queries
      UserUtils.logout();
    }
  }

  return next(action);
};

const middleware = [
  //routerMiddleware(history),
  apiSlice.middleware,
  thunk,
  saga,
  apiErrorHandler,
  StoreLocal.saveData,
];
const enhancer = composeWithDevTools(applyMiddleware(...middleware));

const initStoreWithState = (state: ReduxStateType) => {
  store = createStore(
    reducers,
    state || {},
    enhancer
    //@ts-ignore
    //window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  );

  saga.run(RootSagas);
};

/**
 * Load stored state and initialise the store
 * callback when done
 */
export function initStore(callback: () => void) {
  StoreLocal.loadData((state: ReduxStateType) => {
    initStoreWithState(state);
    callback();
  });
}

/**
 * Get store.
 * Make sure to initialise it first
 * @returns {Store.<ReduxStateType>}
 */
export function getStore() {
  return store;
}

/**
 * Get State
 * @returns {*}
 */
export function getState() {
  return store.getState();
}
