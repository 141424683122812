/**
 * Created by matthewjamieson on 15/09/2020.
 */
import { all, put, takeEvery } from "redux-saga/effects";
import {
  ApiActionType,
  ResponseType,
  StatusType,
} from "framework/build/src/types/Api";
import { push } from "connected-react-router";
import { User } from "framework";
import UserUtils from "../data/utils/UserUtils";

function* handleApiError() {
  yield takeEvery("*", function* (action: ApiActionType<ResponseType>) {
    const data: ResponseType<any> = action?.data;
    if (data?.status === StatusType.ERROR) {
      if (data.error.code === "INVALID_TOKEN") {
        // Log out user if the toke is invalid
        //@ts-ignore  TODO figure out how to type thunk dispatches
        //UserUtils.logout();
      }
    }
  });
}
export default function* () {
  yield all([handleApiError()]);
}
