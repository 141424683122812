import React, { useEffect, useRef, useState } from "react";
import styles from "./MapDraw.module.css";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import Header from "../components/Header";
import sharedStyles from "../../styles/shared.module.css";
import classNames from "classnames";
import LangManager from "../../data/languages/LangManager";
import LangKeys from "../../data/languages/LangKeys";
import Content from "../../components/content/Content";
import { Button, TextField } from "@mui/material";
import WaitForData from "../../components/WaitForData";
import { useAddCustomAreaMutation } from "../../state/api/ApiSlice";
import { IonContent, useIonViewDidEnter } from "@ionic/react";
import PolygonUtils from "../../data/utils/polygon/PolygonUtils";
import { showErrorPopup } from "../../state/popups/PopupDispatcher";

import maplibregl from "maplibre-gl";

let map: any;
let draw: any;
export default function MapDraw() {
  const [
    loadAddCustomArea,
    { isLoading: addCustomAreaLoading },
  ] = useAddCustomAreaMutation();
  function addCustomArea(name, polygon) {
    loadAddCustomArea({ name, polygon });
  }
  const mapContainer = useRef();
  const [name, setName] = useState("");
  useEffect(() => {
    setupMap();
  }, []); // is this needed? Temp fix because the hook below doesnt seem to work
  useIonViewDidEnter(() => {
    console.log("ION ENTER");
    setupMap();
  });
  function setupMap() {
    console.log("Setup map");
    const style = {
      version: 8,
      glyphs: "https://citypainter.io/fonts/{fontstack}/{range}.pbf",
      sources: {
        "raster-tiles": {
          type: "raster",
          tiles: ["https://a.tile.openstreetmap.org/{z}/{x}/{y}.png"],
          tileSize: 256,
        },
      },
      layers: [
        {
          id: "simple-tiles",
          type: "raster",
          source: "raster-tiles",
          minzoom: 0,
          maxzoom: 22,
        },
      ],
    };

    // Rough bounds of the UK
    const maxBounds = [
      [-15.665364417667831, 48.83930669769984],
      [5.3703329437564093, 61.12875996216752],
    ];
    const _map = (map = new maplibregl.Map({
      //@ts-ignore
      container: mapContainer.current,
      //@ts-ignore
      style,
      //@ts-ignore
      maxBounds: maxBounds,
      maxZoom: 15,
      minZoom: 0,
      antialias: true,
      maxParallelImageRequests: 64,
      height: 600,
    }));
    // @ts-ignore
    MapboxDraw.constants.classes.CONTROL_BASE = "maplibregl-ctrl";
    // @ts-ignore
    MapboxDraw.constants.classes.CONTROL_PREFIX = "maplibregl-ctrl-";
    // @ts-ignore
    MapboxDraw.constants.classes.CONTROL_GROUP = "maplibregl-ctrl-group";
    draw = new MapboxDraw({
      displayControlsDefault: false,
      defaultMode: "draw_polygon",
      touchBuffer: 10,
      controls: {
        polygon: true,
        trash: true,
      },
    });

    _map.addControl(draw, "top-left");
    _map.addControl(new maplibregl.NavigationControl());

    _map.on("draw.create", updateArea);
    _map.on("draw.delete", updateArea);
    _map.on("draw.update", updateArea);
  }

  function updateArea() {
    var data = draw.getAll();
    if (data && data.features.length > 0) {
      PolygonUtils.cleanPolygonData(data, draw);
    }
  }

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.value || "";
    setName(name);
  };
  function submit() {
    var data = draw.getAll();
    const cleanPolygon = PolygonUtils.cleanPolygonData(data, draw);
    if (!cleanPolygon) {
      showErrorPopup(
        "Please draw an area on the map using the tools in the top left corner"
      );
    } else {
      addCustomArea(name, cleanPolygon);
    }
  }

  return (
    <>
      <Header title={LangManager.getLang(LangKeys.DRAW_AREA_HEADER)} />
      <IonContent>
        <div className={styles.container}>
          <Content>
            <div
              className={classNames(sharedStyles.heading4, styles.subHeader)}
            >
              {LangManager.getLang(LangKeys.DRAW_AREA_SUBHEADER)}
            </div>
          </Content>
          {/*@ts-ignore - TODO*/}
          <div className={styles.map} ref={mapContainer} key={"mapcontainer"} />
          <Content>
            <div
              className={classNames(sharedStyles.heading4, styles.instruction)}
            >
              {LangManager.getLang(LangKeys.DRAW_AREA_INSTRUCTION)}
            </div>
            {/* @ts-ignore */}
            <div className={styles.inputAndButton}>
              <TextField
                variant="filled"
                color="primary"
                label={LangManager.getLang(LangKeys.DRAW_AREA_NAME)}
                InputLabelProps={{ classes: { root: styles.inputLabel } }}
                inputProps={{ maxLength: 200 }}
                InputProps={{
                  disableUnderline: true,
                }}
                onChange={handleInput}
                style={{ width: "100%", maxWidth: 500 }}
              />
              <div className={styles.buttonContainer}>
                <WaitForData isLoading={addCustomAreaLoading}>
                  <Button
                    color={"primary"}
                    variant="contained"
                    classes={{ root: styles.button, text: styles.buttonLabel }}
                    onClick={submit}
                  >
                    {LangManager.getLang(LangKeys.DRAW_AREA_CREATE)}
                  </Button>
                </WaitForData>
              </div>
            </div>
            <WaitForData
              isLoading={addCustomAreaLoading}
              loadingElement={
                <div className={classNames(sharedStyles.heading5)}>
                  This can take up to a minute. You can leave this page and you
                  will be emailed when your area is ready
                </div>
              }
            />
          </Content>
        </div>
      </IonContent>
    </>
  );
}
