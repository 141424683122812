import React, {Component, CSSProperties} from "react";
import styles from "./Content.module.css";

type PropsType = {
  fullWidth?: boolean;
  maxWidth?: number;
  style? :CSSProperties;
};

type StateType = {};

/**
 * Component for centering the main content of a page
 */
export default class Content extends Component<PropsType, StateType> {
  render() {
      const style = this.props.style
    return (
      <div className={styles.container}>
        <div
          className={`${styles.content} ${
            this.props.fullWidth ? styles.contentMaxWidth : ""
          }`}
          style={this.props.maxWidth ? { maxWidth: this.props.maxWidth, ...style } : style}
        >
          {this.props.children}
        </div>
      </div>
    );
  }
}
