import { ApiRequestBodies, ApiResponses, ApiRoutes } from "cp-server";
import { EndpointBuilder } from "@reduxjs/toolkit/dist/query/endpointDefinitions";
import { TagTypes } from "../../ApiSlice";

const Endpoint = (builder: EndpointBuilder<any, any, any>) =>
  builder.mutation<
    ApiResponses[ApiRoutes.SubscriptionStore],
    ApiRequestBodies[ApiRoutes.SubscriptionStore]
  >({
    invalidatesTags: [TagTypes.user_details],
    query: (data) => ({
      url: ApiRoutes.SubscriptionStore,
      method: "post",
      body: data,
    }),
  });

export default Endpoint;
