import ImportFromVendorResource, {
  ImportFromVendorResourceType,
} from "./ImportFromVendorResource";
import { ImportFromVendorResponseType } from "./ImportFromVendorTypes";
import { createApiActionsHelper } from "../Helpers";
import { OptionsType } from "framework/build/src/types/Api";

export const IMPORT_FROM_VENDOR_LOAD_ACTION = "IMPORT_FROM_VENDOR_LOAD";

export const importFromVendorApiActions = createApiActionsHelper<ImportFromVendorResponseType>(
  {
    resource: ImportFromVendorResource,
    loadAction: IMPORT_FROM_VENDOR_LOAD_ACTION,
  }
);

export const loadImportFromVendor = (vendor_id: string, new_user?: boolean) => {
  const options: OptionsType<ImportFromVendorResourceType["body"]> = {
    body: {
      vendor_id,
      new_user,
    },
  };
  return importFromVendorApiActions.loadResource(options);
};
