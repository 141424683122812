import { isPlatform, getPlatforms } from "@ionic/react";

export function isApp() {
  return (
    !(isPlatform("mobileweb") || isPlatform("desktop")) &&
    (isPlatform("android") || isPlatform("ios"))
  );
}

export function isWeb() {
  return !isApp();
}

export function isMobile() {
  return isPlatform("mobile");
}

export function isIos() {
  return isPlatform("ios");
}

export function isAndroid() {
  return isPlatform("android");
}

export function getPlatform() {
  return getPlatforms();
}
