/**
 * Created by MattATI on 29/04/2020.
 */
import React from "react";

type PropsType = { hidePopup: () => void };
const CloseButton: React.FunctionComponent<PropsType> = (props) => {
  return (
    <div
      onClick={props.hidePopup}
      style={{
        position: "relative",
        cursor: "pointer",
      }}
    >
      <div
        style={{
          position: "absolute",
          top: 8,
          right: 8,
          height: 16,
          width: 16,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: "var(--monotone-200)",
        }}
      >
        {"X"}
      </div>
    </div>
  );
};

export default CloseButton;
