import { ApiRequestBodies, ApiResponses, ApiRoutes } from "cp-server";
import { EndpointBuilder } from "@reduxjs/toolkit/dist/query/endpointDefinitions";

const Endpoint = (builder: EndpointBuilder<any, any, any>) =>
  builder.query<
    ApiResponses[ApiRoutes.GetLandmarksWithVisitsInArea],
    ApiRequestBodies[ApiRoutes.GetLandmarksWithVisitsInArea]
  >({
    query: (data) => ({
      url: ApiRoutes.GetLandmarksWithVisitsInArea,
      method: "post",
      body: data,
    }),
  });
export default Endpoint;
