import React, { useCallback, useEffect, useState } from "react";
import { UserNeighbourhoodType } from "cp-server";
import styles from "./NeighbourhoodsList.module.css";
import { NeighbourhoodCard } from "./NeighbourhoodCard";
import eventEmitter from "../../../data/EventEmitter";
import { useSelector } from "react-redux";
import AccountTypeValues from "../../../data/account/AccountTierFunctions";
import PremiumMessage from "../../../components/premium/PremiumMessage";

type Props = {
  userNeighbourhoods: Array<UserNeighbourhoodType>;
};

export default function NeighbourhoodsList({ userNeighbourhoods }: Props) {
  const [quantity, setQuantity] = useState(6);
  let limit = AccountTypeValues.getNumberOfNeighbourhoods();

  const showNext = useCallback(() => {
    setQuantity(limit ? Math.min(quantity + 6, limit) : quantity + 6);
  }, [quantity]);
  useEffect(() => {
    eventEmitter.on("scroll_bottom", showNext);
    return () => {
      eventEmitter.off("scroll_bottom", showNext);
    };
  }, [quantity]);
  const neighbourhoodsToShow = userNeighbourhoods.slice(0, quantity);
  return (
    <div className={styles.neighbourhoodList}>
      {neighbourhoodsToShow.map((un) => (
        <NeighbourhoodCard
          userNeighbourhood={un}
          key={un.user_neighbourhood_id}
        />
      ))}
      <PremiumMessage
        text={`Want to view all ${userNeighbourhoods.length} neighbourhoods?`}
      />
    </div>
  );
}
