import React from "react";
import Header from "../components/Header";
import styles from "./CityDetails.module.css";
import { Link, useParams } from "react-router-dom";
import WaitForData from "src/components/WaitForData";
import CityMap from "./components/map/CityMap";
import Content from "../../components/content/Content";
import Stat from "../../components/stat/Stat";
import LangManager from "../../data/languages/LangManager";
import LangKeys from "../../data/languages/LangKeys";
import { getDistance } from "../../data/utils/formating/DistanceFormating";
import CityLandmarks from "./components/landmarks/CityLandmarks";
import { Helmet } from "react-helmet";
import { IonContent } from "@ionic/react";
import {
  useGetCityByIdQuery,
  useGetLandmarksByAreaIdQuery,
} from "../../state/api/ApiSlice";
import { getResultsFromResponse } from "../../data/ApiData";
import { isMobile } from "../../platform/PlatformManager";

function CityDetails() {
  const { id: areaId } = useParams<{ id: string }>();

  const {
    data: landmarksResponse,
    isLoading: isLandmarksLoading,
  } = useGetLandmarksByAreaIdQuery({ area_id: areaId });

  const { data: cityResponse, isLoading: isCityLoading } = useGetCityByIdQuery({
    city_id: areaId,
  });
  const landmarks = getResultsFromResponse(landmarksResponse);
  function renderContent() {
    if (
      cityResponse?.success &&
      landmarksResponse?.success &&
      cityResponse.city.area_id === areaId
    ) {
      const city = cityResponse.city;
      return (
        <div className={styles.contentContainer}>
          <Helmet>
            <title>City Painter - {city.name}</title>
          </Helmet>
          <CityMap city={city} landmarks={landmarks} />
          {!isMobile() ? (
            <Link to={`/tabs/home/report-map/${city.area_id}`}>
              Report inaccurate paths
            </Link>
          ) : null}
          <Content>
            <div className={styles.footer}>
              <Stat
                style={{ marginLeft: 16 }}
                label={LangManager.getLang(LangKeys.CITY_DETAILS_TOTAL_ROADS)}
                value={getDistance(city.total_roads_distance, 0)}
              />
              <Stat
                label={LangManager.getLang(LangKeys.CITY_DETAILS_OFF_ROADS)}
                value={getDistance(city.total_off_roads_distance, 0)}
              />
              <Stat
                label={LangManager.getLang(LangKeys.CITY_DETAILS_LANDMARKS)}
                value={landmarks.length}
              />
            </div>
            <CityLandmarks landmarks={landmarks} />
          </Content>
        </div>
      );
    }
    return null;
  }

  function getHeading() {
    if (cityResponse?.success) {
      return cityResponse.city.name;
    }
    return "City";
  }
  return (
    <>
      <Header title={getHeading()} />
      <IonContent>
        <WaitForData isLoading={isLandmarksLoading || isCityLoading}>
          {renderContent()}
        </WaitForData>
      </IonContent>
    </>
  );
}

export default React.memo(CityDetails);
