import { createSelector } from "reselect";
import { ReduxStateType } from "../ReduxStateType";

export const importFromVendorStateSelector = (state: ReduxStateType) =>
  state.importFromVendor;

export const importFromVendorSelector = createSelector(
  importFromVendorStateSelector,
  (state) => state.data
);
