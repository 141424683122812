// @ts-nocheck - TODO: fix types. There are so many!
import React, { useEffect, useState } from "react";
import { ProductWithPricesType } from "cp-server";
import { IonLabel, IonSegment, IonSegmentButton } from "@ionic/react";
import sharedStyles from "../../../../styles/shared.module.css";
import styles from "./ProductSelector.module.css";
import { Button } from "@mui/material";
import { useSubscriptionCreateCheckoutMutation } from "../../../../state/api/ApiSlice";
import Content from "../../../../components/content/Content";
import { isIos } from "../../../../platform/PlatformManager";
import classNames from "classnames";
import { capitalizeFirstLetter } from "../../../../data/utils/formating/StringFormatting";
import { Link } from "react-router-dom";
import PriceCard from "./PriceCard";

const headings = {
  month3: "Every 3 Months",
  month1: "Monthly",
  year1: "Yearly",
};
const subheadings = {
  month3: "Most Popular",
  month1: "",
  year1: "Best Value",
};

export default function ProductSelectorStripe({
  product,
}: {
  product: ProductWithPricesType;
}) {
  const prices = product.prices;
  const sortedPrices = [...product.prices].sort((a, b) =>
    a.unit_amount > b.unit_amount ? 1 : -1
  );
  const [priceId, setPriceId] = useState(sortedPrices[0].id);
  const [createCheckout, { data }] = useSubscriptionCreateCheckoutMutation();
  //On checkout created by server... redirect to checkout
  useEffect(() => {
    if (data?.success && data.checkout_url) {
      window.location.href = data.checkout_url;
    }
  }, [data?.success && data.checkout_url]);
  function renderPricesSelector() {
    return (
      <IonSegment
        value={priceId}
        onIonChange={(e) => setPriceId(e.detail.value)}
        color={"primary"}
      >
        {sortedPrices.map((price, index) => {
          const key = `${price.recurring.interval}${price.recurring.interval_count}`;

          return (
            <IonSegmentButton value={price.id}>
              <IonLabel style={{ margin: 0 }}>{headings[key]}</IonLabel>
              <div
                className={classNames(
                  sharedStyles.heading5,
                  styles.buttonSubheading
                )}
              >
                {subheadings[key]}
              </div>
            </IonSegmentButton>
          );
        })}
      </IonSegment>
    );
  }

  function renderPrice() {
    const currentPrice = prices.filter((p) => p.id === priceId)[0];
    const priceFormatted = (
      parseInt(currentPrice.unit_amount_decimal) / 100
    ).toFixed(2);
    const intervalCount = currentPrice.recurring.interval_count;
    const intervalPeriodText = intervalCount > 1 ? `${intervalCount} ` : "";
    const interval = `${intervalPeriodText}${capitalizeFirstLetter(
      currentPrice.recurring.interval
    )}${intervalCount > 1 ? "s" : ""}`;

    let cpmEquivInt: number;
    if (
      currentPrice.recurring.interval === "month" &&
      currentPrice.recurring.interval_count > 1
    ) {
      cpmEquivInt =
        parseInt(currentPrice.unit_amount_decimal) /
        currentPrice.recurring.interval_count;
    }

    if (currentPrice.recurring.interval === "year") {
      cpmEquivInt =
        parseInt(currentPrice.unit_amount_decimal) /
        (currentPrice.recurring.interval_count * 12);
    }
    const cpmEquivString = cpmEquivInt ? (cpmEquivInt / 100).toFixed(2) : "";

    return (
      <PriceCard
        name={product.product.name}
        formattedPrice={`£${priceFormatted}`}
        interval={interval}
        cpmEquivString={cpmEquivString}
        onClick={() => createCheckout({ product_id: currentPrice.id })}
        showStripeLogo={true}
      />
    );
  }
  return (
    <div style={{ marginTop: 16 }}>
      <div
        style={{ marginLeft: isIos() ? 8 : 0, marginRight: isIos() ? 8 : 0 }}
      >
        {renderPricesSelector()}
      </div>
      <Content>{renderPrice()}</Content>
    </div>
  );
}
