import { UserCityType } from "cp-types";
import { Config } from "framework";
import geobuf from "geobuf";
import Pbf from "pbf";
import bbox from "@turf/bbox";
import { lineString } from "@turf/turf";
import { Geolocation } from "@capacitor/geolocation";
import maplibregl from "maplibre-gl";
import { lat2Pixel, longToPixel } from "../../../data/utils/maputils/MapUtils";

async function parseGeobuf(responce) {
  return geobuf.decode(new Pbf(await responce.arrayBuffer()));
}

function getLineWidth(zoom: number) {
  const widths = {
    10: 0.48,
    11: 0.8,
    12: 1.5,
    13: 3.5,
    14: 7,
    15: 15,
  };
  if (zoom > 15) {
    return widths[15];
  }
  if (zoom < 10) {
    return widths[10];
  }
  return widths[zoom];
}

export default class CanvasLayer {
  area: UserCityType;
  pixelsXStart: number;
  pixelsYStart: number;
  osmz: number;
  boundingBox: number[];
  roadsAndTrails: Array<any>;
  activities: any;
  canvasId: string;
  coords: Array<[number, number]>;
  currentGPSCoords: [number, number];
  map: maplibregl.Map;
  centered: boolean;
  recording: boolean;
  watcher: string;
  constructor(canvasId: string) {
    this.canvasId = canvasId;
    this.centered = true;
    this.recording = false;
    this.coords = [];
  }

  getCanvasId() {
    return this.canvasId;
  }
  setMap(map: maplibregl.Map) {
    this.map = map;
    this.draw();
    const size=180;
    console.log('set map')
    const pulsingDot = {

      width: size,
      height: size,
      data: new Uint8Array(size * size * 4),

      // get rendering context for the map canvas when layer is added to the map
      onAdd () {
        const canvas = document.createElement('canvas');
        canvas.width = this.width;
        canvas.height = this.height;
        this.context = canvas.getContext('2d');
      },

      // called once before every frame where the icon will be used
      render () {
        console.log('draw icon')
        const duration = 1000;
        const t = (performance.now() % duration) / duration;

        const radius = (size / 2) * 0.3;
        const outerRadius = (size / 2) * 0.7 * t + radius;
        const context = this.context;

        // draw outer circle
        context.clearRect(0, 0, this.width, this.height);
        context.beginPath();
        context.arc(
          this.width / 2,
          this.height / 2,
          outerRadius,
          0,
          Math.PI * 2
        );
        context.fillStyle = `rgba(255, 200, 200,${1 - t})`;
        context.fill();

        // draw inner circle
        context.beginPath();
        context.arc(
          this.width / 2,
          this.height / 2,
          radius,
          0,
          Math.PI * 2
        );
        context.fillStyle = 'rgba(255, 100, 100, 1)';
        context.strokeStyle = 'white';
        context.lineWidth = 2 + 4 * (1 - t);
        context.fill();
        context.stroke();

        // update this image's data with data from the canvas
        this.data = context.getImageData(
          0,
          0,
          this.width,
          this.height
        ).data;

        // continuously repaint the map, resulting in the smooth animation of the dot
        map.triggerRepaint();

        // return `true` to let the map know that the image was updated
        return true;
      }
    };


    // map.addImage('pulsing-dot', pulsingDot, {pixelRatio: 2});
    //
    // map.addSource('points', {
    //   'type': 'geojson',
    //   'data': {
    //     'type': 'FeatureCollection',
    //     'features': [
    //       //@ts-ignore
    //       {
    //         'type': 'Feature',
    //         'geometry': {
    //           'type': 'Point',
    //           'coordinates': [-3.113171, 55.9513564]
    //         }
    //       }
    //     ]
    //   }
    // });
    // map.addLayer({
    //   'id': 'points',
    //   'type': 'symbol',
    //   'source': 'points',
    //   'layout': {
    //     'icon-image': 'pulsing-dot'
    //   }
    //
    // });
  }
  async setup({
    area,
    bounds,
    startPoint,
  }: {
    area: UserCityType;
    bounds: { bounds: number[]; zoom: number };
    startPoint: [number, number];
  }) {
    // Setup location watcher - TODO REMOVE WATCHER
    this.currentGPSCoords = startPoint;
    this.draw();
    this.watcher = await Geolocation.watchPosition(
      { enableHighAccuracy: true, timeout: 500 },
      (position, err) => {
        if (position) {
          this.currentGPSCoords = [
            position.coords.longitude,
            position.coords.latitude,
          ];
          if (this.recording) {
            this.coords = [
              ...this.coords,
              [position.coords.longitude, position.coords.latitude],
            ];

            console.log(this.coords);
            this.draw();
          }
        }
      }
    );
    this.area = area;
    this.osmz = bounds.zoom + 2;
    this.boundingBox = bounds.bounds;
    this.pixelsXStart = longToPixel(this.boundingBox[0], this.osmz);
    this.pixelsYStart = lat2Pixel(this.boundingBox[3], this.osmz);
    await this.loadRoadsAndTrails();
    return;
  }

  reset() {
    this.coords = [];
    Geolocation.clearWatch({ id: this.watcher });
  }

  setActivities(activities) {
    this.activities = activities;
  }

  async loadRoadsAndTrails() {
    const roadsUrl = `${Config.getConfigItem("domain")}/maps/${
      this.area?.area_id
    }/roads.json`;
    const trailsUrl = `${Config.getConfigItem("domain")}/maps/${
      this.area?.area_id
    }/trails.json`;
    this.roadsAndTrails = await Promise.all([
      fetch(roadsUrl).then(parseGeobuf),
      fetch(trailsUrl).then(parseGeobuf),
    ]);
    console.log("roads and trails loaded");
  }

  getWidth() {
    if (!this.boundingBox) return 1;
    return (
      longToPixel(this.boundingBox[2], this.osmz) -
      longToPixel(this.boundingBox[0], this.osmz)
    );
  }

  getHeight() {
    if (!this.boundingBox) return 1;
    return (
      lat2Pixel(this.boundingBox[1], this.osmz) -
      lat2Pixel(this.boundingBox[3], this.osmz)
    );
  }

  setBB(bb: number[], zoom) {
    this.boundingBox = bb;
    this.osmz = Math.ceil(zoom) + 1;
    this.pixelsXStart = longToPixel(this.boundingBox[0], this.osmz);
    this.pixelsYStart = lat2Pixel(this.boundingBox[3], this.osmz);
  }

  setCentered(centered: boolean) {
    this.centered = centered;
    this.draw();
  }

  startRecording() {
    this.recording = true;
    this.coords = [this.currentGPSCoords];
    this.draw();
  }

  stopRecording() {
    this.recording = false;
    this.draw();
  }

  clearRecording() {
    this.coords = [];
    this.draw();
  }

  getCoords() {
    return this.coords;
  }

  // TODO neaten up
  draw() {
    const canvas = document.getElementById("live-canvas") as HTMLCanvasElement;
    const context = canvas?.getContext("2d");

    if (this.centered) {
      this.map?.easeTo({
        center: [this.currentGPSCoords[0], this.currentGPSCoords[1]],
        animate: true,
        duration: 500,
        essential: true,

      });
    }


    if (context) {
      canvas.width = this.getWidth();
      canvas.height = this.getHeight();

      context.clearRect(0, 0, canvas.width, canvas.height);
      // Draws a geojson feature
      const drawFeature = (feature, log) => {
        const geom = feature.geometry.coordinates;
        for (let i = 0; i < geom.length; i++) {
          const [x, y] = geom[i];
          const xPixel = longToPixel(x, this.osmz) - this.pixelsXStart;
          const yPixel = lat2Pixel(y, this.osmz) - this.pixelsYStart;
          if (i === 0) {
            context.moveTo(xPixel, yPixel);
          } else {
            context.lineTo(xPixel, yPixel);
          }
        }
      };

      const responses = this.roadsAndTrails;
      if (
        context &&
        responses &&
        this.activities &&
        this.coords &&
        this.coords.length > 1
      ) {
        context.lineCap = "round";
        context.lineJoin = "round";
        context.beginPath(); // Start a new path
        context.globalCompositeOperation = "source-over";
        context.strokeStyle = "#cc0cf0";
        context.lineWidth = getLineWidth(this.osmz - 1);

        /**
         * Draw roads
         */
        for (let i = 0; i < responses[0].features.length; i++) {
          const feature = responses[0].features[i];

          drawFeature(feature, i < 5);
        }
        context.stroke(); // Render the path
        /**
         * Draw trails
         */
        context.beginPath(); // Start a new path
        for (let i = 0; i < responses[1].features.length; i++) {
          const feature = responses[1].features[i];
          drawFeature(feature, false);
        }
        context.stroke(); // Render the path
      }

      const osmTileSize = 256;

      // const tileStartX = lon2tile(this.boundingBox[0], this.osmz);
      // const tileEndX = lon2tile(this.boundingBox[2], this.osmz);
      //
      // const tileStartY = lat2tile(this.boundingBox[1], this.osmz);
      // const tileEndY = lat2tile(this.boundingBox[3], this.osmz);
      //
      // const pixelsX =
      //   tileStartX * osmTileSize - longToPixel(this.boundingBox[0], this.osmz);
      // const pixelsY =
      //   tileStartY * osmTileSize - lat2Pixel(this.boundingBox[3], this.osmz);

      /**
       * Draw the current activity
       */

      if (this.coords && this.coords.length >= 1) {
        /**
         * Draw activities
         */
        if (this.activities) {
          context.beginPath(); // Start a new path
          context.lineWidth = getLineWidth(this.osmz - 1) * 3;
          context.strokeStyle = "#fd7f00";
          context.globalCompositeOperation = "source-atop";
          for (let i = 0; i < this.activities?.features.length; i++) {
            const feature = this.activities.features[i];
            drawFeature(feature, false);
          }
          context.stroke(); // Render the path
        }
        //const line = lineString(this.coords);
        //const box = bbox(line);

        const center = this.coords[this.coords.length - 1];
        const lat = center[1];
        const lon = center[0];

        const geom = this.coords;
        context.lineWidth = getLineWidth(this.osmz - 1);
        context.beginPath();
        context.strokeStyle = "black";
        context.globalCompositeOperation = "destination-in";
        context.lineWidth = getLineWidth(this.osmz - 1) * 3;

        for (let i = 0; i < geom.length; i++) {
          const [x, y] = geom[i];
          const xPixel = longToPixel(x, this.osmz) - this.pixelsXStart;
          const yPixel = lat2Pixel(y, this.osmz) - this.pixelsYStart;
          if (i === 0) {
            context.moveTo(xPixel, yPixel);
          } else {
            context.lineTo(xPixel, yPixel);
          }
        }

        context.stroke(); // Render the path
      }

      // Draw the current location dot
      if (this.currentGPSCoords) {
        const [x, y] = this.currentGPSCoords;
        const xPixel = longToPixel(x, this.osmz) - this.pixelsXStart;
        const yPixel = lat2Pixel(y, this.osmz) - this.pixelsYStart;
        context.globalCompositeOperation = "source-over";

        context.beginPath();
        context.arc(xPixel, yPixel, 8, 0, 2 * Math.PI);
        context.fillStyle = "red";
        context.fill();
        context.lineWidth = 4;
        context.strokeStyle = "blue";
        context.stroke();
      }
    }
  }
}
