import React, { PureComponent } from "react";
import styles from "./Activity.module.css";
import moment from "moment";
import LangManager from "../../../../data/languages/LangManager";
import LangKeys from "../../../../data/languages/LangKeys";
import { ReduxStateType } from "../../../../state/ReduxStateType";
import { connect } from "react-redux";
import Stat from "../../../../components/stat/Stat";
import { getDistance } from "../../../../data/utils/formating/DistanceFormating";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Config } from "framework";
import classNames from "classnames";
import sharedStyles from "../../../../styles/shared.module.css";
import type {
  ActivityType,
  LandmarkType,
  NeighbourhoodSimple,
} from "cp-server";
import Landmark from "../../../components/landmark/Landmark";
import { getDuration } from "../../../../data/utils/formating/DateTimeFormatting";
import { PopupTypes, showPopup } from "../../../../state/popups/PopupsActions";
import { FeedbackCategory } from "../../../../popups/feedback/FeedbackPopup";
import Neighbourhood from "../../../components/neighbourhood/Neighbourhood";
import LandmarkUtils from "../../../../data/utils/landmarks/LandmarkUtils";
import NeighbourhoodUtils from "../../../../data/utils/neighbourhoods/NeighbourhoodUtils";
type DispatchProps = {
  showFeedbackPopup: () => void;
};

type StateProps = {};

type OtherProps = {
  data: ActivityType;
  percent?: string;
} & RouteComponentProps;
type PropsType = StateProps & DispatchProps & OtherProps;

type StateType = {};

class Activity extends PureComponent<PropsType, StateType> {
  goToActivity = () => {
    this.props.history.push(`/tabs/home/activity/${this.props.data.id}`);
  };

  showFeedbackPopup = (e: React.MouseEvent) => {
    e.stopPropagation();
    this.props.showFeedbackPopup();
  };

  noneVisitedMessage(
    landmarks: Array<LandmarkType>,
    neighbourhoods: Array<NeighbourhoodSimple>
  ) {
    // Removes nulls (Can happen due to the way the server processes these items) // TODO

    if (!landmarks.length && !neighbourhoods.length)
      return (
        <div>
          <div
            className={classNames(
              sharedStyles.heading4,
              styles.noLandmarksText
            )}
          >
            {LangManager.getLang(LangKeys.ACTIVITIES_NONE_VISITED)}
          </div>
        </div>
      );
  }
  renderLandmarks(landmarks: Array<LandmarkType>, count: number) {
    // Remove nulls (Can happen due to the way the server processes landmarks) // TODO
    if (!landmarks.length) {
      return null;
    }
    const firstLandmarks = landmarks.slice(0, count);
    const all = firstLandmarks.length === landmarks.length;
    return (
      <div className={styles.landmarksContainer}>
        <div className={classNames(sharedStyles.heading4, styles.visitedText)}>
          {LangManager.getLang(LangKeys.ACTIVITIES_LANDMARKS_VISITED)}
          {!all ? ` (${firstLandmarks.length} of ${landmarks.length})` : ""}
        </div>
        {firstLandmarks.map((l) => {
          return (
            <Landmark
              landmark={l}
              key={`${this.props.data.id}_${l.landmark_id}`}
            />
          );
        })}
      </div>
    );
  }

  renderNeighbourhoods(
    neighbourhoods: Array<NeighbourhoodSimple>,
    count: number
  ) {
    // Remove nulls (Can happen due to the way the server processes neighbourhoods) // TODO
    if (!neighbourhoods.length) {
      return null;
    }
    const firstNeighbourhoods = neighbourhoods.slice(0, count);
    const all = firstNeighbourhoods.length === neighbourhoods.length;
    return (
      <div className={styles.neighbourhoods}>
        <div className={classNames(sharedStyles.heading4, styles.visitedText)}>
          {LangManager.getLang(LangKeys.ACTIVITIES_NEIGHBOURHOODS_VISITED)}
          {!all
            ? ` (${firstNeighbourhoods.length} of ${neighbourhoods.length})`
            : ""}
        </div>
        {firstNeighbourhoods.map((n) => {
          return <Neighbourhood neighbourhood={n} key={n.neighbourhood_id} />;
        })}
      </div>
    );
  }

  renderVisits(activity: ActivityType) {
    // Removes nulls (Can happen due to the way the server processes these items) // TODO
    const filteredLandmarks = LandmarkUtils.removeDuplicates(
      activity.landmarks
    ).filter((l) => l);
    const filteredNeighbourhoods = NeighbourhoodUtils.removeDuplicates(
      activity.neighbourhoods
    ).filter((n) => n);

    const neighbourhoodsAllowed = 4 + Math.max(3 - filteredLandmarks.length, 0);
    const landmarksAllowed = 4 + Math.max(3 - filteredNeighbourhoods.length, 0);

    return (
      <>
        {this.noneVisitedMessage(filteredLandmarks, filteredNeighbourhoods)}
        {this.renderLandmarks(filteredLandmarks, landmarksAllowed)}
        {this.renderNeighbourhoods(
          filteredNeighbourhoods,
          neighbourhoodsAllowed
        )}
      </>
    );
  }

  render() {
    const activity = this.props.data;
    const date = moment(activity.start_time).format("@ HH:mm MMMM Do YYYY");
    const duration = getDuration(activity.moving_time);
    const percentIsLoading = !activity.percent_new && !this.props.percent;
    const percentNew: string = parseFloat(
      activity.percent_new || this.props.percent || "0"
    ).toFixed(1);
    const percentOld: string = (100 - parseFloat(percentNew)).toFixed(1);
    return (
      <div className={styles.container} onClick={this.goToActivity}>
        <div className={styles.inner}>
          <div
            className={styles.image}
            style={{
              backgroundImage: `url(${Config.getConfigItem(
                "domain"
              )}/activities/${activity.id}.png)`,
            }}
          />
          <div className={styles.detailsContainer}>
            <div
              className={classNames(
                activity.name.length > 80
                  ? sharedStyles.heading4
                  : sharedStyles.heading2,
                styles.title
              )}
            >
              {activity.name}
            </div>
            <div className={styles.date}>{date}</div>
            <div className={styles.details}>
              <div className={styles.statsContainer}>
                <Stat
                  label={LangManager.getLang(LangKeys.ACTIVITY_NEW_GROUND)}
                  value={`${percentNew}%`}
                  valueColor="var(--purple)"
                  loading={percentIsLoading}
                />
                <Stat
                  label={"Distance"}
                  value={getDistance(activity.distance)}
                />
                <Stat
                  label={LangManager.getLang(LangKeys.ACTIVITY_REVISITED)}
                  value={`${percentOld}%`}
                  valueColor="var(--orange-2)"
                  loading={percentIsLoading}
                />
                <Stat label={"Time"} value={duration} />
              </div>
            </div>
            <div className={styles.divider} />
          </div>
          <div className={styles.visitsContainer}>
            {this.renderVisits(activity)}
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: ReduxStateType): StateProps {
  return {};
}

function mapDispatchToProps(dispatch): DispatchProps {
  return {
    showFeedbackPopup: () =>
      dispatch(
        showPopup({
          popupType: PopupTypes.FEEDBACK,
          data: { category: FeedbackCategory.landmarks },
        })
      ),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Activity)
);
