import React from "react";
import styles from "./CreateAreaLink.module.css";
import sharedStyles from "../../../styles/shared.module.css";
import LangManager from "../../../data/languages/LangManager";
import LangKeys from "../../../data/languages/LangKeys";
import { useHistory } from "react-router-dom";
import classNames from "classnames";
import { Button } from "@mui/material";
import { IonButton } from "@ionic/react";

function CreateAreaLink({ edit }: { edit: boolean }) {
  const link = edit ? "/tabs/my-areas/edit-areas" : "/tabs/my-areas/draw-area";
  const history = useHistory();
  return (
    <IonButton
      onClick={() => history.push(link)}
      className={classNames(sharedStyles.heading4, styles.link)}
      fill={"clear"}
    >
      {LangManager.getLang(
        edit ? LangKeys.CREATE_OR_EDIT_AREA : LangKeys.CREATE_AREA
      )}
    </IonButton>
  );
}
export default React.memo(CreateAreaLink);
