import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { User } from "framework";

const initialState: { token: null | string; vendor: string } = {
  token: null,
  vendor: "",
};

const userSlice = createSlice({
  name: "userSlice",
  initialState,
  reducers: {
    setToken(state, action: PayloadAction<string>) {
      state.token = action.payload;
    },
    setVendor(state, action: PayloadAction<string>) {
      state.vendor = action.payload;
    },
    logout(state) {
      state.token = null;
      /**
       * Side effects in a reducer is not ideal but it does do the job
       * TODO this should go somewhere else - (is there an RTK equivalent of redux-sagas?)
       */
      User.setToken("");
    },
  },
});

export const accessTokenSelector = (state) => state.userSlice.token;
export const vendorSelector = (state) => state.userSlice.vendor;
export const isLoggedInSelector = (state) => Boolean(state.userSlice.token);

export const { setToken, logout, setVendor } = userSlice.actions;
export default userSlice.reducer;
