import React, { PropsWithChildren } from "react";
import { useUserQuery } from "../../../../state/api/ApiSlice";
import moment from "moment/moment";
import DisplayIf from "../../../../components/display-if/DisplayIf";
import InfoMessage from "../info-message/InfoMessage";

const ACCOUNT_AGE_THRESHOLD = 300; // In seconds. Accounts younger than 10 minutes will display a message informing the user that neighbourhood stats might not be complete as they take time to process
// This is just an approximate time. The actual time it takes to process the data is not known.

export default function IsAccountReady({
  showPlaceholder,
  children,
}: PropsWithChildren<{ showPlaceholder: boolean }>) {
  const { data: userResponse } = useUserQuery({});

  const isGarmin = userResponse?.success && userResponse.vendor === "garmin";
  const accountAge =
    userResponse?.success &&
    moment().diff(moment(userResponse.user.created_at), "seconds");

  // For now only garmin needs the 'account ready' check. Strava is ready immediately.
  // Reassess when adding new platforms
  const isAccountReady =
    !isGarmin || Number(accountAge) > ACCOUNT_AGE_THRESHOLD;
  return (
    <>
      <DisplayIf condition={isAccountReady}>{children}</DisplayIf>
      <DisplayIf condition={!isAccountReady && showPlaceholder}>
        <InfoMessage>
          Your activity data can take up to 5 minutes to process.
        </InfoMessage>
      </DisplayIf>
    </>
  );
}
