import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import Helmet from "react-helmet";
import { IonAlert, IonContent } from "@ionic/react";
import Content from "../../components/content/Content";
import { useHistory, useParams } from "react-router-dom";
import {
  useGroupDeleteMutation,
  useGroupGetMembersQuery,
  useGroupQuery,
} from "../../state/api/ApiSlice";
import { getResultsFromResponse } from "../../data/ApiData";
import SmallHeading from "../../components/headings/SmallHeading";
import classNames from "classnames";
import sharedStyles from "../../styles/shared.module.css";
import styles from "./GroupDetails.module.css";
import Stat from "../../components/stat/Stat";
import DashboardLeaderboardLinks from "../dashboard/components/leaderboard/DashboardLeaderboardLinks";
import {
  ClickAwayListener,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  TextField,
  Tooltip,
} from "@mui/material";
import { ReactComponent as CopyIcon } from "../../assets/copy-icon.svg";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { PopupTypes, showPopup } from "../../state/popups/PopupsActions";
import { ReactComponent as MoreVert } from "../../assets/more-vert-black.svg";
import { GroupType } from "cp-server";

export default function GroupDetails() {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const location = window.location;
  const urlSearchParams = new URLSearchParams(location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  const justJoined = params.joined === "true";
  const { data: groupResponse } = useGroupQuery({ group_id: id });
  const { data: groupMembersResponse } = useGroupGetMembersQuery({
    group_id: id,
  });

  const group = (groupResponse?.success && groupResponse?.group) || undefined;

  useEffect(() => {
    if (groupResponse?.success && justJoined) {
      const name = groupResponse?.group?.name;
      dispatch(
        showPopup({
          popupType: PopupTypes.ERROR,
          data: { message: `You've joined ${name} ` },
        })
      );
    }
  }, [groupResponse]);
  const groupMembers = getResultsFromResponse(groupMembersResponse);
  function getHeaderTitle() {
    if (groupResponse?.success) {
      const group = groupResponse?.group;
      return group?.name;
    }
    return "Group details";
  }

  function renderContent() {
    if (groupResponse?.success) {
      const group = groupResponse?.group;
      const link = "https://citypainter.io/join/" + group.referral_code;
      return (
        <Content>
          <div className={classNames(sharedStyles.heading3, styles.header)}>
            <Stat value={groupMembers.length} label={"Members"} />
            <Stat value={group.area_name} label={"Area"} />
            <TextField
              variant={"outlined"}
              label={t("groups.inviteLink")}
              value={link}
              style={{ width: 380 }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <CopyButton text={link} />
                  </InputAdornment>
                ),
              }}
            />
          </div>

          <SmallHeading left={"Leaderboards"}></SmallHeading>
          <div className={styles.leaderboardLinksContainer}>
            <DashboardLeaderboardLinks
              id={group.area_id}
              type={"area"}
              groupId={group.group_id}
            />
          </div>

          <SmallHeading left={"Members"}></SmallHeading>
          {groupMembers.map((member) => {
            return <div className={styles.memberRow}>{member.user_name}</div>;
          })}
        </Content>
      );
    }
    return null;
  }

  return (
    <>
      <Helmet>
        <title>{getHeaderTitle()}</title>
      </Helmet>
      <Header
        title={getHeaderTitle()}
        rightContent={<GroupMenu group={group} />}
      />

      <IonContent>{renderContent()}</IonContent>
    </>
  );
}

function CopyButton({ text }: { text: string }) {
  const [open, setOpen] = React.useState(false);
  function handleTooltipClose() {
    setOpen(false);
  }
  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <div>
        <Tooltip
          PopperProps={{
            disablePortal: true,
          }}
          onClose={handleTooltipClose}
          open={open}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          placement={"top"}
          title={"Copied!"}
        >
          <IconButton
            color={"primary"}
            onClick={() => {
              setOpen(true);
              navigator.clipboard.writeText(text);
            }}
            size="large"
          >
            <CopyIcon className={styles.copyIcon} />
          </IconButton>
        </Tooltip>
      </div>
    </ClickAwayListener>
  );
}

function GroupMenu({ group }: { group?: GroupType }) {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [showDelete, setShowDelete] = useState(false);
  const [deleteGroup, { data: deleteGroupResponse }] = useGroupDeleteMutation();
  const { t } = useTranslation();
  const history = useHistory();
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen(!open);
  };

  useEffect(() => {
    if (deleteGroupResponse?.success) {
      history.goBack();
    }
  }, [deleteGroupResponse]);

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        size="large"
      >
        <MoreVert />
      </IconButton>

      <Menu
        id="long-menu"
        open={open}
        onClose={() => setOpen(false)}
        anchorEl={anchorEl}
      >
        <MenuItem
          className={classNames(
            sharedStyles.text,
            styles.menuItem,
            styles.delete
          )}
          onClick={() => {
            setOpen(false);
            setShowDelete(true);
          }}
        >
          {t("groupDetails.delete")}
        </MenuItem>
      </Menu>
      <IonAlert
        isOpen={showDelete}
        subHeader={`Are you sure you want to delete ${group?.name}`}
        buttons={[
          {
            text: "Cancel",
            role: "cancel",
            handler: () => {
              setShowDelete(false);
            },
          },
          {
            text: "Delete",
            role: "confirm",
            handler: () => {
              deleteGroup({ group_id: group?.group_id || "" });
              setShowDelete(false);
            },
          },
        ]}
        onDidDismiss={() => setShowDelete(false)}
      ></IonAlert>
    </div>
  );
}
