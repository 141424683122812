import React, { useEffect } from "react";
import Header from "../components/Header";
import { IonContent } from "@ionic/react";
import Content from "../../components/content/Content";
import Helmet from "react-helmet";
import styles from "./GroupsCreate.module.css";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import {
  useGetUserCitiesQuery,
  useGroupCreateMutation,
} from "../../state/api/ApiSlice";
import { useDispatch } from "react-redux";
import { PopupTypes, showPopup } from "../../state/popups/PopupsActions";
import AccountTierFunctions from "../../data/account/AccountTierFunctions";
import { useHistory } from "react-router-dom";

export default function GroupsCreate() {
  const [name, setName] = React.useState("");
  const [areaId, setAreaId] = React.useState("");
  const history = useHistory();
  const dispatch = useDispatch();

  const {
    data: userCities,
    isLoading: userCitiesLoading,
  } = useGetUserCitiesQuery({});

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.value || "";
    setName(name);
  };

  const [createGroup, { data: createGroupResponse }] = useGroupCreateMutation();
  useEffect(() => {
    if (createGroupResponse?.success) {
      history.replace(`/tabs/home/group/${createGroupResponse.group_id}`);
    }
  }, [createGroupResponse]);
  function onCreate() {
    if (!areaId) {
      dispatch(
        showPopup({
          popupType: PopupTypes.ERROR,
          data: { message: "Please select an area" },
        })
      );
    }
    createGroup({ name, area_id: areaId });
  }

  function renderAreas() {
    if (userCities?.success) {
      const { availableAreas } = AccountTierFunctions.processUserAreasList(
        userCities.cities
      );
      return (
        <FormControl variant="filled">
          <InputLabel>Select an area</InputLabel>
          <Select
            label={"Select an area"}
            value={areaId}
            onChange={(e) => setAreaId(e.target.value as string)}
          >
            {/* Filter custom areas out because groups can only be for public areas */}
            {availableAreas
              .filter((area) => area.type !== "custom")
              .map((area) => {
                return <MenuItem value={area.area_id}>{area.name}</MenuItem>;
              })}
          </Select>
        </FormControl>
      );
    }
    return null;
  }
  return (
    <>
      <Helmet>
        <title>City Painter - Create Group</title>
      </Helmet>
      <Header title={`Create a group`} />
      <IonContent>
        <Content>
          <div className={styles.inputContainer}>
            {renderAreas()}
            <TextField
              variant="filled"
              color="primary"
              label={"Group name"}
              inputProps={{ maxLength: 200 }}
              InputProps={{
                disableUnderline: true,
              }}
              value={name}
              onChange={handleInput}
              style={{ width: "100%" }}
            />
            <Button
              variant={"contained"}
              className={styles.button}
              color={"primary"}
              onClick={onCreate}
              fullWidth
              style={{ width: 200 }}
            >
              Create
            </Button>
          </div>
        </Content>
      </IonContent>
    </>
  );
}
