import React from "react";
import Header from "../components/Header";
import Content from "../../components/content/Content";
import styles from "./CustomAreas.module.css";
import CustomAreaEdit from "./components/CustomAreaEdit";
import LangManager from "../../data/languages/LangManager";
import LangKeys from "../../data/languages/LangKeys";
import classNames from "classnames";
import CustomAreaAdd from "./components/CustomAreaAdd";
import { useGetUserCitiesQuery } from "../../state/api/ApiSlice";
import { IonContent } from "@ionic/react";
import PremiumMessage from "../../components/premium/PremiumMessage";
import AccountTierFunctions from "../../data/account/AccountTierFunctions";

export default function CustomAreas() {
  const { data: userAreas } = useGetUserCitiesQuery({});
  function renderCustomAreas() {
    if (userAreas?.success) {
      const customAreas = userAreas.cities.filter(
        (area) => area.type === "custom"
      );
      return (
        <div className={styles.areaList}>
          {/* @ts-ignore */}
          {AccountTierFunctions.getNumberOfCustomAreas() <=
          customAreas.length ? null : (
            <CustomAreaAdd />
          )}
          {customAreas.map((area) => (
            <CustomAreaEdit area={area} key={area.area_id} />
          ))}
          {customAreas.length ? (
            <PremiumMessage
              text={`Want more than ${AccountTierFunctions.getNumberOfCustomAreas()} custom area`}
            />
          ) : null}
        </div>
      );
    }
    return null;
  }
  return (
    <>
      <Header title={LangManager.getLang(LangKeys.CUSTOM_AREAS_HEADER)} />
      <IonContent>
        <Content>{renderCustomAreas()}</Content>
      </IonContent>
    </>
  );
}
