import React, { useEffect, useState } from "react";
import { IonContent } from "@ionic/react";
import MainHeading from "../../components/headings/MainHeading";
import CPMap from "../../components/map/CPMap";
import { useParams } from "react-router-dom";
import {
  useGetCityByIdQuery,
  useGetLandmarksByAreaIdQuery,
  useReportInaccessibleAreaMutation,
} from "../../state/api/ApiSlice";
import styles from "./ReportInaccessibleArea.module.css";
import { getResultsFromResponse } from "../../data/ApiData";
import { Button, TextField } from "@mui/material";
import Content from "../../components/content/Content";
import { showErrorPopup } from "../../state/popups/PopupDispatcher";
import PolygonUtils from "../../data/utils/polygon/PolygonUtils";
import MapboxDraw from "@mapbox/mapbox-gl-draw";

let map: any;
let draw: MapboxDraw;
export default function ReportInaccessibleAreaTool() {
  const [reason, setReason] = useState("");
  const [
    report,
    { data, isSuccess: isReportSuccess },
  ] = useReportInaccessibleAreaMutation();
  const { area_id } = useParams<{ area_id: string }>();
  const { data: areaResponse, isSuccess } = useGetCityByIdQuery({
    city_id: area_id,
  });
  const { data: landmarksResponce } = useGetLandmarksByAreaIdQuery({ area_id });
  const landmarks = getResultsFromResponse(landmarksResponce);

  useEffect(() => {
    // Cleanup
    return () => {
      map = null;
      //@ts-ignore
      draw = null;
    };
  }, []);
  useEffect(() => {
    if (isReportSuccess) {
      showErrorPopup("Area Successfully Reported! Reason:" + reason);
      setReason("");
    }
  }, [isReportSuccess]);

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.value || "";
    setReason(name);
  };

  function submit() {
    var data = draw.getAll();
    const cleanPolygon = PolygonUtils.cleanPolygonData(data, draw);
    if (!cleanPolygon) {
      showErrorPopup(
        "Please draw an area on the map using the tools in the top left corner"
      );
    } else {
      report({ name: reason, way: cleanPolygon });
    }
  }

  function onMapReady(map: maplibregl.Map) {
    /**
     * Needed only for the inaccessible path reporting tool
     */
    //@ts-ignore
    MapboxDraw.constants.classes.CONTROL_BASE = "maplibregl-ctrl";
    //@ts-ignore
    MapboxDraw.constants.classes.CONTROL_PREFIX = "maplibregl-ctrl-";
    //@ts-ignore
    MapboxDraw.constants.classes.CONTROL_GROUP = "maplibregl-ctrl-group";
    draw = new MapboxDraw({
      displayControlsDefault: false,
      defaultMode: "draw_polygon",
      touchBuffer: 10,
      controls: {
        polygon: true,
        trash: true,
      },
    });
    //@ts-ignore
    map.addControl(draw, "top-left");
    //map.addControl(new maplibregl.NavigationControl());

    const updateArea = () => {
      const data = draw.getAll();
      if (data && data.features.length > 0) {
        const poly = PolygonUtils.cleanPolygonData(data, draw);
      }
      console.log("poly update", data);
    };
    map.on("draw.create", updateArea);
    map.on("draw.delete", updateArea);
    map.on("draw.update", updateArea);
  }
  return (
    <>
      <IonContent>
        <MainHeading title={"Report Inaccessible Area"} />
        {isSuccess ? (
          <CPMap
            area_id={area_id}
            boundingBox={
              areaResponse.success ? areaResponse.city.bounding_box : undefined
            }
            key={"report-map"}
            onSetup={onMapReady}
            landmarks={landmarks}
            className={styles.map}
          />
        ) : null}
        <Content>
          <TextField
            variant="filled"
            color="primary"
            label={"Reason for report"}
            value={reason}
            inputProps={{ maxLength: 200 }}
            InputProps={{
              disableUnderline: true,
            }}
            onChange={handleInput}
            style={{ width: "100%", maxWidth: 500 }}
          />
          <Button
            variant={"contained"}
            color={"primary"}
            style={{ width: 200, marginTop: 24 }}
            onClick={submit}
            disabled={!reason}
          >
            Submit
          </Button>
        </Content>
      </IonContent>
    </>
  );
}
