import React from "react";
import styles from "./SignIn.module.css";
import IntroLayout from "../../components/introlayout/IntroLayout";
import SignInBox from "../../components/auth/signin/SignInBox";

export default function SignIn() {
  return (
    <IntroLayout>
      <div className={styles.container}>
        <SignInBox />
      </div>
    </IntroLayout>
  );
}
