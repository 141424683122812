import en from "./EN/en.json";
import { LangConstants, LangManager } from "framework";

class CPLangManager extends LangManager {
  constructor() {
    super();
    this.addLanguage(LangConstants.EN, en);
  }
}

export default new CPLangManager();
