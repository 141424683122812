import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import { IonContent } from "@ionic/react";
import { Config } from "framework";
import {
  useSubscriptionGetAvailableProductsQuery,
  useSubscriptionStoreMutation,
} from "../../state/api/ApiSlice";
import { getResultsFromResponse } from "../../data/ApiData";
import PremiumComparisonGrid from "./components/comparisongrid/PremiumComparisonGrid";
import Content from "../../components/content/Content";
import ProductSelectorStripe from "./components/productdisplay/ProductSelectorStripe";
import SuccessCard from "./components/success/SuccessCard";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";

export default function Premium() {
  const location = useLocation();
  let [success, setSuccess] = useState(false);
  let [sessionId, setSessionId] = useState("");
  const [currentPrice] = useState("");
  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(location.search);

    if (query.get("success")) {
      setSuccess(true);
      setSessionId(query.get("session_id") || "");
    }

    if (query.get("canceled")) {
      setSuccess(false);
      // setMessage(
      //   "Order canceled -- continue to shop around and checkout when you're ready."
      // );
    }
  }, [sessionId, location]);

  return (
    <>
      <Helmet>
        <title>City Painter - Upgrade</title>
      </Helmet>
      <Header title={"Upgrade"} />
      <IonContent>
        {success && sessionId !== "" ? (
          <SuccessDisplay sessionId={sessionId} />
        ) : (
          <ProductDisplay />
        )}
      </IonContent>
    </>
  );
}

const domain = Config.getConfigItem("domain");

const ProductDisplay = () => {
  const { data: productsResponse } = useSubscriptionGetAvailableProductsQuery();
  const products = getResultsFromResponse(productsResponse);

  return (
    <>
      <Content>
        <PremiumComparisonGrid />
      </Content>
      {products.length ? <ProductSelectorStripe product={products[0]} /> : null}
    </>
  );
};

const SuccessDisplay = ({ sessionId }) => {
  const [storeSubscription, { data }] = useSubscriptionStoreMutation();
  useEffect(() => {
    storeSubscription({ session_id: sessionId });
  }, [sessionId]);
  return <SuccessCard />;
};

// const Message = ({ message }) => (
//   <section>
//     <p>{message}</p>
//   </section>
// );
