import React, { Component } from "react";
import Landmark from "../../components/landmark/Landmark";
import { LandmarkType } from "cp-server";
import styles from "./AchievementLandmark.module.css";
import ImageManager from "../../../data/images/ImageManager";
import ImageKeys from "../../../data/images/ImageKeys";

type PropsType = { landmark: LandmarkType; visited: boolean };

type StateType = {};

export default class AchievementLandmark extends Component<
  PropsType,
  StateType
> {
  render() {
    return (
      <div className={styles.container}>
        <Landmark landmark={this.props.landmark} />
        {this.props.visited ? (
          <div
            className={styles.tickImage}
            style={{
              backgroundImage: `url(${ImageManager.getImage(
                ImageKeys.TICK_ICON
              )})`,
            }}
          />
        ) : null}
      </div>
    );
  }
}
