import { ReduxStateType } from "../ReduxStateType";
import { createSelector } from "reselect";

export const popupsStateSelector = (state: ReduxStateType) => state.popups;

export const accountPopupSelector = createSelector(
  popupsStateSelector,
  (data) => data.account
);

export const errorPopupSelector = createSelector(
  popupsStateSelector,
  (data) => data.error
);

export const feedbackPopupSelector = createSelector(
  popupsStateSelector,
  (data) => data.feedback
);
export const deleteConfirmPopupSelector = createSelector(
  popupsStateSelector,
  (data) => data.deleteConfirm
);
export const vendorPermissionsErrorPopupSelector = createSelector(
  popupsStateSelector,
  (data) => data.vendorPermissionsError
);

export const deleteAccountConfirmPopupSelector = createSelector(
  popupsStateSelector,
  (data) => data.deleteAccountConfirm
);

export const namePopupSelector = createSelector(
  popupsStateSelector,
  (data) => data.namePopup
);

export const activityDescriptionPermissionsPopupSelector = createSelector(
  popupsStateSelector,
  (data) => data.activityDescriptionPermissions
);
