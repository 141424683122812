import React, { Component } from "react";
import styles from "./FeedbackPopup.module.css";
import { connect } from "react-redux";
import {
  Button,
  CircularProgress,
  Dialog,
  MenuItem,
  Select,
} from "@mui/material";
import { ReduxStateType } from "../../state/ReduxStateType";
import { hidePopup, PopupTypes } from "../../state/popups/PopupsActions";
import { FeedbackPopupType, PopupType } from "../../state/popups/PopupsTypes";
import { feedbackPopupSelector } from "../../state/popups/PopupsSelectors";
import sharedStyles from "../../styles/shared.module.css";
import classNames from "classnames";
import {
  clearSubmitFeedback,
  loadSubmitFeedback,
  loadSubmitFeedbackPublic,
} from "../../state/submitfeedback/SubmitFeedbackActions";
import {
  SubmitFeedbackResponseType,
  SubmitFeedbackStateType,
} from "../../state/submitfeedback/SubmitFeedbackTypes";
import {
  submitFeedbackSelector,
  submitFeedbackStateSelector,
} from "../../state/submitfeedback/SubmitFeedbackSelectors";
import LangManager from "../../data/languages/LangManager";
import LangKeys from "../../data/languages/LangKeys";
import { isLoggedInSelector } from "../../state/userslice/UserSlice";

type DispatchProps = {
  closePopup: () => void;
  submitFeedback: (message, category) => void;
  submitFeedbackPublic: (message, category) => void;
  clearSubmitFeedback: () => void;
};
type StateProps = {
  feedbackPopup: FeedbackPopupType;
  submitFeedbackState: SubmitFeedbackStateType;
  submitFeedbackResponse: SubmitFeedbackResponseType;
  isLoggedIn: boolean;
};
type OtherProps = {};
type PropsType = StateProps & DispatchProps & OtherProps;

type StateType = { message: string; category: FeedbackCategory };

export enum FeedbackCategory {
  general = "general",
  city_request = "city_request",
  feature_request = "feature_request",
  criticism = "criticism",
  maps = "maps",
  activities = "activities",
  landmarks = "landmarks",
  new_features = "new_features",
}

const categories: { [key in keyof typeof FeedbackCategory]: string } = {
  general: LangManager.getLang(LangKeys.FEEDBACK_TYPE_GENERAL),
  city_request: LangManager.getLang(LangKeys.FEEDBACK_TYPE_AREA),
  feature_request: LangManager.getLang(LangKeys.FEEDBACK_TYPE_REQUEST),
  criticism: LangManager.getLang(LangKeys.FEEDBACK_TYPE_CRITICISM),
  maps: LangManager.getLang(LangKeys.FEEDBACK_TYPE_MAPS),
  activities: LangManager.getLang(LangKeys.FEEDBACK_TYPE_ACTIVITIES),
  landmarks: LangManager.getLang(LangKeys.FEEDBACK_TYPE_LANDMARKS),
  new_features: LangManager.getLang(LangKeys.FEEDBACK_NEW_FEATURES),
};

class FeedbackPopup extends Component<PropsType, StateType> {
  constructor(props: PropsType) {
    super(props);
    const category = props.feedbackPopup.category || FeedbackCategory.general;
    const message = props.feedbackPopup.message || "";
    this.state = {
      message,
      category: category,
    };
  }

  submit = () => {
    if (this.props.isLoggedIn) {
      this.props.submitFeedback(this.state.message, this.state.category);
    } else {
      this.props.submitFeedbackPublic(this.state.message, this.state.category);
    }
  };

  renderSending = () => {
    return <CircularProgress />;
  };

  renderSent = () => {
    return (
      <>
        <div>{LangManager.getLang(LangKeys.FEEDBACK_THANKS)}</div>
        <div style={{ flex: 1 }} />
        <div className={styles.buttonContainer}>
          <Button
            variant="contained"
            classes={{ root: styles.button, text: styles.buttonLabel }}
            onClick={this.close}
          >
            {LangManager.getLang(LangKeys.FEEDBACK_CLOSE)}
          </Button>
        </div>
      </>
    );
  };
  componentDidUpdate(
    prevProps: Readonly<PropsType>,
    prevState: Readonly<StateType>,
    snapshot?: any
  ) {
    if (this.props.feedbackPopup !== prevProps.feedbackPopup) {
      const category =
        this.props.feedbackPopup.category || FeedbackCategory.general;
      const message = this.props.feedbackPopup.message || "";
      this.setState({ category, message });
    }
  }

  renderContent = () => {
    const sent = this.props.submitFeedbackResponse?.success;

    if (sent) {
      return this.renderSent();
    }

    return (
      <>
        <textarea
          className={styles.textInput}
          onChange={(e) => {
            this.setState({ message: e.target.value });
          }}
          value={this.state.message}
        />
        <div className={styles.categoryContainer}>
          <div className={styles.categoryText}>
            {`${LangManager.getLang(LangKeys.FEEDBACK_TYPE)}`}
          </div>
          <Select
            value={this.state.category}
            onChange={(e) => {
              this.setState({ category: e.target.value as FeedbackCategory });
            }}
          >
            {Object.keys(categories).map((cat) => {
              return (
                <MenuItem value={cat} key={cat}>
                  {categories[cat]}
                </MenuItem>
              );
            })}
            <MenuItem></MenuItem>
          </Select>
        </div>
        <div className={styles.buttonContainer}>
          <Button
            variant="contained"
            color={"primary"}
            onClick={this.submit}
            fullWidth
          >
            {LangManager.getLang(LangKeys.FEEDBACK_SUBMIT)}
          </Button>
        </div>
      </>
    );
  };
  close = () => {
    this.props.clearSubmitFeedback();
    this.props.closePopup();
  };
  render() {
    const loading = this.props.submitFeedbackState.loading;
    return (
      <Dialog
        open={this.props.feedbackPopup.visible}
        onClose={this.close}
        fullScreen
      >
        <div className={styles.position} onClick={this.close}>
          <div
            className={styles.container}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <div className={classNames(sharedStyles.heading3, styles.header)}>
              {LangManager.getLang(LangKeys.FEEDBACK_HEADER)}
            </div>
            <div className={classNames(styles.subHeading, sharedStyles.text)}>
              Write your message here or email{" "}
              <a href={"mailto: matthew@citypainter.io"}>
                matthew@citypainter.io
              </a>
            </div>
            {loading ? this.renderSending() : this.renderContent()}
          </div>
        </div>
      </Dialog>
    );
  }
}

function mapStateToProps(state: ReduxStateType): StateProps {
  return {
    isLoggedIn: isLoggedInSelector(state),
    feedbackPopup: feedbackPopupSelector(state),
    submitFeedbackState: submitFeedbackStateSelector(state),
    submitFeedbackResponse: submitFeedbackSelector(state),
  };
}

function mapDispatchToProps(dispatch): DispatchProps {
  return {
    submitFeedbackPublic: (message, category) =>
      dispatch(loadSubmitFeedbackPublic(message, category)),
    closePopup: () => dispatch(hidePopup({ popupType: PopupTypes.FEEDBACK })),
    submitFeedback: (message, category) =>
      dispatch(loadSubmitFeedback(message, category)),
    clearSubmitFeedback: () => dispatch(clearSubmitFeedback()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FeedbackPopup);
