import {
  useAddEventMutation,
  useReviewPopupCanShowQuery,
  useReviewPopupLogAttemptMutation,
} from "../../state/api/ApiSlice";
import { useEffect } from "react";
import { isApp } from "../../platform/PlatformManager";
import { RateApp } from "capacitor-rate-app";

export default function useAttemptReviewPopupOnUnmount() {
  const { data: reviewPopupResponse } = useReviewPopupCanShowQuery({});
  const [logReviewPopupAttempt] = useReviewPopupLogAttemptMutation();
  const [addEvent] = useAddEventMutation();
  /**
   * Show the review popup when leaving the activity screen
   * Only show if the user has a premium subscription and the last popup was shown more than 3 months ago
   * This is currently determined on the server and retrieve using the ReviewCanShow endpoint. Conditions will change in the future
   */
  useEffect(() => {
    return () => {
      if (reviewPopupResponse?.success && reviewPopupResponse.canShow) {
        isApp() &&
          RateApp.requestReview().then(() => {
            // TODO should log review attempt call be moved to here?
            console.log("Log review attempt");
          });
        logReviewPopupAttempt({});
        addEvent("attempt-review-popup");
      }
    };
  }, []);
}
