"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.API_CLEAR_DATA_SUFFIX = exports.API_CLEAR_ERROR_SUFFIX = exports.API_FAIL_SUFFIX = exports.API_SUCCESS_SUFFIX = void 0;
/**
 * Created by matthewjamieson on 25/04/2017.
 */
// Action suffixes for Api
exports.API_SUCCESS_SUFFIX = "_SUCCESS";
exports.API_FAIL_SUFFIX = "_FAIL";
exports.API_CLEAR_ERROR_SUFFIX = "_CLEAR_ERROR";
exports.API_CLEAR_DATA_SUFFIX = "_CLEAR_DATA";
