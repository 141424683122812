import React, { Component, CSSProperties } from "react";
import styles from "./GarminButton.module.css";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { ReduxStateType } from "../../state/ReduxStateType";
import sharedStyles from "../../styles/shared.module.css";
import { Config } from "framework";
type DispatchProps = {};
type StateProps = {};
type OtherProps = { style?: CSSProperties } & RouteComponentProps;
type PropsType = StateProps & DispatchProps & OtherProps;

type StateType = {};

class GarminButton extends Component<PropsType, StateType> {
  loginWithGarmin = () => {
    // Note that we must use www.citypainter.io instead of citypainter.io because android/iOS will redirect citypainter.io straight back into the app and login will fail
    window.open("https://www.citypainter.io/garmin/auth", "_blank");
  };

  render() {
    return (
      <div className={styles.buttonContainer} style={this.props.style}>
        <a
          className={styles.garminButton}
          href=""
          onClick={this.loginWithGarmin}
        >
          <span className={sharedStyles.heading4}>Connect with</span>
          <div className={styles.garminImage}></div>
        </a>
      </div>
    );
  }
}

function mapStateToProps(state: ReduxStateType): StateProps {
  return {};
}

function mapDispatchToProps(dispatch): DispatchProps {
  return {};
}

export default withRouter(GarminButton);
