import React, { useCallback, useEffect } from "react";
import Header from "../components/Header";
import Content from "../../components/content/Content";
import { useParams } from "react-router-dom";
import ActivityMap from "./components/map/ActivityMap";
import WaitForData from "src/components/WaitForData";
import sharedStyles from "../../styles/shared.module.css";
import classNames from "classnames";
import styles from "./ActivityDetails.module.css";
import Stat from "../../components/stat/Stat";
import { getDistance } from "../../data/utils/formating/DistanceFormating";
import { getDuration } from "../../data/utils/formating/DateTimeFormatting";
import moment from "moment";
import { LandmarkType, NeighbourhoodSimple } from "cp-server";
import Landmark from "../components/landmark/Landmark";
import { Helmet } from "react-helmet";
import Neighbourhood from "../components/neighbourhood/Neighbourhood";
import SmallHeading from "../../components/headings/SmallHeading";
import {
  useActivityDetailsQuery,
  useGetSettingsQuery,
  useLazyGetActivityPercentCoveredQuery,
  useLazyUserQuery,
  useSetSettingsMutation,
  useUserQuery,
} from "../../state/api/ApiSlice";
import { IonContent } from "@ionic/react";
import LandmarkUtils from "../../data/utils/landmarks/LandmarkUtils";
import NeighbourhoodUtils from "../../data/utils/neighbourhoods/NeighbourhoodUtils";
import DisplayIf from "../../components/display-if/DisplayIf";
import { PopupTypes, showPopup } from "../../state/popups/PopupsActions";
import { useDispatch } from "react-redux";
import { IconButton, Menu, MenuItem } from "@mui/material";
import { ReactComponent as MoreVert } from "../../assets/more-vert-black.svg";
import { ActivityType } from "cp-types";
import { useTranslation } from "react-i18next";
import useAttemptReviewPopupOnUnmount from "../../data/hooks/useAttemptReviewPopupOnUnmount";
function ActivityDetails() {
  const dispatch = useDispatch();
  const { data: userResponse } = useUserQuery({});
  const { t } = useTranslation();
  const { id: activityId } = useParams<{ id: string }>();
  const { data: settingsResponse } = useGetSettingsQuery();
  const [setSettings] = useSetSettingsMutation();

  useEffect(() => {
    if (settingsResponse?.success && userResponse?.success) {
      const lastPopupAttempt =
        settingsResponse.settings.last_activity_description_popup_attempt;
      const vendor = userResponse.vendor;
      const permissions = userResponse.vendor_permissions;

      // Show the strava activity permissions popup if there wasn't a last attempt or the user is missing the write permission
      if (
        vendor === "strava" &&
        (lastPopupAttempt === null || !permissions?.includes("activity:write"))
      ) {
        dispatch(
          showPopup({
            popupType: PopupTypes.ACTIVITY_DESCRIPTION_PERMISSIONS,
          })
        );
        setSettings({
          settings: {
            last_activity_description_popup_attempt: new Date().toISOString(),
          },
        });
      }
    }
  }, []);

  useAttemptReviewPopupOnUnmount();

  const {
    data: activityDetails,
    isLoading: activityDetailsLoading,
  } = useActivityDetailsQuery({ activity_id: activityId });

  const [
    loadPercentsCovered,
    { data: percentResponse },
  ] = useLazyGetActivityPercentCoveredQuery();
  useEffect(() => {
    if (activityDetails?.success) {
      if (!activityDetails.activity.percent_new) {
        loadPercentsCovered({ activities: [activityDetails.activity.id] });
      }
    }
  }, [activityDetails]);
  const activitiesPercentCovered = percentResponse?.success
    ? percentResponse
    : {};

  function renderNeighbourhoods(neighbourhoods: Array<NeighbourhoodSimple>) {
    // Remove nulls (Can happen due to the way the server processes neighbourhoods) // TODO
    if (!neighbourhoods.length) {
      return null;
    }
    return (
      <>
        <SmallHeading left={t("activityDetails.neighbourhoods")} />
        <div className={styles.neighbourhoods}>
          {neighbourhoods.map((n) => {
            return <Neighbourhood neighbourhood={n} />;
          })}
        </div>
      </>
    );
  }
  function renderLandmarks(landmarks: Array<LandmarkType>) {
    return (
      <>
        <SmallHeading left={t("activityDetails.landmarks")} />
        <div className={styles.landmarksContainer}>
          {landmarks.map((landmark) => {
            const first = landmark.visits === "1";
            return (
              <div className={styles.landmarkContainer}>
                <Landmark landmark={landmark} />
                <span
                  className={
                    first ? styles.detailsTextHighlighted : styles.detailsText
                  }
                >
                  {` - ${
                    first
                      ? t("activityDetails.firstVisit")
                      : `${landmark.visits} ${t(
                          "activityDetails.previousVisits"
                        )}`
                  }`}
                </span>
              </div>
            );
          })}
        </div>
      </>
    );
  }

  function renderStats() {
    if (activityDetails?.success) {
      const activity = activityDetails.activity;
      const duration = getDuration(activity.moving_time);
      const percentIsLoading =
        !activity.percent_new && !activitiesPercentCovered[activity.id];
      const percentNew: string = parseFloat(
        activity.percent_new || activitiesPercentCovered[activity.id] || "0"
      ).toFixed(1);
      const percentOld: string = (100 - parseFloat(percentNew)).toFixed(1);
      const landmarksCount = activityDetails?.landmarks?.length;
      return (
        <div className={styles.statsContainer}>
          <Stat
            label={t("activity.newGround")}
            value={`${percentNew}%`}
            valueColor="var(--purple)"
            loading={percentIsLoading}
          />
          <Stat label={t("activity.landmarks")} value={`${landmarksCount}`} />
          <Stat
            label={t("activity.distance")}
            value={getDistance(activity.distance)}
          />
          <Stat label={t("activity.time")} value={duration} />
          <Stat
            label={t("activity.revisited")}
            value={`${percentOld}%`}
            valueColor="var(--orange-2)"
            loading={percentIsLoading}
          />
        </div>
      );
    }
  }

  function renderContent() {
    if (
      activityDetails?.success &&
      activityDetails.activity.id === activityId
    ) {
      const date = moment(activityDetails.activity.start_time).format(
        "@ HH:mm MMMM Do YYYY"
      );
      const longName = (activityDetails.activity.name || "").length > 80;
      return (
        <div className={styles.container}>
          <Content style={{ paddingRight: 0, paddingLeft: 0 }}>
            <ActivityMap
              activity={activityDetails.activity}
              areaId={activityDetails.activityCities[0]?.area_id}
              boundingBox={activityDetails.activityCities[0]?.bounding_box}
              landmarks={activityDetails.landmarks || []}
            />
          </Content>
          <Content>
            <div
              className={classNames(sharedStyles.heading4, styles.subHeader)}
            >
              {date}
            </div>
            {renderStats()}
            {renderLandmarks(
              LandmarkUtils.removeDuplicates(activityDetails.landmarks)
            )}
            {renderNeighbourhoods(
              NeighbourhoodUtils.removeDuplicates(
                activityDetails.neighbourhoods
              )
            )}
          </Content>
        </div>
      );
    }
    return null;
  }

  function getHeaderTitle() {
    if (
      activityDetails?.success &&
      activityDetails.activity.id === activityId
    ) {
      return activityDetails.activity.name;
    }
    return "Activity";
  }
  return (
    <>
      <Helmet>
        <title>City Painter - Activity Details</title>
      </Helmet>

      <Header
        title={getHeaderTitle()}
        rightContent={
          activityDetails?.success ? (
            <ActivityMenu
              activity={activityDetails?.success && activityDetails.activity}
            />
          ) : undefined
        }
      />
      <IonContent>
        <WaitForData isLoading={activityDetailsLoading}>
          {renderContent()}
        </WaitForData>
      </IonContent>
    </>
  );
}

export default React.memo(ActivityDetails);

function ActivityMenu({ activity }: { activity: ActivityType }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen(!open);
  };
  const showConfirmDeletePopup = useCallback(() => {
    setOpen(false);
    dispatch(
      showPopup({
        popupType: PopupTypes.DELETE_CONFIRM,
        data: {
          type: "ACTIVITY",
          activity_id: activity.id,
          name: activity.name,
        },
      })
    );
  }, [activity, open]);

  const stravaLink = `https://www.strava.com/activities/${activity.vendor_activity_id}`;

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        size="large"
      >
        <MoreVert />
      </IconButton>
      <Menu
        id="long-menu"
        open={open}
        onClose={handleClick}
        anchorEl={anchorEl}
      >
        <DisplayIf condition={activity.vendor === "strava"}>
          <MenuItem className={sharedStyles.text}>
            <a
              href={stravaLink}
              style={{ textAlign: "right", color: "#FC4C02" }}
            >
              {t("activityDetails.strava")}
            </a>
          </MenuItem>
        </DisplayIf>
        <MenuItem
          key="delete"
          onClick={showConfirmDeletePopup}
          className={classNames(sharedStyles.text, styles.menuItem)}
        >
          {t("activityDetails.delete")}
        </MenuItem>
      </Menu>
    </div>
  );
}
