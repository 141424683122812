import React from "react";
import styles from "./City.module.css";
import sharedStyles from "../../../styles/shared.module.css";
import { CityWithBBSimple } from "cp-server";
import classNames from "classnames";
import Stat from "../../../components/stat/Stat";
import LangManager from "../../../data/languages/LangManager";
import LangKeys from "../../../data/languages/LangKeys";
import { getDistance } from "../../../data/utils/formating/DistanceFormating";
import { Config } from "framework";
import { Link } from "react-router-dom";

type Props = { city: CityWithBBSimple };

function City(props: Props) {
  const city = props.city;
  return (
    <Link
      className={styles.container}
      to={`/tabs/explore/city/${props.city.area_id}`}
    >
      <div className={styles.inner}>
        <div
          className={styles.thumbnail}
          style={{
            backgroundImage: `url(${Config.getConfigItem(
              "domain"
            )}/map_thumbs/${city.area_id}.png)`,
          }}
        />
        <div className={classNames(sharedStyles.heading2, styles.cityName)}>
          {city.name}
        </div>
        <div className={styles.statsContainer}>
          <Stat
            label={LangManager.getLang(LangKeys.CITIES_TOTAL_ROADS)}
            value={`${getDistance(city.total_roads_distance, 0)}`}
          />
          <Stat
            label={LangManager.getLang(LangKeys.CITIES_TOTAL_OFF_ROADS)}
            value={`${getDistance(city.total_off_roads_distance, 0)}`}
          />
          <Stat
            label={LangManager.getLang(LangKeys.CITIES_LANDMARKS)}
            value={`${city.total_landmarks || 0}`}
          />
          <Stat
            label={LangManager.getLang(LangKeys.CITIES_NEIGHBOURHOODS_TOTAL)}
            value={`${city.total_neighbourhoods || 0}`}
          />
        </div>
      </div>
    </Link>
  );
}

export default React.memo(City);
