/**
 * Created by matthewjamieson on 04/01/2021.
 */

export function getDistance(meters: string | number, dp?: number): string {
  if (!meters) {
    return "0 km";
  }
  return `${(parseFloat(`${meters}`) / 1000).toFixed(dp ?? 2)} km`;
}
