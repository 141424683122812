"use strict";
/**
 * Created by matthewjamieson on 06/04/2017.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.StatusType = void 0;
var StatusType;
(function (StatusType) {
    StatusType["OK"] = "OK";
    StatusType["ERROR"] = "ERROR";
    StatusType["NONE"] = "NONE";
})(StatusType || (exports.StatusType = StatusType = {}));
