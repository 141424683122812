import React, { Component } from "react";
import styles from "../../dashboard/components/activity/Activity.module.css";
import moment from "moment";
import LangManager from "../../../data/languages/LangManager";
import LangKeys from "../../../data/languages/LangKeys";
import classNames from "classnames";
import sharedStyles from "../../../styles/shared.module.css";
import type { ActivityType, LandmarkType } from "cp-server";
import { getDuration } from "../../../data/utils/formating/DateTimeFormatting";
import DemoLandmark from "./DemoLandmark";
import Stat from "../../../components/stat/Stat";
import { getDistance } from "../../../data/utils/formating/DistanceFormating";

type OtherProps = { data: ActivityType };
type PropsType = OtherProps;

export default class DemoActivity extends Component<PropsType> {
  renderLandmarks(landmarks: Array<LandmarkType>) {
    // Remove nulls (Can happen due to the way the server processes landmarks) // TODO
    const filteredLandmarks = landmarks.filter((l) => l);
    if (!filteredLandmarks.length) {
      return null;
    }
    const first5 = filteredLandmarks.slice(0, 5);
    return (
      <div>
        {first5.map((l) => {
          return <DemoLandmark landmark={l} />;
        })}
        {first5.length !== filteredLandmarks.length ? (
          <div className={styles.viewAll}>
            {LangManager.getLang(LangKeys.ACTIVITY_VIEW_ALL_LANDMARKS)}
          </div>
        ) : null}
      </div>
    );
  }

  render() {
    const activity = this.props.data;
    const date = moment(activity.start_time).format("@ HH:mm MMMM Do YYYY");
    const duration = getDuration(activity.moving_time);
    const percentIsLoading = !activity.percent_new;
    const percentNew: string = parseFloat(activity.percent_new).toFixed(1);
    const percentOld: string = (100 - parseFloat(percentNew)).toFixed(1);
    return (
      <div
        className={styles.container}
        style={{ boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.16)" }}
      >
        <div className={styles.inner}>
          <div
            className={styles.image}
            style={{
              backgroundImage: `url(${require("../../../assets/landing/activity.png")})`,
            }}
          />
          <div className={styles.detailsContainer}>
            <div className={styles.title}>{activity.name}</div>
            <div className={styles.date}>{date}</div>
            <div className={styles.details}>
              <div className={styles.statsContainer}>
                <Stat
                  label={LangManager.getLang(LangKeys.ACTIVITY_NEW_GROUND)}
                  value={`${percentNew}%`}
                  valueColor="var(--purple)"
                  loading={percentIsLoading}
                />
                <Stat
                  label={"Distance"}
                  value={getDistance(activity.distance)}
                />
                <Stat
                  label={LangManager.getLang(LangKeys.ACTIVITY_REVISITED)}
                  value={`${percentOld}%`}
                  valueColor="var(--orange-2)"
                  loading={percentIsLoading}
                />
                <Stat label={"Time"} value={duration} />
              </div>
            </div>
            <div className={styles.divider} />
          </div>
          <div className={styles.visitsContainer}>
            <div
              className={classNames(sharedStyles.heading4, styles.visitedText)}
            >
              {LangManager.getLang(LangKeys.ACTIVITIES_LANDMARKS_VISITED)}
            </div>
            {this.renderLandmarks(activity.landmarks || [])}
          </div>
        </div>
      </div>
    );
  }
}
