/**
 * Created by matthewjamieson on 14/01/2021.
 */
import { ImageManager } from "framework";
import ImageKeys from "./ImageKeys";
import StravaIcon from "../../assets/strava.svg";
import SettingsIcon from "../../assets/settings.svg";
import FullScreenIcon from "../../assets/full_screen.svg";
import FullScreenExit from "../../assets/full_screen_exit.svg";
const images = {
  [ImageKeys.ONBOARDING_BG]: require("../../assets/onboarding_bg.jpg"),
  [ImageKeys.STRAVA]: StravaIcon,
  [ImageKeys.LOGO]: require("../../assets/cp_logo.png"),
  [ImageKeys.LANDMARKS_VIEWPOINT]: require("../../assets/viewpoint.png"),
  [ImageKeys.LANDMARKS_MONUMENT]: require("../../assets/monument.png"),
  [ImageKeys.LANDMARKS_STATUE]: require("../../assets/statue.png"),
  [ImageKeys.LANDMARKS_PEAK]: require("../../assets/peak.png"),
  [ImageKeys.LANDMARKS_ARTWORK]: require("../../assets/artwork.png"),
  [ImageKeys.LANDMARKS_LIGHTHOUSE]: require("../../assets/lighthouse.png"),
  [ImageKeys.LANDMARKS_CASTLE]: require("../../assets/castle.png"),
  [ImageKeys.LANDMARKS_RUINS]: require("../../assets/ruins.png"),
  [ImageKeys.LANDMARKS_DOVECOTE]: require("../../assets/dovecote.png"),
  [ImageKeys.LANDMARK_MAP_MONUMENT]: require("../../assets/monument_map.png"),
  [ImageKeys.LANDMARK_MAP_VIEWPOINT]: require("../../assets/viewpoint_map.png"),
  [ImageKeys.LANDMARK_MAP_PEAK]: require("../../assets/peak_map.png"),
  [ImageKeys.LANDMARK_MAP_ARTWORK]: require("../../assets/artwork_map.png"),
  [ImageKeys.LANDMARK_MAP_LIGHTHOUSE]: require("../../assets/lighthouse_map.png"),
  [ImageKeys.LANDMARK_MAP_CASTLE]: require("../../assets/castle_map.png"),
  [ImageKeys.LANDMARK_MAP_RUINS]: require("../../assets/ruins_map.png"),
  [ImageKeys.LANDMARK_MAP_DOVECOTE]: require("../../assets/dovecote_map.png"),
  [ImageKeys.LANDMARK_MAP_MONUMENT_VISITED]: require("../../assets/monument_map_visited.png"),
  [ImageKeys.LANDMARK_MAP_VIEWPOINT_VISITED]: require("../../assets/viewpoint_map_visited.png"),
  [ImageKeys.LANDMARK_MAP_PEAK_VISITED]: require("../../assets/peak_map_visited.png"),
  [ImageKeys.LANDMARK_MAP_ARTWORK_VISITED]: require("../../assets/artwork_map_visited.png"),
  [ImageKeys.LANDMARK_MAP_LIGHTHOUSE_VISITED]: require("../../assets/lighthouse_map_visited.png"),
  [ImageKeys.LANDMARK_MAP_CASTLE_VISITED]: require("../../assets/castle_map_visited.png"),
  [ImageKeys.LANDMARK_MAP_RUINS_VISITED]: require("../../assets/ruins_map_visited.png"),
  [ImageKeys.LANDMARK_MAP_DOVECOTE_VISITED]: require("../../assets/dovecote_map_visited.png"),
  [ImageKeys.SETTINGS_ICON]: SettingsIcon,
  [ImageKeys.TICK_ICON]: require("../../assets/tick.png"),
  [ImageKeys.FULL_SCREEN_ICON]: FullScreenIcon,
  [ImageKeys.FULL_SCREEN_EXIT_ICON]: FullScreenExit,
  [ImageKeys.PLUS_ICON]: require("../../assets/plus.png"),
  [ImageKeys.NEIGHBOURHOOD_ICON]: require("../../assets/neighbourhood.png"),
};
class CPImageManager extends ImageManager {
  constructor() {
    super(images);
  }
}

export default new CPImageManager();
