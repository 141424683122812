import React from "react";
import SmallHeading from "../../../../components/headings/SmallHeading";
import styles from "./DashboardLeaderboards.module.css";
import { Link } from "react-router-dom";
import { TimePeriods } from "cp-server";
import LangManager from "../../../../data/languages/LangManager";
import LangKeys from "../../../../data/languages/LangKeys";
import AccountTierFunctions from "../../../../data/account/AccountTierFunctions";
import DashboardLeaderboardLinks from "./DashboardLeaderboardLinks";

// Maybe component should be renamed because it is also used on the neighbourhood details page
function DashboardLeaderboards(params: {
  id: string; // This is an area_id or neighbourhood_id. Should 'match' the type provided but there are no checks for this
  type: "area" | "neighbourhood";
}) {
  return (
    <div>
      <SmallHeading left={LangManager.getLang(LangKeys.LEADERBOARDS_HEADER)} />
      <div className={styles.container}>
        <DashboardLeaderboardLinks {...params} />
      </div>
    </div>
  );
}

export default React.memo(DashboardLeaderboards);
