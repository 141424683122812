//Library Imports
import React from "react";
import styles from "./PrivacyPolicy.module.css";
import sharedStyles from "../../styles/shared.module.css";
import classNames from "classnames";
import Content from "../../components/content/Content";
import Header from "../components/Header";
import { Helmet } from "react-helmet";
import { IonContent } from "@ionic/react";

const PrivacyPolicy: React.FunctionComponent = () => {
  return (
    <>
      <Helmet>
        <title>City Painter - Privacy Policy</title>
      </Helmet>
      <Header showAuth logoOnly title={"Privacy Policy"} />
      <IonContent>
        <div className={styles.container}>
          <div className={classNames(sharedStyles.heading1, styles.header)}>
            Privacy Policy
          </div>
          <Content>
            <div
              className={classNames(
                sharedStyles.heading4,
                styles.contentHeader
              )}
            >
              Information we may collect about you
            </div>
            <div
              className={classNames(sharedStyles.paragraph, styles.contentText)}
            >
              {" "}
              We collect and store personal data that includes, but is not
              limited to: full name and email address, activities
            </div>
            <div
              className={classNames(
                sharedStyles.heading4,
                styles.contentHeader
              )}
            >
              How do we use your activity data
            </div>
            <div
              className={classNames(sharedStyles.paragraph, styles.contentText)}
            >
              Activity data is used to provide core features of the site
              including:
              <ul>
                <li>
                  To create personalised maps for each city or area that you
                  have an activity within that is available on the website.
                  These maps are only visible to you the user.
                </li>
                <li>
                  Analyse which landmarks and points of interest the user has
                  visited within cities or areas included on the site
                </li>
              </ul>
              No activity data is ever shared with third parties
            </div>
            <div
              className={classNames(
                sharedStyles.heading4,
                styles.contentHeader
              )}
            >
              Information we share about you
            </div>
            <div
              className={classNames(sharedStyles.paragraph, styles.contentText)}
            >
              We do not share any personal information with third parties
            </div>
            <div
              className={classNames(
                sharedStyles.heading4,
                styles.contentHeader
              )}
            >
              IP Address and cookies
            </div>
            <div
              className={classNames(sharedStyles.paragraph, styles.contentText)}
            >
              We may collect non-personal identification information about Users
              when they interact with our Site from their computer or mobile
              device. Non-personal identification information may include the IP
              address, browser name, type of device and other technical
              information about the Users means of connection to our Site, such
              as the operating system and the Internet service provider. Our
              Site may use ‘cookies’ to enhance the Users experience. User’s web
              browser places cookies on their hard drive for record-keeping
              purposes and sometimes to track information about them. Users may
              choose to set their web browser to refuse cookies, or to alert you
              when cookies are being sent. If they do so, Users should note that
              some parts of the Site may not function properly.
            </div>
            <div
              className={classNames(
                sharedStyles.heading4,
                styles.contentHeader
              )}
            >
              Retention of your Information
            </div>
            <div
              className={classNames(sharedStyles.paragraph, styles.contentText)}
            >
              We take appropriate measures to ensure that the information
              collected from our Users is kept only for so long as is necessary
              for the purposes in which it was collected. You are able to update
              your information anytime in your Account settings to ensure that
              the information we hold for you is accurate.
              <br /> <br />
              We protect your data by adopting appropriate collection, storage
              and processing practices as well as high security measures to
              protect against unauthorized access, alteration, disclosure or
              destruction of your personal information.
              <br /> <br />
              Sensitive and private data exchanged between the Site and its
              Users happens over a 2,048 bit SSL secured communication channel
              and is encrypted and protected with digital signatures
            </div>

            <div
              className={classNames(
                sharedStyles.heading4,
                styles.contentHeader
              )}
            >
              How we use collected information
            </div>
            <div
              className={classNames(sharedStyles.paragraph, styles.contentText)}
            >
              We may collect and use Users personal information for the
              following purposes:
              <ul>
                <li>
                  To improve customer service. Information you provide helps us
                  respond to your customer service requests and support needs
                  more efficiently.
                </li>
                <li>
                  To personalize user experience. We may use information in the
                  aggregate to understand how our Users (as a group) use the
                  services and resources provided on our Site.
                </li>
                <li>To improve our site</li>
              </ul>
              We may use feedback you provide to improve our products and
              services.
              <br />
              <br />
              <ul>
                <li>
                  To run a promotion, contest, survey or other Site feature
                </li>
                <li>
                  To send Users information they have agreed to receive about
                  topics we think will be of interest to them.
                </li>
                <li>To send periodic emails</li>
              </ul>
              We may use the email address to send the User information and
              updates pertaining to their account status and updates to
              policies. It may also be used to respond to their inquiries,
              questions, and/or other requests.
            </div>
            <div
              className={classNames(
                sharedStyles.heading4,
                styles.contentHeader
              )}
            >
              Google Analytics
            </div>
            <div
              className={classNames(sharedStyles.paragraph, styles.contentText)}
            >
              This website uses Google Analytics through Firebase, web analytics
              services provided by Google, Inc. that uses cookies to help
              website operators analyse how Users use the Site. The information
              generated by the cookie about your use of the Site (including your
              IP address) will be transmitted to, and stored by Google on
              servers in the United States and may be cached and stored
              elsewhere. Google will use this information for the purpose of
              evaluating your use of the website, and compiling reports on
              website activity and internet usage. Google will not associate
              your IP address with any other data held by Google. By using this
              Site, you consent to the processing of data about you by Google in
              the manner and the purpose set out as above.
            </div>
            <div
              className={classNames(
                sharedStyles.heading4,
                styles.contentHeader
              )}
            >
              Sharing Tools
            </div>
            <div
              className={classNames(sharedStyles.paragraph, styles.contentText)}
            >
              This Site uses sharing tools, allowing you to share content
              through social networks such as Facebook and Twitter. When you use
              one of these tools, the social networking site may place a cookie
              on your computer or mobile phone. This would be a third-party
              cookie set by the social networking site, and further information
              on this must be checked on the relevant social networking site’s
              cookie policy over which we have no control.
            </div>
            <div
              className={classNames(
                sharedStyles.heading4,
                styles.contentHeader
              )}
            >
              Disclosure of your personal information
            </div>
            <div
              className={classNames(
                sharedStyles.heading4,
                styles.contentHeader
              )}
            >
              Your rights under GDPR
            </div>

            <div
              className={classNames(
                sharedStyles.heading4,
                styles.contentHeader
              )}
            >
              Right of access
            </div>
            <div
              className={classNames(sharedStyles.paragraph, styles.contentText)}
            >
              The Data Protection Legislation gives Users the right to access
              information held about them. If you wish to exercise this right,
              please do so in writing to matthew@citypainter.io
              <br /> <br />
              Any access request shall be subject to you providing acceptable
              proof of identification.
              <br /> <br />
              If we are processing your personal data, we will provide you with
              a copy of that personal data.
            </div>

            <div
              className={classNames(
                sharedStyles.heading4,
                styles.contentHeader
              )}
            >
              Right of rectification
            </div>
            <div
              className={classNames(sharedStyles.paragraph, styles.contentText)}
            >
              You are entitled to have your personal data rectified if it is
              incorrect or incomplete. Please help us help you by keeping your
              information up to date and letting us know if there are any errors
              we hold about you. You can update your information under Your
              Account, or you can exercise this request in writing to
              matthew@citypainter.io
            </div>

            <div
              className={classNames(
                sharedStyles.heading4,
                styles.contentHeader
              )}
            >
              Right to erasure (or “the right to be forgotten”)
            </div>
            <div
              className={classNames(sharedStyles.paragraph, styles.contentText)}
            >
              You have the right to have your personal data erased and to
              prevent processing in specific circumstances. If you wish to
              exercise this request, please do so in writing to
              matthew@citypainter.io
            </div>

            <div
              className={classNames(
                sharedStyles.heading4,
                styles.contentHeader
              )}
            >
              Right to restrict processing
            </div>
            <div
              className={classNames(sharedStyles.paragraph, styles.contentText)}
            >
              In some circumstances, you may have the right to restrict us from
              processing your personal data, (particularly when the accuracy of
              the information is contested by you), allowing a period to verify
              the accuracy of the data.{" "}
            </div>

            <div
              className={classNames(
                sharedStyles.heading4,
                styles.contentHeader
              )}
            >
              Right to withdraw your consent
            </div>
            <div
              className={classNames(sharedStyles.paragraph, styles.contentText)}
            >
              In some circumstances, we must have your consent before we contact
              you. You have the right to withdraw your consent. If you wish to
              exercise this request, please do so in writing to
              matthew@citypainter.io
            </div>

            <div
              className={classNames(
                sharedStyles.heading4,
                styles.contentHeader
              )}
            >
              Right to object
            </div>
            <div
              className={classNames(sharedStyles.paragraph, styles.contentText)}
            >
              In certain circumstances you have the right to object to us
              processing your information. If you wish to exercise this request,
              please do so in writing to matthew@citypainter.io. Please note
              that depending on the circumstance you object to, our services may
              no longer be available to you and as such your account will be
              closed.
            </div>

            <div
              className={classNames(
                sharedStyles.heading4,
                styles.contentHeader
              )}
            >
              Rights related to automated decision making and profiling
            </div>
            <div
              className={classNames(sharedStyles.paragraph, styles.contentText)}
            >
              You have the right not to be subject to a decision based solely on
              automated processing, including profiling, which produces legal
              effects concerning you or similarly significantly affect you, as
              long as the decision is not necessary for entering into, or the
              performance of, a contract between you and us; or is not based on
              your explicit consent.
            </div>

            <div
              className={classNames(
                sharedStyles.heading4,
                styles.contentHeader
              )}
            >
              Changes to this privacy policy
            </div>
            <div
              className={classNames(sharedStyles.paragraph, styles.contentText)}
            >
              Mapped Space LTD can update this privacy policy at any time. When
              we do, we will post a notification on the main page of our Site,
              revise the updated date at the bottom of this page and send you an
              email to inform you of an update. We encourage Users to frequently
              check this page to stay informed about how we are helping to
              protect the personal information we collect.{" "}
            </div>

            <div
              className={classNames(
                sharedStyles.heading4,
                styles.contentHeader
              )}
            >
              Your acceptance of these terms
            </div>
            <div
              className={classNames(sharedStyles.paragraph, styles.contentText)}
            >
              By positively confirming the acceptance of our Privacy Policy upon
              registration, you signify your acceptance of the terms of this
              Site. Your continued use of the Site following the posting of
              changes to this policy will be deemed your acceptance of any
              changes.{" "}
            </div>

            <div
              className={classNames(
                sharedStyles.heading4,
                styles.contentHeader
              )}
            >
              Contacting us
            </div>
            <div
              className={classNames(sharedStyles.paragraph, styles.contentText)}
            >
              If you have any questions about this Privacy Policy, the practices
              of this site, or your dealings with this site, please contact us
              at matthew@citypainter.io
            </div>
          </Content>
        </div>
      </IonContent>
    </>
  );
};

export default React.memo(PrivacyPolicy);
