import React, { useCallback, useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import CloseButton from "../components/CloseButton";
import styles from "./NamePopup.module.css";
import sharedStyles from "../../styles/shared.module.css";
import Button from "@mui/material/Button";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { namePopupSelector } from "../../state/popups/PopupsSelectors";
import { hidePopup, PopupTypes } from "../../state/popups/PopupsActions";
import { useTranslation } from "react-i18next";
import { TextField } from "@mui/material";
import { useUpdateUserMutation, useUserQuery } from "../../state/api/ApiSlice";
import { showErrorPopup } from "../../state/popups/PopupDispatcher";

export default function NamePopup() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const popup = useSelector(namePopupSelector);
  const { data: userResponse } = useUserQuery({});
  const user = userResponse?.success ? userResponse.user : undefined;
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  useEffect(() => {
    if (user) {
      setFirstName(user?.first_name as string);
      setLastName(user?.last_name as string);
    }
  }, [userResponse, user]);

  const hide = useCallback(() => {
    dispatch(hidePopup({ popupType: PopupTypes.NAME }));
  }, []);
  const [updateUser, { data: updateUserResponse }] = useUpdateUserMutation();

  const onConfirmClick = () => {
    if (!firstName || !lastName) {
      showErrorPopup(t("popups.namePopup.fieldError"));
    }
    updateUser({ first_name: firstName, last_name: lastName });
  };

  useEffect(() => {
    updateUserResponse?.success && hide();
  }, [updateUserResponse, hide]);

  return (
    <Dialog open={popup.visible} onClose={hide}>
      <CloseButton hidePopup={hide} />
      <div className={styles.container}>
        <div className={classNames(sharedStyles.heading6, styles.message)}>
          {t(
            firstName || lastName
              ? "popups.namePopup.updateMessage"
              : "popups.namePopup.message"
          )}
        </div>
        <TextField
          variant={"outlined"}
          placeholder={t("popups.namePopup.firstName")}
          label={t("popups.namePopup.firstName")}
          value={firstName}
          onChange={(e) => {
            setFirstName(e.target.value);
          }}
          style={{ marginBottom: 16, marginTop: 16 }}
        />
        <TextField
          variant={"outlined"}
          placeholder={t("popups.namePopup.lastName")}
          label={t("popups.namePopup.lastName")}
          value={lastName}
          onChange={(e) => {
            setLastName(e.target.value);
          }}
        />

        <div className={styles.buttonContainer}>
          <Button onClick={hide}>{t("popups.namePopup.cancel")}</Button>
          <Button variant="contained" color="primary" onClick={onConfirmClick}>
            {t("popups.namePopup.confirm")}
          </Button>
        </div>
      </div>
    </Dialog>
  );
}
