import React from "react";
import { GroupType } from "cp-server";
import Stat from "../../../components/stat/Stat";
import styles from "./GroupCard.module.css";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";
import { IonButton } from "@ionic/react";
import DisplayIf from "../../../components/display-if/DisplayIf";
import { useGroupRemoveMemberMutation } from "../../../state/api/ApiSlice";
import { PopupTypes, showPopup } from "../../../state/popups/PopupsActions";
import { useDispatch } from "react-redux";
export default function GroupCard({
  group,
  edit = false,
}: {
  group: GroupType;
  edit?: boolean;
}) {
  const dispatch = useDispatch();
  function leaveGroupConfirm() {
    dispatch(
      showPopup({
        popupType: PopupTypes.DELETE_CONFIRM,
        data: { type: "GROUP", group_id: group.group_id, name: group.name },
      })
    );
  }

  return (
    <Link
      className={styles.container}
      to={`/tabs/home/group/${group.group_id}`}
    >
      <Stat label={"Group name"} value={group.name} />
      <Stat label={"Area"} value={group.area_name} />

      <DisplayIf condition={edit}>
        <IonButton
          fill={"outline"}
          color={"red"}
          className={styles.deleteButton}
          onClick={(e) => {
            e.preventDefault();
            leaveGroupConfirm();
          }}
        >
          Leave group
        </IonButton>
      </DisplayIf>
    </Link>
  );
}
