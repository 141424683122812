import { UserCityType } from "cp-server";

export function getUserCityWithHighestPercentComplete(
  userCities: Array<UserCityType>
) {
  const sortedArray = [...userCities].sort((a, b) => {
    return parseFloat(a.percent_covered) > parseFloat(b.percent_covered)
      ? -1
      : 1;
  });
  return sortedArray[0];
}

export function sortAreas(areas: Array<UserCityType>) {
  const sortedAreas = areas.sort((a, b) => {
    return parseFloat(a.percent_covered) > parseFloat(b.percent_covered)
      ? -1
      : 1;
  });
  return [...sortedAreas];
}
