import React, { PropsWithChildren } from "react";

const DisplayIf = ({
  children,
  condition,
}: PropsWithChildren<{ condition: boolean }>) => {
  if (condition) {
    return <>{children}</>;
  }
  return null;
};

export default DisplayIf;
