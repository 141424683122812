import React, { Component } from "react";
import styles from "./IntroLayout.module.css";
import ImageKeys from "../../data/images/ImageKeys";
import Header from "../../routes/components/Header";
import ImageManager from "../../data/images/ImageManager";

type DispatchProps = {};
type StateProps = {};
// height prop is a bit of a hacky work around so that the email input can be smaller and not underlap the keyboard on mobile
// TODO At some point make this more responsive
type OtherProps = { height?: number };
type PropsType = DispatchProps & StateProps & OtherProps;

type StateType = {};

export default class IntroLayout extends Component<PropsType, StateType> {
  render() {
    return (
      <div
        className={styles.container}
        style={{
          backgroundImage: `url(${ImageManager.getImage(
            ImageKeys.ONBOARDING_BG
          )})`,
        }}
      >
        <div className={styles.headerContainer}>
          <Header logoOnly />
        </div>
        <div
          className={styles.authBox}
          style={{ height: this.props.height || 590 }}
        >
          <div className={styles.authBoxBG} />
          <div className={styles.content}>{this.props.children}</div>
        </div>
      </div>
    );
  }
}
