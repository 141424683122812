import React, { PureComponent } from "react";
import { UserCityType } from "cp-server";
import styles from "./UserCityList.module.css";
import City from "../UserCity";
import AccountTierFunctions from "../../../../data/account/AccountTierFunctions";
import PremiumMessage from "../../../../components/premium/PremiumMessage";
import DisplayIf from "../../../../components/display-if/DisplayIf";
import NoActivities from "../../../dashboard/components/no-activities/NoActivities";

type Props = {
  cities: Array<UserCityType>;
};

export default function UserCityList({ cities }: Props) {
  const {
    availableAreas,
    lockedAreas,
  } = AccountTierFunctions.processUserAreasList(cities);

  return (
    <div className={styles.cityList}>
      <DisplayIf condition={availableAreas.length === 0}>
        <NoActivities />
      </DisplayIf>
      <DisplayIf condition={availableAreas.length > 0}>
        {availableAreas.map((city) => (
          <City city={city} key={city.user_city_id} locked={false} />
        ))}
        <PremiumMessage text={"Want to view all your areas?"} />
        <DisplayIf condition={AccountTierFunctions.showPremiumOptions()}>
          {lockedAreas.map((city) => (
            <City city={city} key={city.user_city_id} locked={true} />
          ))}
        </DisplayIf>
      </DisplayIf>
    </div>
  );
}
