"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * Created by matthewjamieson on 12/04/2017.
 */
const Api_1 = require("../../Api");
const Actions_1 = require("./Actions");
class ApiActionsHelpers {
    constructor(options) {
        this.loadAction = options.loadAction;
        this.successAction = options.loadAction + Actions_1.API_SUCCESS_SUFFIX;
        this.failAction = options.loadAction + Actions_1.API_FAIL_SUFFIX;
        this.clearErrorAction = options.loadAction + Actions_1.API_CLEAR_ERROR_SUFFIX;
        this.clearDataAction = options.loadAction + Actions_1.API_CLEAR_DATA_SUFFIX;
        this.keepOldData = options.keepOldData;
        this.resource = options.resource;
        this.suppressErrors = options.suppressErrors || false;
    }
    /**
     * Success
     * Dispatched on api request success
     * @param data
     * @returns {{type: string, data: *}}
     */
    success(data, options // eslint-disable-line
    ) {
        return {
            type: this.successAction,
            data,
        };
    }
    /**
     * Fail
     * Dispatched on api request fail
     * @returns {{type: string}}
     */
    fail(error) {
        return {
            type: this.failAction,
            data: error,
        };
    }
    /**
     * Load
     * Dispatched when sending an api request
     * @returns {{type: string}}
     */
    load() {
        return {
            type: this.loadAction,
        };
    }
    /**
     * Action for clearing error error messages from the previous api call
     * @returns {{type: string}}
     */
    clearError() {
        return {
            type: this.clearErrorAction,
        };
    }
    /**
     * Action for resetting to initial state
     * @returns {{type: string}}
     */
    clearData() {
        return (dispatch) => {
            dispatch({
                type: this.clearDataAction,
            });
        };
    }
    /**
     * Returns loadResource function bound to this scope
     * @returns {function()}
     */
    getLoadResourceAction(options = {}) {
        return () => this.loadResource(options);
    }
    /**
     * Handle api errors
     */
    _handleError({ dispatch, json }) {
        // If API returns a user message then alert it
        const error = json.status;
        if (error && json.msg) {
            const userMessage = json.msg;
            if (userMessage.length && !this.suppressErrors) {
                alert(userMessage);
            }
        }
        dispatch(this.fail(json));
    }
    /**
     * If the access token has changed then make sure to save it
     * @param dispatch
     * @param responce
     */
    /*_handleTokenChange(
      dispatch: ThunkDispatch<TStateType, any, any>,
      responce: ResponceType<TResponseType>
    ) {
      try {
        const headers = responce.headers;
        const token = headers.ecltoken || "";
        console.log(headers, token);
        if (token && User.getToken() !== token) {
          User.setToken(token);
          dispatch({
            type: "UPDATE_TOKEN",
            token,
          });
        }
      } catch (e) {
        console.warn(e);
      }
    }*/
    /**
     * LoadResource
     * Loads an api AddUserResource and dispatches the appropriate actions
     * Not recommended to use directly. Use the getLoadResourceAction function
     * (Can be used directly if calling function and not being passed as a reference)
     */
    loadResource(options = {}) {
        return (dispatch) => {
            if (!this.keepOldData) {
                dispatch(this.clearData());
            }
            dispatch(this.load());
            return (0, Api_1.httpRequest)(this.resource, options)
                .catch((err) => {
                dispatch(this.fail(err));
                throw new Error(err);
            })
                .then((response = {}) => {
                const json = response.json || {};
                if (json && !json.success) {
                    this._handleError({ dispatch, json });
                }
                else {
                    //this._handleTokenChange(dispatch, response);
                    dispatch(this.success(json, options));
                }
                return response;
            });
        };
    }
}
exports.default = ApiActionsHelpers;
