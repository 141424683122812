import React from "react";
import sharedStyles from "../../styles/shared.module.css";
import classNames from "classnames";
import styles from "./SmallHeading.module.css";
import { Link } from "react-router-dom";
import DisplayIf from "../display-if/DisplayIf";
type Props = {
  left: string;
  right?: string;
  rightElement?: React.ReactNode;
  link?: string;
};
function SmallHeading({ left, right, link, rightElement }: Props) {
  return (
    <div className={classNames(sharedStyles.heading3, styles.main)}>
      {left}
      <DisplayIf condition={Boolean(right)}>
        <Link
          to={link || "#"}
          className={classNames(sharedStyles.text, styles.right)}
        >
          {right}
        </Link>
      </DisplayIf>
      <DisplayIf condition={Boolean(rightElement)}>
        <div className={classNames(sharedStyles.text, styles.right)}>
          {rightElement}
        </div>
      </DisplayIf>
    </div>
  );
}

export default React.memo(SmallHeading);
