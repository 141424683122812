import React, { Component, CSSProperties } from "react";
import styles from "./Select.module.css";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

type DispatchProps = {};
type StateProps = {};

type OtherProps = {
  onChange: (id: string) => void;
  buttons: Array<{ id: string; label: string }>;
  value?: string;
};

type PropsType = DispatchProps & StateProps & OtherProps;
type StateType = {};

export default class CustomSelect extends Component<PropsType, StateType> {
  renderMenuItem(button: { id: string; label: string }): JSX.Element {
    return (
      <MenuItem
        classes={{ root: styles.label }}
        value={button.id}
        key={button.id}
      >
        {button.label}
      </MenuItem>
    );
  }

  render() {
    return (
      <Select
        value={this.props.value}
        inputProps={{ "aria-label": "Without label" }}
        classes={{ root: styles.mainLabel }}
        onChange={(event) => {
          const id: string = event.target.value as string;
          if (id) {
            this.props.onChange(id);
          }
        }}
        disableUnderline
        style={{ maxWidth: 300 }}
        variant={"standard"}
      >
        {this.props.buttons.map((button) => this.renderMenuItem(button))}
      </Select>
    );
  }
}
