import SubmitFeedbackResource, {
  SubmitFeedbackResourceType,
} from "./SubmitFeedbackResource";
import { SubmitFeedbackResponseType } from "./SubmitFeedbackTypes";
import { createApiActionsHelper } from "../Helpers";
import { OptionsType } from "framework/build/src/types/Api";
import SubmitFeedbackPublicResource, {
  SubmitFeedbackPublicResourceType,
} from "./SubmitFeedbackPublicResource";

export const SUBMIT_FEEDBACK_LOAD_ACTION = "SUBMIT_FEEDBACK_LOAD";

export const submitFeedbackApiActions = createApiActionsHelper<SubmitFeedbackResponseType>(
  {
    resource: SubmitFeedbackResource,
    loadAction: SUBMIT_FEEDBACK_LOAD_ACTION,
  }
);

export const loadSubmitFeedback = (message: string, category: string) => {
  const options: OptionsType<SubmitFeedbackResourceType["body"]> = {
    body: {
      category,
      message,
    },
  };
  return submitFeedbackApiActions.loadResource(options);
};

export const submitFeedbackPublicApiActions = createApiActionsHelper<SubmitFeedbackResponseType>(
  {
    resource: SubmitFeedbackPublicResource,
    loadAction: SUBMIT_FEEDBACK_LOAD_ACTION,
  }
);

export const loadSubmitFeedbackPublic = (message: string, category: string) => {
  const options: OptionsType<SubmitFeedbackPublicResourceType["body"]> = {
    body: {
      category,
      message,
    },
  };
  return submitFeedbackPublicApiActions.loadResource(options);
};

export const clearSubmitFeedback = () => {
  return submitFeedbackApiActions.clearData();
};
