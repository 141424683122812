import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { RouteProps } from "react-router";
import Route from "./RouteWrapper";
import { isLoggedInSelector } from "../state/userslice/UserSlice";

type DispatchProps = {};
type StateProps = {
  isLoggedIn: boolean;
};
type OtherProps = {};
type PropsType = DispatchProps & StateProps & OtherProps & RouteProps;

type StateType = {};

class PrivateRoute extends Component<PropsType, StateType> {
  render() {
    const auth = this.props.isLoggedIn;
    if (!auth) {
      return (
        <Route path={"*"}>
          <Redirect to={"/auth"} />
        </Route>
      );
    }

    if (auth) {
      return <Route {...this.props} />;
    }
  }
}

function mapStateToProps(state): StateProps {
  return {
    isLoggedIn: isLoggedInSelector(state),
  };
}

function mapDispatchToProps(dispatch): DispatchProps {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);
