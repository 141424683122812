import React from "react";
import styles from "../../cities/components/City.module.css";
import styleOverrides from "./DemoCity.module.css";
import sharedStyles from "../../../styles/shared.module.css";
import { CityWithBB, UserNeighbourhoodType } from "cp-server";
import classNames from "classnames";
import Stat from "../../../components/stat/Stat";
import LangManager from "../../../data/languages/LangManager";
import LangKeys from "../../../data/languages/LangKeys";
import { Config } from "framework";

type OtherProps = {
  userNeighbourhood: UserNeighbourhoodType & { image: string };
};
type PropsType = OtherProps;

function DemoNeighbourhood(props: PropsType) {
  const userNeighbourhood = props.userNeighbourhood;
  const landmarksText = `${userNeighbourhood.landmarks_visited}/${userNeighbourhood.total_landmarks}`;
  return (
    <div className={styleOverrides.container}>
      <div className={styles.inner}>
        <div
          className={styles.thumbnail}
          style={{
            backgroundImage: `url(${userNeighbourhood.image})`,
          }}
        />
        <div className={classNames(sharedStyles.heading2, styles.cityName)}>
          {userNeighbourhood.name}
        </div>
        <div className={styles.statsContainer}>
          <Stat
            label={LangManager.getLang(LangKeys.NEIGHBOURHOOD_EXPLORED)}
            value={`${userNeighbourhood.percent_complete} %`}
            valueColor="var(--orange-2)"
          />
          <Stat
            label={LangManager.getLang(LangKeys.NEIGHBOURHOOD_LANDMARKS)}
            value={landmarksText}
            valueColor="var(--orange-2)"
            complete={
              parseInt(userNeighbourhood.landmarks_visited) ===
                userNeighbourhood.total_landmarks &&
              userNeighbourhood.total_landmarks !== 0
            }
          />
          <Stat
            label={LangManager.getLang(LangKeys.NEIGHBOURHOOD_ACTIVITIES)}
            value={`${userNeighbourhood.activities_count}`}
          />
        </div>
      </div>
    </div>
  );
}

export default DemoNeighbourhood;
