"use strict";
/**
 * Created by matthewjamieson on 25/04/2017.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.defaultInitialState = void 0;
const Api_1 = require("../../types/Api");
const Actions_1 = require("./Actions");
exports.defaultInitialState = {
    loading: false,
    data: {
        status: false,
    },
};
/**
 * Api reducer helper
 * This class provides a reducer for handling api actions (see ApiActionsHelper class)
 * A flow type should be provided for the response type.
 */
class ApiReducerHelper {
    /**
     * @param options {loadAction} Only the api load action is needed.
     * The success and fail actions are created by adding a suffix to the load action.
     */
    constructor(options) {
        this.loadAction = options.loadAction;
        this.successAction = options.loadAction + Actions_1.API_SUCCESS_SUFFIX;
        this.clearErrorAction = options.loadAction + Actions_1.API_CLEAR_ERROR_SUFFIX;
        this.clearDataAction = options.loadAction + Actions_1.API_CLEAR_DATA_SUFFIX;
        this.failAction = options.loadAction + Actions_1.API_FAIL_SUFFIX;
        this.initialState = options.initialState;
        this.mergeData = options.mergeData || false;
    }
    /**
     * Returns the reducer function bound to this scope
     *
     * (passing the reducer function directly will result not work
     * because it will be bound to the scope where it is called and not this scope)
     * @returns {function(ApiStateType.<TResponseType>=, ApiActionType.<TResponseType>)}
     */
    handleLoadAction(state = this.initialState) {
        return Object.assign({}, state, {
            loading: true,
        });
    }
    getReducer() {
        return (state = this.initialState, action = { type: "" }) => {
            return this.reducer(state, action);
        };
    }
    /**
     * Success Action - Merge data from the api with data from the initial state.
     * If some data is in an unexpected structure then the initial state data will
     * fill in the gaps. This should prevent errors caused by access of
     * undefined properties on api data
     * @param state
     * @param action
     * @returns {*}
     */
    success(state, action) {
        return Object.assign({}, state, {
            loading: false,
            // $FlowFixMe - Ignore this flow error. (flow bug with generic types and spread operators)
            data: {
                ...this.initialState.data,
                ...(this.mergeData ? state.data : {}),
                ...action.data,
            },
        });
    }
    /**
     * Generic reducer for api responses
     * Not recomended to use directly. Use the getReducer function
     * (Can be used directly if calling function and not being passed as a reference)
     * @param state
     * @param action
     * @returns {*}
     */
    reducer(state = this.initialState, action = { type: "" }) {
        switch (action.type) {
            case this.successAction:
                return this.success(state, action);
            case this.failAction:
                return Object.assign({}, state, {
                    loading: false,
                    data: action.data,
                });
            case this.clearErrorAction:
                return Object.assign({}, state, {
                    status: Api_1.StatusType.NONE,
                });
            case this.clearDataAction:
                return Object.assign({}, state, {
                    data: this.initialState.data,
                    loading: false,
                });
            case this.loadAction:
                return this.handleLoadAction(state);
            default:
                return state;
        }
    }
}
exports.default = ApiReducerHelper;
