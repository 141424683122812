/**
 * Created by matthewjamieson on 07/09/2020.
 */

export type AuthResponce = {
  token: string;
  first_time_user?: boolean;
  vendor: string;
};

export type FullUserType = {
  user_id: string;
  email: string | null;
  city: string | null;
  country: string | null;
  first_name: string | null;
  last_name: string | null;
  password: string | null;
  created_at?: string | null;
  broken_email?: boolean;
  marked_for_deletion?: boolean;
  last_review_popup_attempt?: string | null;
  last_access?: string | null;
};

export type UserType = Pick<
  FullUserType,
  | "user_id"
  | "email"
  | "city"
  | "country"
  | "first_name"
  | "last_name"
  | "created_at"
  | "broken_email"
  | "marked_for_deletion"
  | "last_review_popup_attempt"
  | "last_access"
>;

export type UserWithSubscriptionStatus = UserType & {
  account_type: AccountTypes;
  subscription_status: string;
  subscription_end_timestamp: string;
};

export enum AccountTypes {
  premium = "premium",
  free = "free",
}
