import React, { useCallback } from "react";
import Header from "../components/Header";
import Content from "../../components/content/Content";
import styles from "./PublicAreaList.module.css";
import { useDispatch } from "react-redux";
import { PopupTypes, showPopup } from "../../state/popups/PopupsActions";
import { FeedbackCategory } from "../../popups/feedback/FeedbackPopup";
import { getResultsFromResponse } from "../../data/ApiData";
import SmallHeading from "../../components/headings/SmallHeading";
import sharedStyles from "../../styles/shared.module.css";
import classNames from "classnames";
import LangManager from "../../data/languages/LangManager";
import LangKeys from "../../data/languages/LangKeys";
import { IonContent } from "@ionic/react";
import { usePublicGetCitiesQuery } from "../../state/api/ApiSlice";
export default function PublicAreaList() {
  const dispatch = useDispatch();

  const showFeedbackPopup = useCallback(() => {
    dispatch(
      showPopup({
        popupType: PopupTypes.FEEDBACK,
        data: { category: FeedbackCategory.city_request },
      })
    );
  }, []);
  const { data: citiesResponse } = usePublicGetCitiesQuery({});
  const cities = getResultsFromResponse(citiesResponse);
  const alphabeticalCities = [...cities].sort((a, b) =>
    a.name > b.name ? 1 : -1
  );
  return (
    <IonContent>
      <Header
        showAuth
        title={LangManager.getLang(LangKeys.PUBLIC_CITIES_LIST_SUGGEST_HEADER)}
      />
      <Content style={{ backgroundColor: "#eeeeee" }}>
        <SmallHeading
          left={LangManager.getLang(LangKeys.PUBLIC_CITIES_LIST_SUGGEST)}
        />
        <div style={{ marginTop: 7 }}>
          {LangManager.getLang(LangKeys.PUBLIC_CITIES_LIST_SUGGEST_1)}
          <br />
          {LangManager.getLang(LangKeys.PUBLIC_CITIES_LIST_SUGGEST_2)}
          <span className={styles.suggest} onClick={showFeedbackPopup}>
            {" "}
            {LangManager.getLang(LangKeys.PUBLIC_CITIES_LIST_SUGGEST_LINK)}{" "}
          </span>
          {LangManager.getLang(LangKeys.PUBLIC_CITIES_LIST_SUGGEST_3)}
        </div>
        <br />

        <SmallHeading
          left={LangManager.getLang(LangKeys.PUBLIC_CITIES_LIST_CREATE)}
        />
        {LangManager.getLang(LangKeys.PUBLIC_CITIES_LIST_CREATE_MESSAGE)}
        <br />
        <br />
        <SmallHeading
          left={LangManager.getLang(LangKeys.PUBLIC_CITIES_LIST_SUPPORTED)}
        />
        <div className={styles.citiesContainer}>
          {alphabeticalCities.map((city) => {
            return (
              <div
                key={city.area_id}
                className={classNames(sharedStyles.heading4, styles.city)}
              >
                {city.name}
              </div>
            );
          })}
        </div>
      </Content>
    </IonContent>
  );
}
