import React, { Component } from "react";
import styles from "./Footer.module.css";
import { connect } from "react-redux";
import { ReduxStateType } from "../../../state/ReduxStateType";
import { RouteComponentProps, withRouter } from "react-router-dom";
import LangManager from "../../../data/languages/LangManager";
import LangKeys from "../../../data/languages/LangKeys";
import Content from "../../../components/content/Content";
import { PopupTypes, showPopup } from "../../../state/popups/PopupsActions";

type DispatchProps = {
  showFeedbackPopup: () => void;
};
type StateProps = {};
type OtherProps = {} & RouteComponentProps;
type PropsType = StateProps & DispatchProps & OtherProps;

type StateType = {};

class Footer extends Component<PropsType, StateType> {
  goto = (url: string) => {
    this.props.history.push(url);
  };
  render() {
    return (
      <div className={styles.container}>
        <Content maxWidth={1000}>
          <div className={styles.linkListsContainer}>
            <div className={styles.linkList}>
              <div
                className={styles.link}
                onClick={() => this.goto("/about_us")}
              >
                {LangManager.getLang(LangKeys.FOOTER_ABOUT_US)}
              </div>

              <div
                className={styles.link}
                onClick={() => this.props.showFeedbackPopup()}
              >
                {LangManager.getLang(LangKeys.FOOTER_FEEDBACK)}
              </div>
              <div className={styles.link}>
                <a
                  href={"mailto:matthew@citypainter.io"}
                  className={styles.link}
                >
                  {LangManager.getLang(LangKeys.FOOTER_CONTACT_US)}
                </a>
              </div>
            </div>
            <div className={styles.linkList}>
              <div
                className={styles.link}
                onClick={() => this.goto("/t_and_c")}
              >
                {LangManager.getLang(LangKeys.FOOTER_TERMS_AND_CONDITIONS)}
              </div>
              <div
                className={styles.link}
                onClick={() => this.goto("/privacy_policy")}
              >
                {LangManager.getLang(LangKeys.FOOTER_PRIVACY_POLICY)}
              </div>
            </div>
          </div>
        </Content>
      </div>
    );
  }
}

function mapStateToProps(state: ReduxStateType): StateProps {
  return {};
}

function mapDispatchToProps(dispatch): DispatchProps {
  return {
    showFeedbackPopup: () =>
      dispatch(showPopup({ popupType: PopupTypes.FEEDBACK })),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Footer));
