import React, { useState } from "react";
import Header from "../components/Header";
import {
  IonContent,
  IonLabel,
  IonSegment,
  IonSegmentButton,
} from "@ionic/react";
import PremiumComparisonGrid from "./components/comparisongrid/PremiumComparisonGrid";
import Content from "../../components/content/Content";
import PriceCard from "./components/productdisplay/PriceCard";
import { useUserQuery } from "../../state/api/ApiSlice";
import { AccountTypes } from "cp-server";
import SuccessCard from "./components/success/SuccessCard";
import classNames from "classnames";
import sharedStyles from "../../styles/shared.module.css";
import styles from "./components/productdisplay/ProductSelector.module.css";
import "cordova-plugin-purchase";
import InAppPayments from "../../data/iap/InAppPayments";

export default function PremiumIAP() {
  const store = InAppPayments.getCVDStore();
  const productMonthly = store.get("premium_monthly");
  const product3Monthly = store.get("premium_3monthly");
  const productYear = store.get("premium_yearly");
  console.log("products", productMonthly, product3Monthly, productYear);
  const productArray = [productMonthly, product3Monthly, productYear];
  //Get the user data - This is refreshed after a successful subscription (See InAppPayments module)
  const { data: userResponse } = useUserQuery({});
  console.log(productArray);

  // Possibly use the account tier function module... but it just seems better to get the data directly
  // Because this will access the data straight after a change. The account tier functions might not have the data fast enough?
  const isPremium =
    userResponse?.success &&
    userResponse.user.account_type === AccountTypes.premium;
  const [currentProductId, setCurrentProductId] = useState<string | number>(
    productMonthly?.id || ""
  );

  const headings = {
    premium_3monthly: "Every 3 Months",
    premium_monthly: "Monthly",
    premium_yearly: "Yearly",
  };
  const subheadings = {
    premium_3monthly: "Most Popular",
    premium_monthly: "",
    premium_yearly: "Best Value",
  };
  function renderSegments() {
    return (
      <IonSegment
        value={currentProductId}
        onIonChange={(e) => setCurrentProductId(e.target.value || "")}
        color={"primary"}
      >
        {productArray.map((price, index) => {
          const key = price?.id || "";

          return (
            <IonSegmentButton value={price?.id}>
              <IonLabel style={{ margin: 0 }}>{headings[key]}</IonLabel>
              <div
                className={classNames(
                  sharedStyles.heading5,
                  styles.buttonSubheading
                )}
              >
                {subheadings[key]}
              </div>
            </IonSegmentButton>
          );
        })}
      </IonSegment>
    );
  }
  const product: any = productArray.find((p) => p?.id === currentProductId);
  console.log("PROD: ", product?.raw);

  function orderProduct() {
    console.log("ATTEMPTING PURCHASE");
    store.order(product.offers[0]).then((e) => {
      console.log("PURACHASED", e);
    });
  }

  return (
    <>
      <Header title={"Upgrade"} />
      <IonContent>
        <Content>
          {isPremium ? (
            <SuccessCard />
          ) : (
            <>
              <PremiumComparisonGrid />
              {renderSegments()}
              <PriceCard
                freeTrialAvailable={
                  product?.raw.introPricePaymentMode === "FreeTrial"
                }
                name={product?.title}
                formattedPrice={product?.raw?.price}
                interval={`${
                  product?.raw.billingPeriod !== 1
                    ? `${product?.raw.billingPeriod} `
                    : ""
                }${product?.raw.billingPeriodUnit}`}
                onClick={orderProduct}
                showStripeLogo={false}
              />
            </>
          )}
        </Content>
      </IonContent>
    </>
  );
}
