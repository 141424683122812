import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useGroupAddMemberMutation } from "../../state/api/ApiSlice";
import { showErrorPopup } from "../../state/popups/PopupDispatcher";

export default function JoinGroup() {
  const { referral_code } = useParams<{ referral_code: string }>();
  console.log({ referral_code });
  return <GroupReferralHandler referral_code={referral_code} />;
}

function GroupReferralHandler({ referral_code }: { referral_code: string }) {
  const history = useHistory();
  const [
    addUserToGroup,
    { data: addGroupMemberResponse },
  ] = useGroupAddMemberMutation();

  useEffect(() => {
    if (referral_code) {
      addUserToGroup({ referral_code });
    }
  }, [referral_code]);
  useEffect(() => {
    if (addGroupMemberResponse?.success) {
      const code = addGroupMemberResponse.code;
      if (code === "invalid-code") {
        showErrorPopup(
          "The group you are trying to join does not exist. Please check the link, contact the group admin or email customer support at matthew@citypainter.io"
        );
        return history.push(`/tabs/home`);
      }
      const group = addGroupMemberResponse.group;

      history.replace(`/tabs/home/group/${group?.group_id}?joined=true`);
    }
  }, [addGroupMemberResponse]);
  return <></>;
}
