import React, { useEffect, useState } from "react";
import styles from "../Settings.module.css";
import { IonToggle } from "@ionic/react";
import { SetSettingsType, SettingsType } from "cp-server";
import { useTranslation } from "react-i18next";
import sharedStyles from "../../../styles/shared.module.css";

type PropsType = SettingsType & {
  saveSettings: (settings: SetSettingsType) => void;
};
export default function ActivityDescriptionSettings(props: PropsType) {
  const { t } = useTranslation();

  return (
    <>
      <div className={styles.togglesList}>
        <IonToggle
          checked={props.activity_desc_new_ground}
          onIonChange={(e) => {
            props.saveSettings({ activity_desc_new_ground: e.target.checked });
          }}
        >
          <span className={sharedStyles.text}>{t("settings.newGround")}</span>
        </IonToggle>
        <IonToggle
          checked={props.activity_desc_neighbourhoods}
          onIonChange={(e) => {
            props.saveSettings({
              activity_desc_neighbourhoods: e.target.checked,
            });
          }}
        >
          <span className={sharedStyles.text}>
            {t("settings.neighbourhoods")}
          </span>
        </IonToggle>

        <IonToggle
          checked={props?.activity_desc_landmarks}
          onIonChange={(e) => {
            props.saveSettings({ activity_desc_landmarks: e.target.checked });
          }}
        >
          <span className={sharedStyles.text}>{t("settings.landmarks")}</span>
        </IonToggle>
      </div>
    </>
  );
}
