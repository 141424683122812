// @flow
/**
 * Created by matthewjamieson on 09/02/2017.
 */
import React, { Component, CSSProperties } from "react";
import { ApiStateType } from "framework/build/src/types/Api";
import CircularProgress from "@mui/material/CircularProgress/index";

/**
 * Wrapper for components that should wait for data before rendering
 * An Activity indicator is displayed while  the data is loading.
 * Re-rendering does not take place during interactions/animations.
 * This is to avoid animation stutter
 */

type PropsType = {
  style?: CSSProperties;
  data?: ApiStateType<any> | Array<ApiStateType<any>>; // remove eventually
  size?: "small" | "large";
  loadingElement?: JSX.Element;
  className?: string;
  isLoading?: boolean;
};

type StateType = {};

export default class WaitForData extends Component<PropsType, StateType> {
  render() {
    const style = this.props.style;
    const data = this.props.data;
    const loading =
      (data &&
        (Array.isArray(data)
          ? data.reduce((acc, value) => value.loading || acc, false)
          : data.loading)) ||
      this.props.isLoading;
    return loading ? (
      this.props.loadingElement ? (
        this.props.loadingElement
      ) : (
        <div
          style={style ? style : { padding: 20 }}
          className={this.props.className}
        >
          <div style={{ paddingTop: 20 }}>
            <CircularProgress />
          </div>
        </div>
      )
    ) : (
      this.props.children
    );
  }
}
