import React, { PureComponent } from "react";
import styles from "./ActivityMap.module.css";
import CPMap from "../../../../components/map/CPMap";
import { Polygon } from "geojson";
import { ActivityType, LandmarkType } from "cp-server";
import { MapStyles } from "../../../../components/map/MapStyles";
import { activityHeatmapSource } from "../../../../components/map/Sources";

type DispatchProps = {};
type StateProps = {};
type OtherProps = {
  activity: ActivityType;
  landmarks?: Array<LandmarkType>;
  areaId: string;
  boundingBox: Polygon;
};
type PropsType = DispatchProps & StateProps & OtherProps;

type StateType = {};

export default class ActivityMap extends PureComponent<PropsType, StateType> {
  render() {
    const activity = this.props.activity;
    return (
      <CPMap
        mapStyle={MapStyles.Heatmap}
        unfilled_map
        landmarks={this.props.landmarks || []}
        className={styles.map}
        boundingBox={this.props.boundingBox}
        initialBounds={activity.bounding_box}
        area_id={this.props.areaId}
        bigLandmarks
        sources={activityHeatmapSource(activity.id)}
      />
    );
  }
}
