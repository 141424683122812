import { ApiRequestBodies, ApiResponses, ApiRoutes } from "cp-server";
import { EndpointBuilder } from "@reduxjs/toolkit/dist/query/endpointDefinitions";
import { User } from "framework";
import { TagTypes } from "../../ApiSlice";

const Endpoint = (builder: EndpointBuilder<any, any, any>) =>
  builder.query<ApiResponses[ApiRoutes.User], ApiRequestBodies[ApiRoutes.User]>(
    {
      providesTags: [TagTypes.user_details],
      query: (data) => ({
        url: ApiRoutes.User,
        method: "post",
        body: data,
      }),
      keepUnusedDataFor: 5, // Avoids potential issue where a user logs out and a different user logs in but by default the previous user's data might still be stored
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data.success) {
            const user = data.user;
            const accountType = user.account_type;
            User.setAccountType(accountType);
          }
        } catch (err) {
          console.log(err);
        }
      },
    }
  );

export default Endpoint;
