import React, { Component, useState } from "react";
import styles from "./EmailSettings.module.css";
import { Button, FormControlLabel, FormGroup, Switch } from "@mui/material";
import { SetSettingsType, SettingsType } from "cp-server";
import LangManager from "../../../data/languages/LangManager";
import LangKeys from "../../../data/languages/LangKeys";
import sharedStyles from "../../../styles/shared.module.css";
import classNames from "classnames";
import { IonItem, IonList, IonToggle } from "@ionic/react";

type PropsType = SettingsType & {
  saveSettings: (settings: SetSettingsType) => void;
};

export default function EmailSettings(props: PropsType) {
  const [state, setState] = useState({
    email_all_activities: props.email_all_activities,
    email_notable_activities: props.email_notable_activities,
    email_new_areas: props.email_new_areas,
  });
  function renderSaveButton() {
    // TODO simplify this
    if (
      props.email_all_activities !== state.email_all_activities ||
      props.email_notable_activities !== state.email_notable_activities ||
      props.email_new_areas !== state.email_new_areas
    ) {
      return (
        <div className={styles.saveButton}>
          <Button
            color={"primary"}
            onClick={() => props.saveSettings(state)}
            classes={{ root: styles.button }}
          >
            Save
          </Button>
        </div>
      );
    }
    return null;
  }

  const handleAllActivitiesChange = (event) => {
    const newValue = event.target.checked;
    if (newValue === true) {
      setState({
        ...state,
        email_all_activities: true,
        email_notable_activities: true,
      });
    } else {
      setState({
        ...state,
        email_all_activities: false,
      });
    }
  };
  const handleNotableActivitiesChange = (event) => {
    console.log(event);
    const newValue = event.target.checked;
    console.log("change");
    if (newValue === false) {
      setState({
        ...state,
        email_all_activities: false,
        email_notable_activities: false,
      });
    } else {
      setState({
        ...state,
        email_notable_activities: true,
      });
    }
  };
  const handleNewAreasChange = (event) => {
    const newValue = event.target.checked;
    setState({
      ...state,
      email_new_areas: newValue,
    });
  };

  return (
    <div className={styles.toggles}>
      <IonToggle
        checked={state.email_all_activities}
        onIonChange={handleAllActivitiesChange}
      >
        {LangManager.getLang(LangKeys.SETTINGS_EMAIL_ACTIVITIES)}
      </IonToggle>

      <IonToggle
        checked={state.email_notable_activities}
        onIonChange={handleNotableActivitiesChange}
      >
        {LangManager.getLang(LangKeys.SETTINGS_EMAIL_NOTABLE_ACTIVITIES)}
      </IonToggle>
      <div
        className={classNames(sharedStyles.heading5, styles.toggleDescription)}
      >
        {LangManager.getLang(LangKeys.SETTINGS_EMAIL_NOTABLE_ACTIVITIES_DESC)}
      </div>

      <IonToggle
        checked={state.email_new_areas}
        onIonChange={handleNewAreasChange}
      >
        {"New cities available for you"}
      </IonToggle>

      <div
        className={classNames(sharedStyles.heading5, styles.toggleDescription)}
      >
        {
          "This is when a new city or area is added to City Painter and you have activities in this area"
        }
      </div>
      {renderSaveButton()}
    </div>
  );
}
