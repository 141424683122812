import { ApiResultsType, ApiStateType, ResponseType } from "framework";

export function getData<Type extends ResponseType>(
  state: ApiStateType<Type>
): Type {
  return state.data;
}

export function getResults<Type>(
  state: ApiStateType<ResponseType<ApiResultsType<Type>>>
): Array<Type> {
  const response = getData(state);
  if (response?.success) {
    return response.results || [];
  }
  return [];
}

export function getResultsFromResponse<Type>(
  response: ResponseType<ApiResultsType<Type>> | undefined
): Array<Type> {
  if (response?.success && response.results) {
    return response.results;
  }
  return [];
}
