import { ApiRequestBodies, ApiResponses, ApiRoutes } from "cp-server";
import { EndpointBuilder } from "@reduxjs/toolkit/dist/query/endpointDefinitions";
import { PopupTypes, showPopup } from "../../../popups/PopupsActions";
import { history } from "../../../Store";
import { TagTypes } from "../../ApiSlice";

const Endpoint = (builder: EndpointBuilder<any, any, any>) =>
  builder.mutation<
    ApiResponses[ApiRoutes.AddCustomArea],
    ApiRequestBodies[ApiRoutes.AddCustomArea]
  >({
    query: (data) => ({
      url: ApiRoutes.AddCustomArea,
      method: "post",
      body: data,
    }),
    invalidatesTags: [TagTypes.user_areas],

    async onQueryStarted(arg, { dispatch, queryFulfilled }) {
      try {
        const { data } = await queryFulfilled;
        if (data.success === false && data.error) {
          dispatch(
            showPopup({
              popupType: PopupTypes.ERROR,
              data: { message: data.error.msg },
            })
          );
        }

        /**
         * Push to newly generated user city (or city if user city doesnt exist - is that even possible?)
         */
        if (data.success) {
          const city = data.city;
          const userCity = data.userCity;
          if (userCity) {
            history.push(`/tabs/home/${userCity.user_city_id}`);
          } else if (city) {
            history.push(`/tabs/explore/city/${city.area_id}`);
          }
        }
      } catch (err) {
        console.log(err);
      }
    },
  });

export default Endpoint;
