import React from "react";
import styles from "./AppAvailableNotification.module.css";
import sharedStyles from "../../styles/shared.module.css";
import classNames from "classnames";
import { isAndroid, isIos, isWeb } from "../../platform/PlatformManager";
import IosBadge from "../../assets/ios-badge.svg";
export default function AppAvailableNotification() {
  if (isWeb() && isAndroid()) {
    return (
      <div className={styles.container}>
        <div className={classNames(sharedStyles.text, styles.message)}>
          Android app now available!
        </div>
        <a
          href={"https://play.google.com/store/apps/details?id=io.citypainter"}
        >
          <img
            src={require("../../assets/google-play-badge.png")}
            height={60}
          />
        </a>
      </div>
    );
  }

  if (isWeb() && isIos()) {
    return (
      <div className={styles.containerIos}>
        <div className={classNames(sharedStyles.text, styles.message)}>
          iPhone app now available!
        </div>
        <a href={"https://apps.apple.com/us/app/city-painter/id1615522457"}>
          <img src={IosBadge} height={44} />
        </a>
      </div>
    );
  }
  return null;
}
