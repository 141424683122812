import { ApiRequestBodies, ApiResponses, ApiRoutes } from "cp-server";
import { EndpointBuilder } from "@reduxjs/toolkit/dist/query/endpointDefinitions";

const Endpoint = (builder: EndpointBuilder<any, any, any>) =>
  builder.mutation<
    ApiResponses[ApiRoutes.SubscriptionGetPortalUrl],
    ApiRequestBodies[ApiRoutes.SubscriptionGetPortalUrl]
  >({
    query: (data) => ({
      url: ApiRoutes.SubscriptionGetPortalUrl,
      method: "post",
      body: data,
    }),
    async onQueryStarted(arg, { dispatch, queryFulfilled }) {
      try {
        const { data } = await queryFulfilled;
        //@ts-ignore
        window.location.href = data?.success && data.portal_url;
      } catch (err) {
        console.log(err);
      }
    },
  });

export default Endpoint;
