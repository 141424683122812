import React, { useEffect } from "react";
import styles from "./AccountSetupImport.module.css";
import { useDispatch, useSelector } from "react-redux";
import IntroLayout from "../../components/introlayout/IntroLayout";
import { Logo } from "../../components/logo/Logo";
import sharedStyles from "../../styles/shared.module.css";
import { CircularProgress } from "@mui/material";
import { useHistory } from "react-router-dom";
import {
  importFromVendorSelector,
  importFromVendorStateSelector,
} from "../../state/importfromvendor/ImportFromVendorSelectors";
import { loadImportFromVendor } from "../../state/importfromvendor/ImportFromVendorActions";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { PopupTypes, showPopup } from "../../state/popups/PopupsActions";
import { FeedbackCategory } from "../../popups/feedback/FeedbackPopup";
import {
  isLoggedInSelector,
  vendorSelector,
} from "../../state/userslice/UserSlice";
import { User } from "framework";

export default function AccountSetupImport() {
  const dispatch = useDispatch();
  const history = useHistory();

  const importFromVendor = useSelector(importFromVendorSelector);
  const importFromVendorState = useSelector(importFromVendorStateSelector);
  const isLoggedIn = useSelector(isLoggedInSelector);
  const vendor = useSelector(vendorSelector);
  useEffect(() => {
    if (importFromVendor?.success) {
      setTimeout(() => {
        history.replace("/tabs/home/");
      }, 2000);
    }
    if (
      !importFromVendorState.loading &&
      !importFromVendor?.success &&
      isLoggedIn
    ) {
      console.log("token is ", User.getToken());
      dispatch(loadImportFromVendor(vendor));
    }
  }, []);

  useEffect(() => {
    // if (prevProps.importFromVendorState.loading) {
    if (importFromVendor?.success) {
      history.replace("/tabs/home");
    }
    //}
  }, [importFromVendor]);

  function renderContent() {
    return (
      <>
        <div className={sharedStyles.heading3}>Fetching activity data</div>
        <div className={sharedStyles.heading5}>
          This may take up to a minute
        </div>
        <div className={styles.progressContainer}>
          <CircularProgress />
        </div>
      </>
    );
  }
  function renderError() {
    return (
      <div className={classNames(sharedStyles.heading3, styles.errorContainer)}>
        Something went wrong. Please{" "}
        <Link to={"/auth"} className={sharedStyles.link}>
          try again
        </Link>
        {" or "}
        <span
          className={sharedStyles.link}
          onClick={() =>
            dispatch(
              showPopup({
                popupType: PopupTypes.FEEDBACK,
                data: { category: FeedbackCategory.general },
              })
            )
          }
        >
          contact us
        </span>
      </div>
    );
  }

  // @ts-ignore
  const error = importFromVendor.success === false && importFromVendor.error;
  return (
    <IntroLayout>
      <div className={styles.logoContainer}>
        <Logo />
      </div>
      <div className={styles.container}>
        {error ? renderError() : renderContent()}
      </div>
    </IntroLayout>
  );
}
