/* eslint-disable */
import React, { PureComponent } from "react";

import { CityWithBB, LandmarkType } from "cp-server";
import maplibregl from "maplibre-gl";
//@ts-ignore
import styles from "./CityMap.module.css";

import CPMap from "../../../../components/map/CPMap";

type DispatchProps = {};
type StateProps = {};
type OtherProps = { city: CityWithBB; landmarks: Array<LandmarkType> };
type PropsType = DispatchProps & StateProps & OtherProps;

type StateType = {};

export default class CityMap extends PureComponent<PropsType, StateType> {
  render() {
    const city = this.props.city;
    const landmarks = this.props.landmarks;
    return (
      <div style={{ height: "70vh", display: "flex" }}>
        <CPMap
          area_id={city.area_id}
          boundingBox={city.bounding_box}
          landmarks={landmarks}
          className={styles.map}
          unfilled_map
        />
      </div>
    );
  }
}
