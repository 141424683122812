import styles from "./Neighbourhood.module.css";
import React from "react";
import { NeighbourhoodSimple } from "cp-server";
import ImageManager from "../../../data/images/ImageManager";
import ImageKeys from "../../../data/images/ImageKeys";

function Neighbourhood(props: { neighbourhood: NeighbourhoodSimple }) {
  return (
    <div className={styles.container}>
      <img
        width={20}
        height={20}
        src={ImageManager.getImage(ImageKeys.NEIGHBOURHOOD_ICON)}
      />
      <div className={styles.neighbourhood}>{props.neighbourhood?.name}</div>
    </div>
  );
}
export default React.memo(Neighbourhood);
