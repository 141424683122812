import React, { useEffect } from "react";
import { PopupTypes, showPopup } from "../../state/popups/PopupsActions";
import {
  useAddUserMutation,
  useLazyUserQuery,
  useUserQuery,
} from "../../state/api/ApiSlice";
import { useDispatch, useSelector } from "react-redux";
import { isLoggedInSelector } from "../../state/userslice/UserSlice";
import { useHistory } from "react-router-dom";

export default function StravaPermissionsUpdate() {
  const [
    addUser,
    { isLoading: addUserIsLoading, data: addUserResponse },
  ] = useAddUserMutation();
  const dispatch = useDispatch();
  const search: string = window.location.search;
  const params = new URLSearchParams(search);
  const oauth_token = params.get("oauth_token") || "";
  const code = params.get("code") || "";
  const scopesString = params.get("scope") || "";
  const scopes = scopesString.split(",");
  const history = useHistory();
  const { data } = useUserQuery({});
  const [refreshUserDetails] = useLazyUserQuery({});

  useEffect(() => {
    if (!scopes.includes("activity:read")) {
      dispatch(showPopup({ popupType: PopupTypes.VENDOR_PERMISSIONS_ERROR }));
    } else {
      if (!code && !oauth_token) {
        history.goBack();
      } else {
        addUser({ code, vendor: "strava", permissions: scopesString });
      }
    }
  }, []);
  useEffect(() => {
    if (addUserResponse?.success) {
      refreshUserDetails({});
      history.replace("/tabs/home");
    }
  }, [addUserResponse]);

  return <div>Scopes are now: {scopes}</div>;
}
