import React, { ReactElement } from "react";
import {
  IonBackButton,
  IonButtons,
  IonHeader,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import sharedStyles from "../../styles/shared.module.css";
import { isApp, isMobile } from "../../platform/PlatformManager";
import Content from "../content/Content";
import styles from "./MainHeading.module.css";

function MainHeading(props: { title: string; rightContent?: ReactElement }) {
  const longName = props.title.length > 80;
  if (!isMobile()) {
    return (
      <Content>
        <div className={styles.container}>
          <div
            className={longName ? sharedStyles.heading2 : sharedStyles.heading1}
            style={{
              color: "var(--text-medium)",
            }}
          >
            {props.title}
          </div>
          {props.rightContent || null}
        </div>
      </Content>
    );
  }
  return (
    <IonHeader>
      <IonToolbar>
        <IonButtons slot="start">
          <IonBackButton defaultHref={"/tabs/home"} />
        </IonButtons>
        <IonTitle>{props.title}</IonTitle>
        <IonButtons slot={"end"}>{props.rightContent}</IonButtons>
      </IonToolbar>
    </IonHeader>
  );
}

export default React.memo(MainHeading);
