import { ApiRequestBodies, ApiResponses, ApiRoutes } from "cp-server";
import { EndpointBuilder } from "@reduxjs/toolkit/dist/query/endpointDefinitions";
import { User } from "framework";
import { loadImportFromVendor } from "../../../importfromvendor/ImportFromVendorActions";
import { setToken, setVendor } from "../../../userslice/UserSlice";

const Endpoint = (builder: EndpointBuilder<any, any, any>) =>
  builder.mutation<
    ApiResponses[ApiRoutes.AddUser],
    ApiRequestBodies[ApiRoutes.AddUser]
  >({
    query: (data) => ({
      url: ApiRoutes.AddUser,
      method: "post",
      body: data,
    }),
    async onQueryStarted(arg, { dispatch, queryFulfilled }) {
      try {
        const { data } = await queryFulfilled;
        if (data?.success) {
          const { token, vendor, first_time_user } = data;
          User.setToken(token);
          dispatch(setToken(token));
          dispatch(setVendor(vendor));
          dispatch(loadImportFromVendor(vendor, first_time_user));

          // @ts-ignore
          window.gtag("event", first_time_user ? "register" : "login", {
            method: vendor,
          });
        }
      } catch (err) {
        console.log(err);
      }
    },
  });

export default Endpoint;
