import React, { PropsWithChildren } from "react";
import Content from "../../../../components/content/Content";
import classNames from "classnames";
import styles from "../../Dashboard.module.css";
import sharedStyles from "../../../../styles/shared.module.css";

export default function InfoMessage(props: PropsWithChildren<any>) {
  return (
    <div className={classNames(styles.message, sharedStyles.text)}>
      <img
        src={require("../../../../assets/landing/exclaimation.png")}
        width={24}
        height={24}
        style={{ marginTop: 4 }}
        alt={"Exclamation point img"}
      />
      <div style={{ marginTop: 8 }}>{props.children}</div>
    </div>
  );
}
