import React, { useEffect } from "react";
import DisplayIf from "../../../components/display-if/DisplayIf";
import classNames from "classnames";
import sharedStyles from "../../../styles/shared.module.css";
import styles from "../../dashboard/components/neighbourhoods/DashboardNeighbourhoods.module.css";
import {
  useGetUserCitiesQuery,
  useUserQuery,
} from "../../../state/api/ApiSlice";
import moment from "moment";
const ACCOUNT_AGE_THRESHOLD = 210; // In seconds. Accounts younger than 3 minutes will display a message informing the user that neighbourhood stats might not be complete as they take time to process

/**
 * Only displayed if the account is less than 5 minutes old
 * @constructor
 */
export default function AreasProcessingNote() {
  const { data: userResponse } = useUserQuery({});
  const { data: userCities, refetch } = useGetUserCitiesQuery({});
  const accountAge =
    userResponse?.success &&
    moment().diff(moment(userResponse.user.created_at), "seconds");

  /**
   * Refresh user cities every x seconds for the first few minutes
   */
  useEffect(() => {
    let interval;
    if (Number(accountAge) < ACCOUNT_AGE_THRESHOLD) {
      interval = setInterval(() => {
        const accountAge =
          userResponse?.success &&
          moment().diff(moment(userResponse.user.created_at), "seconds");
        if (Number(accountAge) < ACCOUNT_AGE_THRESHOLD) {
          refetch();
        } else {
          clearInterval(interval);
        }
      }, 10000);
    }
    return () => clearInterval(interval);
  }, []);

  return (
    <DisplayIf condition={Number(accountAge) < ACCOUNT_AGE_THRESHOLD}>
      <div className={classNames(sharedStyles.text, styles.readyMessage)}>
        Note: After sign up it will take a few minutes to process your areas and
        activities.
      </div>
    </DisplayIf>
  );
}
