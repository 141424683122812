import DashboardHeader from "./components/header/DashboardHeader";
import { UserCityType } from "cp-server";
import React, { useState } from "react";
import DashboardContent from "./DashboardContent";
import Content from "../../components/content/Content";
import { useHistory } from "react-router-dom";
import AccountTierFunctions from "../../data/account/AccountTierFunctions";
import IsAccountReady from "./components/account-ready/IsAccountReady";
import DisplayIf from "../../components/display-if/DisplayIf";
import {
  useGetSettingsQuery,
  useSetSettingsMutation,
} from "../../state/api/ApiSlice";
import { useDispatch } from "react-redux";

export default function DashboardContentAreaSelector({
  userAreas,
  initialCityId,
}: {
  userAreas: Array<UserCityType>; // All, not filtered
  initialCityId: string;
}) {
  const history = useHistory();
  const { data: settings } = useGetSettingsQuery();
  const homeArea = (settings?.success && settings?.settings?.home_area) || "";
  const {
    availableAreas,
    lockedAreas,
  } = AccountTierFunctions.processUserAreasList(userAreas, homeArea);
  const currentCity = userAreas.find((uc) => uc.user_city_id === initialCityId);
  const isLocked = Boolean(
    lockedAreas.find((uc) => uc.user_city_id === initialCityId)
  );

  const handleChangeCity = (userCityId: string) => {
    history.replace(`/tabs/home/${userCityId}`);
  };

  return (
    <div>
      <IsAccountReady showPlaceholder={true} />
      <Content>
        <DisplayIf condition={Boolean(currentCity)}>
          <DashboardHeader
            userCities={availableAreas}
            onChangeCity={handleChangeCity}
            userArea={currentCity as UserCityType}
          />
        </DisplayIf>
      </Content>
      <DisplayIf condition={Boolean(currentCity)}>
        <DashboardContent
          userArea={currentCity as UserCityType}
          key={currentCity?.user_city_id}
          isLocked={isLocked}
        />
      </DisplayIf>
    </div>
  );
}
