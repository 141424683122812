import React from "react";
import { RouteProps } from "react-router";
import { Route } from "react-router-dom";
import { IonPage } from "@ionic/react";
import { isAtBottom } from "../App";
export default function RouteWrapper(props: RouteProps) {
  const RouteComponent = props.component;
  if (props.component) {
    return (
      <Route
        {...props}
        render={(props) => (
          <IonPage>
            {/* @ts-ignore */}
            <RouteComponent {...props} />
          </IonPage>
        )}
      />
    );
  }
  if (props.children) {
    return (
      <Route {...props}>
        <IonPage>{props.children}</IonPage>
      </Route>
    );
  }
  if (props.render) {
    return (
      <Route
        {...props}
        render={(p) => {
          // @ts-ignore
          return <IonPage>{props.render(p)}</IonPage>;
        }}
      />
    );
  }

  return <div></div>;
}
