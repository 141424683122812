export default {
  ACTIVITY_NEW_GROUND: "ACTIVITY_NEW_GROUND",
  ACTIVITY_DISTANCE: "ACTIVITY_DISTANCE",
  ACTIVITY_TIME: "ACTIVITY_TIME",
  ACTIVITY_REVISITED: "ACTIVITY_REVISITED",
  ACTIVITIES_COUNT_IN: "ACTIVITIES_COUNT_IN",
  ACTIVITIES_COUNT: "ACTIVITIES_COUNT",
  ACTIVITIES_LANDMARKS_VISITED: "ACTIVITIES_LANDMARKS_VISITED",
  ACTIVITIES_NEIGHBOURHOODS_VISITED: "ACTIVITIES_NEIGHBOURHOODS_VISITED",
  ACTIVITIES_NONE_VISITED: "ACTIVITIES_NONE_VISITED",
  ACTIVITIES_LANDMARKS: "ACTIVITIES_LANDMARKS",
  ACTIVITY_VIEW_ALL_LANDMARKS: "ACTIVITY_VIEW_ALL_LANDMARKS",
  ACTIVITY_DETAILS_LANDMARKS_VISITED: "ACTIVITY_DETAILS_LANDMARKS_VISITED",
  ACTIVITY_DETAILS_NEIGHBOURHOODS_VISITED:
    "ACTIVITY_DETAILS_NEIGHBOURHOODS_VISITED",
  ACTIVITY_DETAILS_FIRST_VISIT: "ACTIVITY_DETAILS_FIRST_VISIT",
  ACTIVITY_DETAILS_PREVIOUS_VISITS: "ACTIVITY_DETAILS_PREVIOUS_VISITS",
  ACHIEVEMENT_PROGRESS: "ACHIEVEMENT_PROGRESS",
  ACHIEVEMENT_VISIT_1: "ACHIEVEMENT_VISIT_1",
  ACHIEVEMENT_VISIT_2: "ACHIEVEMENT_VISIT_2",
  ACHIEVEMENT_COMPLETE: "ACHIEVEMENT_COMPLETE",
  ACHIEVEMENT_COMPLETE_2: "ACHIEVEMENT_COMPLETE_2",
  ACHIEVEMENTS_HEADER: "ACHIEVEMENTS_HEADER",
  ACHIEVEMENT_LANDMARKS_HEADER: "ACHIEVEMENT_LANDMARKS_HEADER",
  ACCOUNT_SETTINGS: "ACCOUNT_SETTINGS",
  ACCOUNT_LOGOUT: "ACCOUNT_LOGOUT",
  ACCOUNT_CONTACT: "ACCOUNT_CONTACT",
  AUTH_HEADER: "AUTH_HEADER",
  CITIES_HEADER: "CITIES_HEADER",
  CITIES_PERCENT: "CITIES_PERCENT",
  CITIES_ACTIVITIES: "CITIES_ACTIVITIES",
  CITIES_TOTAL_ROADS: "CITIES_TOTAL_ROADS",
  CITIES_TOTAL_OFF_ROADS: "CITIES_TOTAL_OFF_ROADS",
  CITIES_NEIGHBOURHOODS_TOTAL: "CITIES_NEIGHBOURHOODS_TOTAL",
  CITIES_BROWSE: "CITIES_BROWSE",
  CITIES_CUSTOM: "CITIES_CUSTOM",
  CITIES_DISTANCE: "CITIES_DISTANCE",
  CITIES_LANDMARKS: "CITIES_LANDMARKS",
  CITIES_LANDMARKS_VISITED: "CITIES_LANDMARKS_VISITED",
  CITIES_ACHIEVEMENTS: "CITIES_ACHIEVEMENTS",
  CITIES_NEIGHBOURHOODS: "CITIES_NEIGHBOURHOODS",
  CITY_DETAILS_TOTAL_ROADS: "CITY_DETAILS_TOTAL_ROADS",
  CITY_DETAILS_OFF_ROADS: "CITY_DETAILS_OFF_ROADS",
  CITY_DETAILS_LANDMARKS: "CITY_DETAILS_LANDMARKS",
  CITY_DETAILS_LANDMARKS_HEADER: "CITY_DETAILS_LANDMARKS_HEADER",
  CITY_SUGGESTION_MESSAGE: "CITY_SUGGESTION_MESSAGE",
  CITY_SUGGESTION: "CITY_SUGGESTION",
  CREATE_AREA: "CREATE_AREA",
  CREATE_OR_EDIT_AREA: "CREATE_OR_EDIT_AREA",
  COOKIE_POPUP_HEADER: "COOKIE_POPUP_HEADER",
  COOKIE_POPUP_TEXT: "COOKIE_POPUP_TEXT",
  COOKIE_POPUP_PRIVACY_POLICY: "COOKIE_POPUP_PRIVACY_POLICY",
  COOKIE_POPUP_PRIVACY_ACCEPT: "COOKIE_POPUP_PRIVACY_ACCEPT",
  COOKIE_POPUP_PRIVACY_REJECT: "COOKIE_POPUP_PRIVACY_REJECT",
  COOKIE_POPUP_PRIVACY_LEARN_MORE: "COOKIE_POPUP_PRIVACY_LEARN_MORE",
  CUSTOM_AREAS_HEADER: "CUSTOM_AREAS_HEADER",
  CUSTOM_AREA_DELETE_BUTTON: "CUSTOM_AREA_DELETE_BUTTON",
  CUSTOM_AREA_DELETE_CONFIRM: "CUSTOM_AREA_DELETE_CONFIRM",
  CUSTOM_AREA_CREATE: "CUSTOM_AREA_CREATE",
  CUSTOM_AREA_DELETE_CANCEL: "CUSTOM_AREA_DELETE_CANCEL",
  CUSTOM_AREA_DELETE_MESSAGE: "CUSTOM_AREA_DELETE_MESSAGE",
  GROUP_DELETE_MESSAGE: "GROUP_DELETE_MESSAGE",
  DELETE_ACCOUNT_CONFIRM_MESSAGE: "DELETE_ACCOUNT_CONFIRM_MESSAGE",
  DELETE_ACCOUNT_CANCEL: "DELETE_ACCOUNT_CANCEL",
  DELETE_ACCOUNT_CONFIRM: "DELETE_ACCOUNT_CONFIRM",
  DRAW_AREA_HEADER: "DRAW_AREA_HEADER",
  DRAW_AREA_SUBHEADER: "DRAW_AREA_SUBHEADER",
  DRAW_AREA_INSTRUCTION: "DRAW_AREA_INSTRUCTION",
  DRAW_AREA_NAME: "DRAW_AREA_NAME",
  DRAW_AREA_CREATE: "DRAW_AREA_CREATE",
  FEEDBACK_HEADER: "FEEDBACK_HEADER",
  FEEDBACK_SUBMIT: "FEEDBACK_SUBMIT",
  FEEDBACK_CLOSE: "FEEDBACK_CLOSE",
  FEEDBACK_THANKS: "FEEDBACK_THANKS",
  FEEDBACK_TYPE: "FEEDBACK_TYPE",
  FEEDBACK_TYPE_GENERAL: "FEEDBACK_TYPE_GENERAL",
  FEEDBACK_TYPE_MAPS: "FEEDBACK_TYPE_MAPS",
  FEEDBACK_TYPE_ACTIVITIES: "FEEDBACK_TYPE_ACTIVITIES",
  FEEDBACK_TYPE_REQUEST: "FEEDBACK_TYPE_REQUEST",
  FEEDBACK_TYPE_AREA: "FEEDBACK_TYPE_AREA",
  FEEDBACK_TYPE_CRITICISM: "FEEDBACK_TYPE_CRITICISM",
  FEEDBACK_NEW_FEATURES: "FEEDBACK_NEW_FEATURES",
  FOOTER_PRIVACY_POLICY: "FOOTER_PRIVACY_POLICY",
  FOOTER_TERMS_AND_CONDITIONS: "FOOTER_TERMS_AND_CONDITIONS",
  FOOTER_ABOUT_US: "FOOTER_ABOUT_US",
  FOOTER_FEEDBACK: "FOOTER_FEEDBACK",
  FOOTER_CONTACT_US: "FOOTER_CONTACT_US",
  FEEDBACK_TYPE_LANDMARKS: "FEEDBACK_TYPE_LANDMARKS",
  GROUPS_DASHBOARD_HEADER: "GROUPS_DASHBOARD_HEADER",
  LANDMARK_HEADER_ALL: "LANDMARK_HEADER_ALL",
  LANDMARK_HEADER_PEAKS: "LANDMARK_HEADER_PEAKS",
  LANDMARK_HEADER_ARTWORKS: "LANDMARK_HEADER_ARTWORKS",
  LANDMARK_HEADER_VIEWPORTS: "LANDMARK_HEADER_VIEWPORTS",
  LANDMARK_HEADER_MEMORIALS: "LANDMARK_HEADER_MEMORIALS",
  LANDMARK_HEADER_MONUMENTS: "LANDMARK_HEADER_MONUMENTS",
  LANDMARK_HEADER_OTHERS: "LANDMARK_HEADER_OTHERS",
  LANDMARK_DETAILS_VISITS: "LANDMARK_DETAILS_VISITS",
  LANDMARK_DETAILS_RUNS: "LANDMARK_DETAILS_RUNS",
  LANDMARK_DETAILS_CYCLE: "LANDMARK_DETAILS_CYCLE",
  LANDMARK_DETAILS_WALK: "LANDMARK_DETAILS_WALK",
  LANDMARK_DETAILS_EVERYONE: "LANDMARK_DETAILS_EVERYONE",
  LANDMARK_DETAILS_YOU: "LANDMARK_DETAILS_YOU",
  LANDMARK_DETAILS_NO_VISITS: "LANDMARK_DETAILS_NO_VISITS",
  LANDMARK_DETAILS_YOUR_VISITS: "LANDMARK_DETAILS_YOUR_VISITS",
  LANDMARK_DETAILS_TOTAL_VISITS: "LANDMARK_DETAILS_TOTAL_VISITS",
  LANDMARKS_HEADER: "LANDMARKS_HEADER",
  LANDMARKS_SEE_ALL: "LANDMARKS_SEE_ALL",
  LEADERBOARDS_HEADER: "LEADERBOARDS_HEADER",
  LEADERBOARD_THIS_MONTH: "LEADERBOARD_THIS_MONTH",
  LEADERBOARD_THIS_YEAR: "LEADERBOARD_THIS_YEAR",
  LEADERBOARD_ALL: "LEADERBOARD_ALL",
  LEADERBOARD_NO_ENTRIES: "LEADERBOARD_NO_ENTRIES",
  LEADERBOARD_NOT_IN_LEADERBOARD: "LEADERBOARD_NOT_IN_LEADERBOARD",
  PUBLIC_CITIES_LIST_SUGGEST_HEADER: "PUBLIC_CITIES_LIST_SUGGEST_HEADER",
  PUBLIC_CITIES_LIST_SUGGEST: "PUBLIC_CITIES_LIST_SUGGEST",
  PUBLIC_CITIES_LIST_SUGGEST_1: "PUBLIC_CITIES_LIST_SUGGEST_1",
  PUBLIC_CITIES_LIST_SUGGEST_2: "PUBLIC_CITIES_LIST_SUGGEST_2",
  PUBLIC_CITIES_LIST_SUGGEST_LINK: "PUBLIC_CITIES_LIST_SUGGEST_LINK",
  PUBLIC_CITIES_LIST_SUGGEST_3: "PUBLIC_CITIES_LIST_SUGGEST_3",
  PUBLIC_CITIES_LIST_CREATE: "PUBLIC_CITIES_LIST_CREATE",
  PUBLIC_CITIES_LIST_CREATE_MESSAGE: "PUBLIC_CITIES_LIST_CREATE_MESSAGE",
  PUBLIC_CITIES_LIST_SUPPORTED: "PUBLIC_CITIES_LIST_SUPPORTED",
  NEIGHBOURHOOD_EXPLORED: "NEIGHBOURHOOD_EXPLORED",
  NEIGHBOURHOOD_LANDMARKS: "NEIGHBOURHOOD_LANDMARKS",
  NEIGHBOURHOOD_ACTIVITIES_HEADER: "NEIGHBOURHOOD_ACTIVITIES_HEADER",
  NEIGHBOURHOOD_ACTIVITIES: "NEIGHBOURHOOD_ACTIVITIES",
  NEIGHBOURHOODS_VISITED: "NEIGHBOURHOODS_VISITED",
  NEIGHBOURHOODS_HEADER_TOP: "NEIGHBOURHOODS_HEADER_TOP",
  NEIGHBOURHOODS_HEADER_ALL: "NEIGHBOURHOODS_HEADER_ALL",
  NEIGHBOURHOODS_COMING_SOON_MESSAGE: "NEIGHBOURHOODS_COMING_SOON_MESSAGE",
  NEIGHBOURHOODS_HIGHLIGHT_INCOMPLETE_PATHS:
    "NEIGHBOURHOODS_HIGHLIGHT_INCOMPLETE_PATHS",
  REGISTER_COMPLETE_HEADER: "REGISTER_COMPLETE_HEADER",
  REGISTER_SUBHEADER: "REGISTER_SUBHEADER",
  REGISTER_EMAIL: "REGISTER_EMAIL",
  REGISTER_AGREE: "REGISTER_AGREE",
  REGISTER_TERMS: "REGISTER_TERMS",
  REGISTER_PP: "REGISTER_PP",
  REGISTER_CONTINUE: "REGISTER_CONTINUE",
  SETTINGS_HEADER: "SETTINGS_HEADER",
  SETTINGS_EMAIL_HEADER: "SETTINGS_EMAIL_HEADER",
  SETTINGS_EMAIL_ACTIVITIES: "SETTINGS_EMAIL_ACTIVITIES",
  SETTINGS_EMAIL_NOTABLE_ACTIVITIES: "SETTINGS_EMAIL_NOTABLE_ACTIVITIES",
  SETTINGS_EMAIL_NOTABLE_ACTIVITIES_DESC:
    "SETTINGS_EMAIL_NOTABLE_ACTIVITIES_DESC",
  SETTINGS_ACCOUNT: "SETTINGS_ACCOUNT",
  SETTINGS_CLOSE_ACCOUNT: "SETTINGS_CLOSE_ACCOUNT",
  DASHBOARD_LANDMARKS_MOST_VISITED: "DASHBOARD_LANDMARKS_MOST_VISITED",
  DASHBOARD_LANDMARKS_RECENTLY_VISITED: "DASHBOARD_LANDMARKS_RECENTLY_VISITED",
  DASHBOARD_LANDMARKS_NO_VISITS: "DASHBOARD_LANDMARKS_NO_VISITS",
  DASHBOARD_LANDMARKS_VISITS: "DASHBOARD_LANDMARKS_VISITS",
  DASHBOARD_LANDMARKS_VISIT: "DASHBOARD_LANDMARKS_VISIT",
  DASHBOARD_ACHIEVEMENTS: "DASHBOARD_ACHIEVEMENTS",
  DASHBOARD_NEIGHBOURHOODS: "DASHBOARD_NEIGHBOURHOODS",
  DASHBOARD_NEIGHBOURHOODS_SEE_ALL: "DASHBOARD_NEIGHBOURHOODS_SEE_ALL",
  DASHBOARD_NEIGHBOURHOODS_TOP: "DASHBOARD_NEIGHBOURHOODS_TOP",
  HEADER_DASHBOARD: "HEADER_DASHBOARD",
  HEADER_MY_CITIES: "HEADER_MY_CITIES",
  HEADER_EXPLORE: "HEADER_EXPLORE",
  MAP_EXPLORED: "MAP_EXPLORED",
  MAP_NEIGHBOURHOOD: "MAP_NEIGHBOURHOOD",
  MAP_LANDMARKS: "MAP_LANDMARKS",
  MAP_ACHIEVEMENTS: "MAP_ACHIEVEMENTS",
  DASHBOARD_VIEW_ALL: "DASHBOARD_VIEW_ALL",
  VENDOR_PERMISSIONS_ERROR_MESSAGE: "VENDOR_PERMISSIONS_ERROR_MESSAGE",
  VENDOR_PERMISSION_GRANT_BUTTON: "VENDOR_PERMISSION_GRANT_BUTTON",
};
