import React from "react";
import styles from "./CookiePopup.module.css";
import sharedStyles from "../../styles/shared.module.css";
import classNames from "classnames";

import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { LocalStoreStateType } from "../../state/localstore/LocalStoreTypes";
import { setLocalStoreData } from "../../state/localstore/LocalStoreActions";
import { localStoreSelector } from "../../state/localstore/LocalStoreSelector";
import Content from "../../components/content/Content";
import LangManager from "../../data/languages/LangManager";
import LangKeys from "../../data/languages/LangKeys";
import { isApp } from "../../platform/PlatformManager";

type StateProps = {
  localstore: LocalStoreStateType;
};

type DispatchProps = {
  hidePopup: () => void;
};

type PropsType = StateProps & DispatchProps;

class CookiePopup extends React.PureComponent<PropsType> {
  reject() {
    alert(
      "City Painter needs cookies to function correctly. Please accept cookies or leave the site"
    );
  }
  render() {
    if (
      isApp() ||
      (this.props.localstore && this.props.localstore.cookiesAccepted)
    ) {
      return null;
    }
    return (
      <div className={styles.container}>
        <Content>
          <div className={classNames(sharedStyles.heading5)}>
            {LangManager.getLang(LangKeys.COOKIE_POPUP_HEADER)}
          </div>
          <div className={styles.textContainer}>
            <div className={classNames(sharedStyles.paragraph)}>
              {LangManager.getLang(LangKeys.COOKIE_POPUP_TEXT)}{" "}
            </div>
            <Link to={"/privacy_policy"} className={styles.privacyText}>
              <span className={classNames(sharedStyles.paragraph)}>
                {LangManager.getLang(LangKeys.COOKIE_POPUP_PRIVACY_POLICY)}
              </span>
            </Link>
          </div>
          <div className={styles.buttonsContainer}>
            <Link to={"/privacy_policy"}>
              <div
                className={classNames(sharedStyles.paragraph, styles.learnMore)}
              >
                {LangManager.getLang(LangKeys.COOKIE_POPUP_PRIVACY_LEARN_MORE)}
              </div>
            </Link>
            <div className={styles.acceptRejectButtons}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={styles.acceptButton}
                onClick={() => this.props.hidePopup()}
              >
                <span className={classNames(sharedStyles.paragraphButton)}>
                  {LangManager.getLang(LangKeys.COOKIE_POPUP_PRIVACY_ACCEPT)}
                </span>
              </Button>
              <div className={styles.reject} onClick={this.reject}>
                <span
                  className={classNames(
                    sharedStyles.paragraphButton,
                    styles.rejectText
                  )}
                >
                  {LangManager.getLang(LangKeys.COOKIE_POPUP_PRIVACY_REJECT)}
                </span>
              </div>
            </div>
          </div>
        </Content>
      </div>
    );
  }
}
function mapStateToProps(state): StateProps {
  return {
    localstore: localStoreSelector(state),
  };
}

function mapDispatchToProps(dispatch): DispatchProps {
  return {
    hidePopup: () => dispatch(setLocalStoreData({ cookiesAccepted: true })),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CookiePopup);
