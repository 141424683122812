/**
 * Created by matthewjamieson on 24/02/2020.
 */
import { HIDE_POPUP, SHOW_POPUP } from "./PopupsActions";
import { PopupsStateType } from "./PopupsTypes";
import { FeedbackCategory } from "../../popups/feedback/FeedbackPopup";

const initialState: PopupsStateType = {
  account: {
    visible: false,
  },
  error: {
    visible: false,
  },
  feedback: {
    visible: false,
    category: FeedbackCategory.general,
    message: "",
  },
  deleteConfirm: {
    type: "AREA", // default
    visible: false,
    name: "",
  },
  vendorPermissionsError: {
    visible: false,
  },
  deleteAccountConfirm: {
    visible: false,
  },
  namePopup: {
    visible: false,
  },
  activityDescriptionPermissions: {
    visible: false,
  },
};

export default function (state = initialState, action): PopupsStateType {
  switch (action.type) {
    case SHOW_POPUP:
      return Object.assign(
        {},
        { ...state, [action.popupType]: { visible: true, ...action.data } }
      );
    case HIDE_POPUP:
      return Object.assign(
        {},
        { ...state, [action.popupType]: { visible: false } }
      );

    default:
      return state;
  }
}
