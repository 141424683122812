import { defaultInitialState, ApiReducerHelper } from "framework";
import {
  ImportFromVendorResponseType,
  ImportFromVendorStateType,
} from "./ImportFromVendorTypes";
import { IMPORT_FROM_VENDOR_LOAD_ACTION } from "./ImportFromVendorActions";

const initialState: ImportFromVendorStateType = {
  ...defaultInitialState,
  data: {
    success: false,
  },
};

const importFromVendorApiReducer: ApiReducerHelper<ImportFromVendorResponseType> = new ApiReducerHelper(
  {
    loadAction: IMPORT_FROM_VENDOR_LOAD_ACTION,
    initialState,
  }
);

export default importFromVendorApiReducer.getReducer();
