import { ApiRequestBodies, ApiResponses, ApiRoutes } from "cp-server";
import { EndpointBuilder } from "@reduxjs/toolkit/dist/query/endpointDefinitions";

const Endpoint = (builder: EndpointBuilder<any, any, any>) =>
  builder.mutation<
    ApiResponses[ApiRoutes.ReportInaccessibleArea],
    ApiRequestBodies[ApiRoutes.ReportInaccessibleArea]
  >({
    query: (data) => ({
      url: ApiRoutes.ReportInaccessibleArea,
      method: "post",
      body: data,
    }),
  });

export default Endpoint;
