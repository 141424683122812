import { ApiRequestBodies, ApiResponses, ApiRoutes } from "cp-server";
import { EndpointBuilder } from "@reduxjs/toolkit/dist/query/endpointDefinitions";

const Endpoint = (builder: EndpointBuilder<any, any, any>) =>
  builder.query<
    ApiResponses[ApiRoutes.SubscriptionGetAvailableProducts],
    ApiRequestBodies[ApiRoutes.SubscriptionGetAvailableProducts]
  >({
    query: (data) => ({
      url: ApiRoutes.SubscriptionGetAvailableProducts,
      method: "post",
      body: data,
    }),
  });

export default Endpoint;
