import React from "react";
import styles from "./StravaButton.module.css";
import { useHistory } from "react-router-dom";
import { isApp } from "../../platform/PlatformManager";
import Echo from "../../platform/appplugins/StravaAuthPlugin";
import { isPlatform } from "@ionic/react";

export default function StravaButton() {
  const history = useHistory();
  const stravaLogin = async () => {
    const clientId = "43843";
    if (!isApp()) {
      window.location.href = `https://www.strava.com/oauth/authorize?client_id=${clientId}&response_type=code&redirect_uri=${window.location.origin}/account-setup/strava&approval_prompt=auto&scope=read,activity:read,activity:read_all,activity:write`;
    }
    if (isPlatform("ios")) {
      console.log("IOS strava auth");
      const url = `https://www.strava.com/oauth/authorize?client_id=${clientId}&response_type=code&redirect_uri=citypainter%3A%2F%2Fcitypainter.io/account-setup/strava&approval_prompt=auto&scope=read,activity:read,activity:read_all,activity:write`;
      // @ts-ignore
      const { value } = await Echo.auth({ value: url });
      const newUrl = "/account-setup/strava?" + value;
      history.push(newUrl);
    }

    if (isPlatform("android")) {
      //const url = `https://www.strava.com/oauth/authorize?client_id=${clientId}&response_type=code&redirect_uri=citypainter%3A%2F%2Fcitypainter.io/account-setup/strava&approval_prompt=auto&scope=read,activity:read`;
      const url = `https://www.strava.com/oauth/authorize?client_id=${clientId}&response_type=code&redirect_uri=citypainter%3A%2F%2Fcitypainter.io/account-setup/strava&approval_prompt=auto&scope=read,activity:read,activity:read_all,activity:write`;
      // @ts-ignore
      Echo.auth({
        value: url,
        clientId,
        redirectUri:
          "&redirect_uri=https://citypainter.io/account-setup/strava",
      });
    }
  };

  return (
    <div className={styles.buttonContainer}>
      <div className={styles.stravaButton} onClick={stravaLogin} />
    </div>
  );
}
