import React, { Component } from "react";
import styles from "./VerifyEmail.module.css";
import sharedStyles from "../../styles/shared.module.css";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { ReduxStateType } from "../../state/ReduxStateType";
import Header from "../components/Header";
import { Button } from "@mui/material";
import classNames from "classnames";
type DispatchProps = {};
type StateProps = {};
type OtherProps = {};
type PropsType = StateProps & DispatchProps & OtherProps;

type StateType = {};

class VerifyEmail extends Component<PropsType, StateType> {
  render() {
    return (
      <div>
        <Header logoOnly />
        <div className={styles.container}>
          <div
            className={classNames(sharedStyles.heading2, styles.verifyMessage)}
          >
            Thanks for verifying your email address!
          </div>
          <Button
            variant="contained"
            classes={{ root: styles.button, text: styles.buttonLabel }}
            href={"/tabs/home"}
          >
            Go To Dashboard
          </Button>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: ReduxStateType): StateProps {
  return {};
}

function mapDispatchToProps(dispatch): DispatchProps {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(VerifyEmail);
