import { LandmarkType } from "cp-server";
import { getUnique } from "../Unique";

// Remove duplicates based on osm_id
// duplicates occur because a landmark can exist in multiple areas and therefore multiple entries can be genrated
function removeDuplicates(
  landmarks: Array<LandmarkType> | undefined
): LandmarkType[] {
  if (!landmarks) return [];
  const osm_ids = landmarks.map((l) => l.osm_id);
  const unique_osm_ids = getUnique(osm_ids);
  return unique_osm_ids.map(
    (osm_id) => landmarks.find((l) => l.osm_id === osm_id) as LandmarkType
  );
}

export default { removeDuplicates };
