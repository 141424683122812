"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.loadData = exports.saveData = void 0;
const saveData = (store) => (next) => (action) => {
    const result = next(action);
    const state = store.getState();
    const localStore = state.localStore;
    const userSlice = state.userSlice;
    const stateJson = JSON.stringify({ localStore, userSlice }, filterKeys);
    localStorage.setItem("cpappdata", stateJson);
    return result;
};
exports.saveData = saveData;
function filterKeys(key, value) {
    if (key === "loading") {
        return false;
    }
    if (key === "field_report") {
        return false;
    }
    return value;
}
const loadData = (callback) => {
    let json = {};
    try {
        json = JSON.parse(localStorage.getItem("cpappdata") || "{}");
    }
    catch (e) {
        console.warn("SaveStore: Cannot load state", e);
    }
    callback(json);
};
exports.loadData = loadData;
