import React from "react";
import styles from "../../Premium.module.css";
import sharedStyles from "../../../../styles/shared.module.css";
import { Button } from "@mui/material";
import classNames from "classnames";
import Content from "../../../../components/content/Content";
import { useHistory } from "react-router-dom";

export default function SuccessCard() {
  const history = useHistory();

  return (
    <Content>
      <div className={styles.successContainer}>
        <img src={require("../../../../assets/logo.png")} width={200} />
        <div className={classNames(sharedStyles.heading2)}>
          Thank you for upgrading to
          <br /> City Painter premium!
        </div>
        <div>
          You can view/change/cancel your subscription at any time on the
          settings tab
        </div>
        <Button
          color={"primary"}
          variant={"contained"}
          style={{ color: "white", marginBottom: 40 }}
          onClick={() => history.push("/tabs/home")}
        >
          Go To Dashboard
        </Button>
      </div>
    </Content>
  );
}
