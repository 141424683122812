import { ApiRequestBodies, ApiResponses, ApiRoutes } from "cp-server";
import { EndpointBuilder } from "@reduxjs/toolkit/dist/query/endpointDefinitions";

const Endpoint = (builder: EndpointBuilder<any, any, any>) =>
  builder.mutation<
    ApiResponses[ApiRoutes.SubscriptionCreateCheckout],
    ApiRequestBodies[ApiRoutes.SubscriptionCreateCheckout]
  >({
    query: (data) => ({
      url: ApiRoutes.SubscriptionCreateCheckout,
      method: "post",
      body: data,
    }),
  });

export default Endpoint;
