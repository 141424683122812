import { User } from "framework";
import { getStore, history } from "../../state/Store";
import { logout } from "../../state/userslice/UserSlice";

function _logout() {
  // @ts-ignore
  const isLoggedIn = getStore().getState().userSlice.token;
  if (isLoggedIn) {
    getStore().dispatch(logout());
    User.clearData();
    history.push("/auth");
  }
}

export default {
  logout: _logout,
};
