import { ApiRequestBodies, ApiResponses, ApiRoutes } from "cp-server";
import { EndpointBuilder } from "@reduxjs/toolkit/dist/query/endpointDefinitions";

const Endpoint = (builder: EndpointBuilder<any, any, any>) =>
  builder.query<ApiResponses[ApiRoutes.EmailAction], string>({
    query: (email_id) => ({
      url: ApiRoutes.EmailAction,
      method: "post",
      body: {
        email_id,
      },
    }),
  });

export default Endpoint;
