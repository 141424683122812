"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * Created by matthewjamieson on 09/02/2017.
 */
class ImageManager {
    constructor(images) {
        this.getImage = (key) => {
            return this.images[key];
        };
        this.images = images;
    }
}
exports.default = ImageManager;
