import React, { Component, Props } from "react";
import styles from "./Achievement.module.css";
import { AchievementType } from "cp-server";
import sharedStyles from "../../../../styles/shared.module.css";
import classNames from "classnames";
import { getuniqueVisitedLandmarksCount } from "../../../../data/utils/formating/AchievementUtils";
import LangManager from "../../../../data/languages/LangManager";
import LangKeys from "../../../../data/languages/LangKeys";
import CompleteTick from "../../../../components/completetick/CompleteTick";
import { Link } from "react-router-dom";

type PropsType = { data: AchievementType };

function Achievement(props: PropsType) {
  function renderComplete() {
    return (
      <div className={styles.completeContainer}>
        <CompleteTick size={32} />
        <div className={classNames(sharedStyles.heading4, styles.completeText)}>
          {LangManager.getLang(LangKeys.ACHIEVEMENT_COMPLETE_2)}
        </div>
      </div>
    );
  }

  const a = props.data;
  const totalLandmarks = a.landmarks_needed || a.landmarks.length;
  const visitedLandmarks = Math.min(
    totalLandmarks,
    getuniqueVisitedLandmarksCount(a.visited_landmarks)
  );
  const complete = totalLandmarks === visitedLandmarks;

  return (
    <Link
      className={styles.container}
      to={`/tabs/home/achievement/${props.data.achievement_id}`}
    >
      <div className={styles.inner}>
        <div className={styles.info}>
          <div className={classNames(sharedStyles.heading3, styles.name)}>
            {a.name}
          </div>
          <div className={classNames(sharedStyles.heading4, styles.desc)}>
            {a.description}
          </div>
          <div className={styles.bottomRow}>
            {complete ? (
              renderComplete()
            ) : (
              <div
                className={classNames(sharedStyles.heading2, styles.count)}
              >{`${visitedLandmarks} / ${totalLandmarks}`}</div>
            )}
          </div>
        </div>
      </div>
    </Link>
  );
}

export default React.memo(Achievement);
