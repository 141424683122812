import React, { Component } from 'react';
import {connect} from 'react-redux';
import {loadRegister} from "../state/register/RegisterActions";

type DispatchProps = {
  register: ({username, password}: { username: string, password: string }) => void
};
type StateProps = {};
type OtherProps = {};
type PropsType = DispatchProps & StateProps & OtherProps;

type StateType = {
  username: string;
  password: string;
};

class Register extends Component<PropsType, StateType> {
  constructor(props) {
    super(props);
    this.state= {
      username: '',
      password: ''
    }
  }

  render() {
    return (
      <div>
        HELLO
        <form action="">
          <input type="text" id="username" name="username" value="Test" onChange={e=> this.setState({username:e.target.value})}/>
          <br/>
          <input  type="password" id="password" name="password" value="" onChange={e=> this.setState({password:e.target.value})}/>
          <br/>
        </form>
        <div onClick={() => {this.props.register({username: this.state.username, password: this.state.password})}}>SUBMIT</div>
      </div>
    );
  }
}

function mapStateToProps(state): StateProps {
 return {

 }
}
function mapDispatchToProps(dispatch): DispatchProps {
  return {
    register: (details) => dispatch(loadRegister(details))
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Register);
